import React from "react";
import {
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Form as bootstrapForm,
} from "react-bootstrap";
import ReactSwitch from "react-switch";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import { useTranslation } from "react-i18next";

const EmbedURL = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="mt-3" style={{ fontSize: "13px" }}>
        <div className="header mb-2">
          {t("Modules.Room categories (+ add new).Embed 3D Model")}
        </div>

        <div className="sub-header-text">
          {t("Modules.Rooms List.Embed 3D Model - Description")}
          <OverlayTrigger
            key="right"
            placement="right"
            overlay={
              <Tooltip id="tooltip-right-common">
                {t("Modules.Rooms List.Embed 3D Model - Tooltip")}
              </Tooltip>
            }
          >
            <QuestionIcon className="pointer" />
          </OverlayTrigger>
        </div>
        <div>
          <span className="switch-lable sub-header-text">
            {t("Modules.Rooms List.Off")}
          </span>
          <ReactSwitch
            onChange={(value) => {
              props.filedValue("is_3dmodel", value);
            }}
            checked={props.values?.is_3dmodel}
            name="is_3dmodel"
          />
          <span className="switch-lable sub-header-text">
            {t("Modules.Rooms List.On")}
          </span>
        </div>
      </Row>
      {props.values?.is_3dmodel ? (
        <Row className="mt-3">
          <Col md={6}>
            <bootstrapForm.Group>
              <bootstrapForm.Label className={"sub-header-text"}>
                {t("Modules.Rooms List.3D Model URL")}
                <span style={{ color: "red" }}>*</span>
              </bootstrapForm.Label>
              <bootstrapForm.Control
                type="text"
                name="model_3d_url"
                onChange={(e) =>
                  props.filedValue("model_3d_url", e.target.value)
                }
                value={props.values?.model_3d_url?.trimStart()}
                isInvalid={
                  props.touched?.model_3d_url && props.errors?.model_3d_url
                }
              ></bootstrapForm.Control>
              <span style={{ fontSize: "small" }}>
                {t(
                  "Modules.Room categories (+ add new).3D Model URL - Description"
                )}
              </span>
            </bootstrapForm.Group>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

export default EmbedURL;
