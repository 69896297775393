// Toast msg configs
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as QuestionIcon } from "../components/Common/icons/Question.svg";

const STATIC_HOTEL_ID = localStorage.getItem("hotelId");
const TOAST_CONFIG = {
  default: {
    position: "bottom-center",
    duration: 3000,
  },
};

const MOBILE_SCREENS = {
  MAX_WIDTH: 768,
};

const TRACKING_TYPES = {
  GENERAL_TERMS_CONDITION: "general_terms_condition",
  DATA_PROCESSING_AGREEMENT: "data_processing_agreement",
};

const RES_CODE = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  BAD_REQ: 400,
  SUCCESS: 200,
  INTERNAL_SERVER: 500,
};

const REGEX = {
  URL_VALIDATION:
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  NEGATIVE_REGEX: /^[0-9\b]+$/,
  INT_DECIMAL_REGEX: /^(0|[1-9]\d*)(\.\d+)?$/,
};

const TEXT_EDITOR_CONFIG = {
  MAX_CHAR_LIMIT: 2000,
  HTML_MAX_CHAR_LIMIT: 15000,
  inBuiltConfigs: {
    options: ["inline", "fontFamily", "list", "textAlign"],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ["bold", "italic", "underline"],
    },
  },
  ckEditorConfig: {
    // NOTE: use link to remove/add icons -> https://ckeditor.com/latest/samples/toolbarconfigurator/index.html#basic
    extraPlugins: "font",
    versionCheck: false,
    contentsCss: [
      'body {font-size: 14px;font-family:sans-serif, Arial, Verdana, "Trebuchet MS", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";color: #333;line-height: 1.6;word-wrap: break-word;cursor: text;}',
    ],
    toolbarGroups: [
      { name: "styles", groups: ["styles", "FontSize"] },
      // "/",
      {
        name: "basicstyles",
        groups: ["basicstyles"],
      },
      {
        name: "paragraph",
        groups: ["bold", "list", "indent", "align", "bidi"], // 'align' -> 'justify' plugin
      },
      "/",
    ],
    removeButtons:
      "Radio, Underline,Subscript,Superscript,Styles,Format,TextField, Textarea, Source, Image, ImageCaption, links, ImageStyle, ImageToolbar, ImageUpload, MediaEmbed, Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
    allowedContent: true,
  },
};

const LANGUAGE = {
  // todo: remove multiple field once DB is set
  english: "en",
  french: "fr",
  en: "en",
  fr: "fr",
  dutch: "dutch",
  spanish: "spanish",
  german: "german",
  de: "de",
};

const STATUS_MESSAGE = {
  pending: "pending",
  location_pending: "location-pending",
  completed: "completed",
};

const TRANSLATION_TAB = {
  CATEGORY: 3,
  ROOMS: 4,
  DEALS: 5,
  OFFERS: 6,
};

const TABS_TRANSLATION = [
  {
    id: 1,
    key: "greeting",
    value: "Greeting Message",
  },
  {
    id: 2,
    key: "tags",
    value: "Tags",
  },
  {
    id: 3,
    key: "category",
    value: "Category",
  },
  // {
  //   id: 4,
  //   key: "season",
  //   value: "Season",
  // },
  {
    id: 5,
    key: "room",
    value: "Rooms",
  },
  {
    id: 6,
    key: "deals",
    value: "Deals",
  },
  {
    id: 7,
    key: "offer",
    value: "Offers",
  },
];
const generateToolTip = (translation = "") => {
  if (translation) {
    return (
      <OverlayTrigger
        key="right"
        placement="right"
        overlay={<Tooltip id="tooltip-right-common">{translation}</Tooltip>}
      >
        <QuestionIcon className="pointer" />
      </OverlayTrigger>
    );
  }
};
const generateToolTipforCategory = (translation = "") => {
  if (translation) {
    return (
      <OverlayTrigger
        key="right"
        placement="right"
        overlay={<Tooltip id="tooltip-category-right">{translation}</Tooltip>}
      >
        <QuestionIcon className="pointer" />
      </OverlayTrigger>
    );
  }
};
const STORAGE_KEYS = {
  IdToken: "IdToken",
  RefreshToken: "RefreshToken",
  hotelGroupToken: "hotel-group-token",
  allowedPages: "allowedPages",
  AccessToken: "AccessToken",
  UserFirstName: "user-first-name",
  hotelGroupId: "hotelGroupId",
  hotelId: "hotelId",
  languageId: "languageId",
  dashboardLinks: "dashboardLinks",
  CONFIG_SET: "configSet",
  CONFIG_DATA: "configData",
};
const PRIVATE_ROUTES = {
  Home: "/hotel-management",
  CONFIG: "/config",
  Hotel_management: "/home",
  hotel_configuration: "/hotel-configuration",
  floor_list: "/hotel-floor/list",
  floor_create: "/hotel-floor/create",
  floor_update: "/hotel-floor/edit/:id",
  room_category_list: "/hotel-room-categories",
  room_category_create: "/hotel-room-categories/create",
  room_category_edit: "/hotel-room-categories/edit/:id",
  room_pricing_list: "/hotel-room-pricing",
  room_pricing_create: "/hotel-room-pricing/create",
  room_pricing_edit: "/hotel-room-pricing/edit/:id",
  room_list: "/hotel-room-list",
  floor_plan_assign: "/assign-room/:floor_id/:room_id",
  room_create: "/add-room",
  room_update: "/update-room/:roomId",
  deals_and_offer: "/deals-and-offer",
  deal_create: "/extra-service/create",
  offer_create: "/special-offers/create",
  deal_edit: "/extra-service/edit/:id",
  offer_edit: "/special-offers/edit/:id",
  user_profile: "/user/profile",
  user_list: "/user/list",
  user_update: "/update-user/:id",
  // user_set_password: "/set-password",
  user_create: "/user/create",
  coupons_list: "/coupons",
  coupon_create: "/coupon/create",
  coupon_edit: "/coupon/edit/:id",
};

export {
  TOAST_CONFIG,
  MOBILE_SCREENS,
  RES_CODE,
  TABS_TRANSLATION,
  LANGUAGE,
  STATIC_HOTEL_ID,
  TEXT_EDITOR_CONFIG,
  REGEX,
  STATUS_MESSAGE,
  generateToolTip,
  generateToolTipforCategory,
  PRIVATE_ROUTES,
  STORAGE_KEYS,
  TRACKING_TYPES,
  TRANSLATION_TAB,
};
