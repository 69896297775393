import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import Breadcrumbs from "./../Common/Breadcrumbs";
import { FloorForm } from "./FloorForm";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as Vectors } from "../Common/icons/vectors.svg";
import SoftDeleteAlertModal from "../Common/SoftDeleteAlertModal";
import { RES_CODE } from "../../helper/constants";

export const EditFloor = (props) => {
  const floorId = props.match.params.id;
  const storageService = new StorageService();
  const history = useHistory();
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(null);
  const hotelId = +storageService.getItem("hotelId");
  const hotelName = useSelector((state) => state?.hotelDetails?.result?.name);
  const dispatch = useDispatch();
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );

  const floorDetails = useSelector((state) => state?.floor?.result);
  const [currentFloorName, setCurrentFloorName] = useState(null);
  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotels" },
    { path: "/hotel-configuration", title: hotelName ? hotelName : "" },
    { path: "/hotel-floor/list", title: "Floor List" },
    { path: `/hotel-floor/edit/${floorId}`, title: `${currentFloorName}` },
  ];
  const { t } = useTranslation();

  useEffect(() => {
    getFloorDetails();
  }, [floorId]);

  useEffect(() => {
    if (defaultSelectedLanguage && floorDetails) {
      const changedFloorName = findExistingLanguage(
        floorDetails?.h_floor_ts,
        defaultSelectedLanguage
      );
      if (changedFloorName && changedFloorName?.language_id) {
        setCurrentFloorName(changedFloorName?.name);
      } else {
        setCurrentFloorName(floorDetails?.h_floor_ts[0].name);
      }
    }
  }, [defaultSelectedLanguage, floorDetails]);

  useEffect(() => {
    if (i18next.language && availableLanguagesFromSelector) {
      const langInfo = selectedLangId(i18next.language?.toLowerCase());

      if (langInfo) {
        setDefaultLanguage(langInfo?.language?.id);
      } else {
        setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
      }
    }
  }, [i18next.language, availableLanguagesFromSelector]);

  const findExistingLanguage = (languageBLock, selectedLangId) => {
    return (languageBLock || []).find((translationContent) => {
      return +translationContent.language_id === +selectedLangId;
    });
  };

  const isUpdated = useSelector((state) => state?.floor?.isUpdate);

  useEffect(() => {
    if (isUpdated) {
      history.push("/hotel-floor/list");
    }
  }, [isUpdated]);

  const getFloorDetails = () => {
    dispatch(actions.getFloorDetail(floorId));
  };

  const selectedLangId = (selectedLang) => {
    return availableLanguagesFromSelector?.find((langInfo) => {
      return (
        langInfo?.language?.code?.toLowerCase() ===
          selectedLang.toLowerCase() ||
        langInfo?.language?.key?.toLowerCase() === selectedLang.toLowerCase()
      );
    });
  };

  const apiResponseListing = useSelector((state) => state.network.data);
  const [showSoftDeleteAlert, setShowSoftDeleteAlert] = useState(false);
  const [softDeleteAlertType, setSoftDeleteAlertType] =
    useState("confirmation");

  useEffect(() => {
    switch (+apiResponseListing?.status) {
      case +RES_CODE.BAD_REQ: {
        setShowSoftDeleteAlert(true);
        setSoftDeleteAlertType("dependency");
        break;
      }
      default: {
        setShowSoftDeleteAlert(false);
        setSoftDeleteAlertType("confirmation");
        break;
      }
    }
  }, [apiResponseListing]);

  return (
    <React.Fragment>
      <SoftDeleteAlertModal
        show={showSoftDeleteAlert}
        type={softDeleteAlertType}
        primaryMessage={
          softDeleteAlertType === "dependency"
            ? t("Modal Messages.Dependency Message")
            : t("Modal Messages.General Message")
        }
        secondaryMessage={
          softDeleteAlertType === "dependency"
            ? t("Modal Messages.Dependency Info Message", {
                linked: t("Modal Messages.Linked Room To Floor"),
              })
            : t("Modal Messages.General Info Message")
        }
        onConfirm={() => dispatch(actions.softDeleteFloor({ id: floorId }))}
        closeButtonText={
          softDeleteAlertType === "dependency"
            ? t("Modal Messages.Close")
            : t("Modal Messages.Cancel")
        }
        onClose={() => {
          setShowSoftDeleteAlert(false);
          setSoftDeleteAlertType("confirmation");
        }}
      />
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12} xs={12} className="alignSelf alignRight">
            <>
              <Dropdown className="customer-dropdown">
                <Dropdown.Toggle size="sm" variant="white">
                  <Vectors />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowSoftDeleteAlert(true)}>
                    {t("Modules.Floor list (edit).Deactivate Floor")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3> {t("Modules.Floor list (edit).Edit floor")} </h3>
          </Col>
        </Row>
      </div>
      <div className="content">
        <FloorForm
          isForEdit={true}
          floorDetails={floorDetails}
          hotelId={hotelId}
          floorId={floorId}
          updateFloor={getFloorDetails}
        />
      </div>
    </React.Fragment>
  );
};
