import axios from "../authInstance";
class Login {
  login = async (credential) => {
    try {
      const response = await axios.post("/user-signin", credential);
      return response;
    } catch (error) {
      throw error;
    }
  };
  signOut = async (accessToken) => {
    try {
      const response = await axios.post("/sign-out", accessToken);
      return response;
    } catch (error) {
      throw error;
    }
  };
  forgotPassword = async (emailId) => {
    try {
      const response = await axios.post("/forgot-password", emailId);
      return response;
    } catch (error) {
      throw error;
    }
  };
  confirmPassword = async (payload) => {
    try {
      const response = await axios.post("/confirm-password", payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
  resetPassword = async (emailId) => {
    try {
      const response = await axios.post("/reset-password", emailId);
      return response;
    } catch (error) {
      throw error;
    }
  };
  setPassword = async (payload) => {
    try {
      const response = await axios.post("/set-password", payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
  changePassword = async (payload) => {
    try {
      const response = await axios.post("/change-password", payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default Login;
