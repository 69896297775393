import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import actions from "../store/actions/actions";
import { ReactComponent as Logo } from "./Common/SidebarSvg/Hotelsgroup.svg";
import { ReactComponent as EMLogo } from "./Common/icons/expectmelogo.svg";

export default () => {
  const configData = useSelector((state) => state.login.configData);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!configData) dispatch(actions.removeToken());
  }, [configData]);

  const handleOnHGClick = (hgId) => {
    dispatch(actions.setHG(hgId));
  };

  const ConfigRows = () => {
    if (!configData) return <></>;
    return Object.keys(configData).map((name) => {
      return (
        <div
          className="config-row"
          onClick={() => handleOnHGClick(configData[name])}
        >
          <Logo />
          <span>
            <h5>{name}</h5>
          </span>
        </div>
      );
    });
  };

  return (
    <div className="hg-config">
      <Card className="config-card">
        <EMLogo />
        <Card.Header>Choose Hotel Group</Card.Header>
        <Card.Body>{<ConfigRows />}</Card.Body>
      </Card>
    </div>
  );
};
