import axios from "../axiosInstance";

class LanguageSettingsService {
  getLanguageSettings = async (hotelId) => {
    try {
      const response = await axios.get(`/hotel/${hotelId}/tags`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  storeLanguageSettings = async (payload) => {
    try {
      const hotelId = payload.hotelId;
      delete payload.hotelId;
      const response = await axios.post(`/hotel/${hotelId}/tags`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
  getLanguage = async () => {
    try {
      const response = await axios.get(`/languages`, {
        headers: { client_secret: "879979wIthuoutLOgiNvErIFicAtion9898032" },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default LanguageSettingsService;
