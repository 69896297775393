import React from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const PanZoom = ({
  image,
  hotSpots,
  existingHotSpotData,
  imageLoad,
  imageHotSpotRef,
  scale,
  setScale,
}) => {
  const imageOnLoad = () => {
    imageLoad(true);
  };

  return (
    <TransformWrapper
      //   initialPositionX={200}
      //   initialPositionY={100}
      initialScale={scale}
      minScale={0.3}
      onZoom={(e) => {
        if (e.state.scale < 0.3) {
          setScale(0.3);
        } else {
          setScale(e.state.scale);
        }
      }}
      wheel={{ step: 0.2 }}
      pinch={{ step: 5 }}
      centerZoomedOut={true}
      centerOnInit={true}
      panning={{ excluded: ["button"] }}
      ref={imageHotSpotRef}
    >
      {({ zoomIn, zoomOut, resetTransform, zoomToElement, ...rest }) => (
        <React.Fragment>
          <TransformComponent
            wrapperStyle={{ maxWidth: "100%", maxHeight: "calc(100vh - 50px)" }}
          >
            <img src={image} alt="Floor Plan" onLoad={imageOnLoad} />
            {hotSpots}
            {existingHotSpotData}
          </TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
};

export default PanZoom;
