import axios from "../servicesInstance";
import { Utils } from "./../../utils/utils";

class SpacialOffers {
  getOffers = async (payload) => {
    try {
      const params = {
        ...payload,
      };
      const response = await axios.get(`offers`, { params });
      return response;
    } catch (error) {
      throw error;
    }
  };
  getSearchOffers = async (hotelId, searchKey) => {
    try {
      const response = await axios.get(
        `/offers?hotel_id=${hotelId}&searchKey=${searchKey}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  addOffer = async (payload) => {
    const bodyFormData = Utils.appendDataServiceAndOffers(payload);
    try {
      const response = await axios.post(`/offer`, bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };
  getOfferById = async (hotelId, offerId) => {
    try {
      const response = await axios.get(`/offer/${offerId}?hotel_id=${hotelId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  updateOffer = async (categoryId, payload) => {
    const bodyFormData = Utils.appendDataServiceAndOffers(payload);
    try {
      const response = await axios.put(`/offer/${categoryId}`, bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  softDeleteOffer = async (payload) => {
    try {
      const api = `/offer/` + payload.id + `/status`;
      const response = await axios.patch(api);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default SpacialOffers;
