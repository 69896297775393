import actions from "../actions/actions";

const initialState = {
  result: false,
  msg: false,
  success: false,
  userList: false,
  addUser: false,
  getUserProfile: false,
  userProfile: false,
  updated: false,
};

export const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_USERS_SUCCESS: {
      state.userList = action?.result;
      return {
        ...state,
        addUser: false,
        getUserProfile: false,
        success: true,
        updated: false,
      };
    }
    case actions.FETCH_USERS_FAILED: {
      return {
        ...state,
        result: {
          addUser: false,
          msg: action?.msg,
        },
        success: false,
        updated: false,
      };
    }
    case actions.ADD_USERS_SUCCESS: {
      return {
        ...state,
        addUser: true,
        success: true,
        updated: false,
      };
    }
    case actions.ADD_USERS_FAILED: {
      return {
        ...state,
        result: {
          msg: action?.msg,
        },
        success: false,
        updated: false,
      };
    }
    case actions.UPDATE_USERS_SUCCESS: {
      return {
        ...state,
        success: true,
        updated: true,
      };
    }
    case actions.UPDATE_USERS_FAILED: {
      return {
        ...state,
        result: {
          msg: action?.msg,
        },
        success: false,
        updated: false,
      };
    }
    case actions.UPDATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        userProfile: action.data,
        userById: action.data,
        success: true,
        updated: false,
      };
    }
    case actions.UPDATE_USER_PROFILE_FAILED: {
      return {
        ...state,
        result: {
          msg: action?.msg,
        },
        success: false,
        updated: false,
      };
    }
    case actions.GET_USER_PROFILE_SUCCESS: {
      state.userProfile = action?.result;
      return {
        ...state,
        getUserProfile: true,
        success: true,
        updated: false,
      };
    }
    case actions.GET_USER_PROFILE_FAILED: {
      return {
        ...state,
        result: {
          msg: action?.msg,
        },
        success: false,
        updated: false,
      };
    }
    case actions.GET_USER_BY_ID_SUCCESS: {
      state.userById = action?.result;
      return {
        ...state,
        getUserProfile: true,
        success: true,
        updated: false,
      };
    }
    case actions.GET_USER_BY_ID_FAILED: {
      return {
        ...state,
        result: {
          msg: action?.msg,
        },
        success: false,
        updated: false,
      };
    }
    case actions.CLEAR_USER_BY_ID: {
      return {
        ...state,
        userById: false,
      };
    }
    case actions.CLEAR_USER_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};