import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form as bootstrapForm,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { ToastService } from "../../services/toast.service";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import ConfirmWindow from "../Common/ConfirmWindow";
import DragDropSortable from "../Common/DragDropSortable";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import categoryDes from "../Common/overlayImages/categoryDes.jpg";
import catImg from "../Common/overlayImages/catImg.jpg";
import { StorageService } from "./../../services/storage.service";
import { CKEditorComponent } from "./../Common/CkEditor";
import { DragDrop } from "./../Common/DragDrop";
import Spinner from "./../Common/Spinner";
import CategoryDropDown from "./CategoryDropdown";
import ReactSwitch from "react-switch";
import Switch from "react-switch";

export const RoomCategoriesForm = (props) => {
  //Todo make dynamic hotel id
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const formikRef = useRef();
  const { t } = useTranslation();
  const { id } = useParams();
  const [content, setContent] = useState(null);
  const [editorState, setEditorState] = useState();
  const [allowAccess, setAllowAccess] = useState(true);

  const PMSRoomTypesData = useSelector(
    (state) => state.roomCategories.PMSRoomTypes
  );
  // const roomCategoryByIdData = useSelector((state) => state.roomCategories.roomCategoriesById);
  const [fileUploaded, setFileUploaded] = useState([]);
  const [imageFromApi, setimageFromApi] = useState([]);
  const gotSuccess = useSelector((state) => state?.roomCategories?.success);
  const isAdd = useSelector((state) => state?.roomCategories?.isAdd);
  const isUpdate = useSelector((state) => state?.roomCategories?.isUpdate);
  const hotelDetails = useSelector((state) => state?.hotelDetails?.result);
  const [prevDescription, setPrevDescription] = useState();
  const [dropdownValue, setDropDownValue] = useState([]);
  const [payloadData, setPayloadData] = useState({});
  const [imageSortData, setImageSortData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalShow, setModalShow] = React.useState(false);
  const [language, setLanguage] = useState(null);
  const [tid, setTid] = useState(null);
  const isLoading = useSelector((state) => state?.network?.isLoading);
  const toast = new ToastService();

  const formInitialValue = {
    name: "",
    description: "",
    category_mappings: [],
    images: "",
    is_active: true,
  };
  const formSchema = yup.object().shape({
    name: yup.string().trim().min(2, "To short").required("Required"),
    category_mappings: yup
      .number()
      .integer()
      .required("Please Enter Special offers"),
    description: yup
      .string()
      .test(
        "length",
        "Maximum is 2000",
        (value) => Utils.getHTMLtoPlainText(value).length <= 2000
      ),
    images: "",
  });

  useEffect(() => {
    setAllowAccess(
      Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.room_category_create)
    );
    setLanguage(props.selectedLanguageForUpdate);
    dispatch(actions.getPMSRoomsTypes(hotelId));

    if (!props.isForEdit) {
      clearForm();
      setContent(<p></p>);
    }
  }, [props.selectedLanguageForUpdate]);
  useEffect(() => {
    if (props.categoryDetails?.id === parseInt(id)) {
      const toggleId = props.selectedLanguageForUpdate - 1;
      const content = Utils.langValueGenerator(
        props.categoryDetails?.category_ts || [],
        "description",
        props.selectedLanguageForUpdate
      );
      const dropDownValue = props.categoryDetails?.category_mappings.reduce(
        (v, i) => {
          v.push({ value: i["id"], label: i["name"] });
          return v;
        },
        []
      );

      const initialValues = props.categoryDetails?.category_mappings.reduce(
        (val, i) => {
          val.push(i["id"]);
          return val;
        },
        []
      );

      formikRef.current.setValues({
        name: Utils.langValueGenerator(
          props.categoryDetails?.category_ts || [],
          "name",
          props.selectedLanguageForUpdate
        ),
        // set isactive as true when received as `1` from backend
        is_active: parseInt(props.categoryDetails?.is_active) === 1,
        is_3dmodel: props.categoryDetails?.is_3dmodel,
        model_3d_url: props.categoryDetails?.model_3d_url,
        allow_room_upgrade: props.categoryDetails?.allow_room_upgrade,
        allow_overbooking: props.categoryDetails?.allow_overbooking,
        category_mappings: initialValues,
        description: content,
      });
      const img = [];
      props.categoryDetails?.category_images?.map((image, i) =>
        img.push({ arrId: image.id, preview: image.path })
      );
      setimageFromApi(img);
      setDropDownValue([...dropDownValue]);
      setTid(props.categoryDetails?.category_ts[toggleId]?.id);
      setPrevDescription(content);
      setEditorState(content);
      // setContent(content);
    }
  }, [props.categoryDetails, props.selectedLanguageForUpdate]);
  useEffect(() => {
    if (gotSuccess && !props.isForEdit) {
      clearForm();
      const htmlConverted = convertFromHTML(`<p></p>`);
      const finalizeContent = EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlConverted)
      );
      setEditorState(finalizeContent);
    }
    if (isAdd) {
      history.push("/hotel-room-categories");
    }
    if (isUpdate) {
      history.push("/hotel-room-categories");
    }
  }, [gotSuccess, isAdd, isUpdate]);

  const updateEditorState = (updatedContent, setFieldValue) => {
    const plainText = Utils.getHTMLtoPlainText(updatedContent);
    setContent(updatedContent);
    if (plainText.length > 0) {
      if (updatedContent.trim().length > 0) {
        setEditorState(updatedContent);

        // commented this to fix paste issue in CKEditor
        //setFieldValue("description", updatedContent);
      } else {
        setEditorState("");
      }
    } else {
      setEditorState("");

      // commented this to fix paste issue in CKEditor
      //setFieldValue("description", "");
    }
  };

  const updateDescriptionValue = (setFieldValue) => {
    const plainText = Utils.getHTMLtoPlainText(content);
    if (plainText.length > 0) {
      if (content.trim().length > 0) {
        setFieldValue("description", content);
      }
    } else {
      setFieldValue("description", "");
    }
  };

  const addRoomCategory = (payload) => {
    payload.category_mappings = payload.category_mappings.filter(
      (e) => e != null
    );
    dispatch(actions.addRoomCategories(payload));
  };
  const onClickCancelButton = () => {
    history.push("/hotel-room-categories");
  };
  const isNotOverride = () => {
    // payloadData["override"] = 0;
    // updateRoomCategory(payloadData);
    setModalShow(false);
  };
  const isOverride = () => {
    payloadData["override"] = 1;
    updateRoomCategory(payloadData);
    setModalShow(false);
  };
  const isReplaceAll = () => {
    payloadData["override"] = 2;
    updateRoomCategory(payloadData);
    setModalShow(false);
  };
  const updateRoomCategory = (payload) => {
    payload.category_mappings = payload.category_mappings.filter(
      (e) => e != null
    );
    dispatch(actions.updateRoomCategories(id, payload));
  };
  const clearForm = () => {
    formikRef.current?.resetForm();
  };

  const imageAlreadyPresent = [];
  imageFromApi.forEach((alreadyPresent, i) => {
    imageAlreadyPresent.push(alreadyPresent.arrId);
  });

  const fileSend = [...fileUploaded];
  const imageDeletedFromAlreadyPresent = [];
  imageAlreadyPresent.forEach((imageAlreadyPresentId, i) => {
    fileSend.forEach((uploadedFile, i2) => {
      if (uploadedFile.arrId === imageAlreadyPresentId) {
        fileSend.splice(i2, 1);
      }
    });
  });
  const fileUploadedId = [];
  fileUploaded?.forEach((uploadedFile, i) => {
    fileUploadedId.push(uploadedFile.arrId);
  });
  imageAlreadyPresent.forEach((imageAlreadyPresentId, i) => {
    if (!fileUploadedId.includes(imageAlreadyPresentId)) {
      imageDeletedFromAlreadyPresent.push(imageAlreadyPresentId);
    }
  });

  const validateFileUpload = () => {
    if (!fileUploaded?.length) {
      formikRef.current.touched.images = true;
      formikRef.current.values.images = "";
      // formikRef.current.errors.floor_plan = ;
      setFileUploaded([]);
    }
  };

  useEffect(() => {
    if (fileUploaded?.length > 10) {
      toast.onWarning("Category can have only 10 images associated!");
      setFileUploaded([...fileUploaded.slice(0, 10)]);
    }
  }, [fileUploaded]);

  const shouldHideToggle = () => {
    if (!hotelDetails) return false;
    return hotelDetails.pms_hotel.pms_integration.pms_master_id === 4;
  };

  return (
    <React.Fragment>
      <Formik
        innerRef={formikRef}
        initialValues={formInitialValue}
        validationSchema={formSchema}
        onSubmit={(values) => {
          if (!props.isForEdit) {
            if (values?.images || fileUploaded?.length) {
              const payload = {
                ...values,
                description: editorState,
                name: values.name.trim(),
                language_id: props.selectedLanguageForUpdate,
                hotel_id: hotelId,
                images: { data: fileUploaded, name: "category_images" },
                imageSortData: JSON.stringify(imageSortData),
              };
              addRoomCategory(payload);
            } else {
              formikRef.current.touched.images = true;
              formikRef.current.values.images = null;
              setFileUploaded([]);
            }
          } else {
            const payload = {
              ...values,
              description: editorState,
              name: values.name.trim(),
              hotel_id: hotelId,
              language_id: language,
              translation_id: tid,
              deleted_images: imageDeletedFromAlreadyPresent.toString(),
              images: { data: fileSend, name: "category_images" },
              imageSortData: JSON.stringify(imageSortData),
            };
            if (fileUploaded?.length) {
              const descriptionPlainText = Utils.getHTMLtoPlainText(
                payload.description
              );
              const prevDescriptionPlainText =
                Utils.getHTMLtoPlainText(prevDescription);
              if (
                payload.description.length !== prevDescription.length &&
                payload.description?.length > 0
              ) {
                setPayloadData(payload);
                setModalShow(true);
              } else {
                payload["override"] = 0;
                updateRoomCategory(payload);
              }
            } else {
              formikRef.current.touched.images = true;
              formikRef.current.values.images = null;
              setFileUploaded([]);
            }
          }
        }}
      >
        {({
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
          submitForm,
        }) => (
          <>
            {!isLoading ? (
              <Form>
                <div className="header">
                  {t(
                    "Modules.Room categories (+ add new).Category information"
                  )}
                </div>

                {/* Activate/Deactivate Room category SWITCH */}
                <Row className="mt-3" style={{ fontSize: "13px" }}>
                  <bootstrapForm.Label
                    className={"sub-header-text mb-0"}
                    style={{ fontSize: "13px" }}
                  >
                    {t(
                      "Modules.Room categories (+ add new).Activate category?"
                    )}
                    <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-right-common">
                          {t(
                            "Modules.Room categories (+ add new).Activate category - Description"
                          )}
                        </Tooltip>
                      }
                    >
                      <QuestionIcon className="pointer" />
                    </OverlayTrigger>
                  </bootstrapForm.Label>

                  <div>
                    <span className="switch-lable-off sub-header-text">
                      {t("Modules.Room categories (+ add new).Off")}
                    </span>
                    <Switch
                      name="is_active"
                      checked={values?.is_active}
                      onChange={(value) => setFieldValue("is_active", value)}
                    />
                    <span className="switch-lable sub-header-text">
                      {t("Modules.Room categories (+ add new).On")}
                    </span>
                  </div>
                </Row>

                {/* Room Category Name INPUT */}
                <Row className="mt-3">
                  <Col md={6}>
                    <bootstrapForm.Group>
                      <bootstrapForm.Label className={"sub-header-text"}>
                        {t("Modules.Room categories (+ add new).Category")}
                        <span style={{ color: "red" }}>*</span>
                      </bootstrapForm.Label>
                      <bootstrapForm.Control
                        type="text"
                        // placeholder="Category ipsum"
                        name="name"
                        onChange={handleChange}
                        value={values?.name.trimStart()}
                        isInvalid={touched?.name && errors?.name}
                      ></bootstrapForm.Control>
                    </bootstrapForm.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <CategoryDropDown
                      lang={PMSRoomTypesData ? PMSRoomTypesData : []}
                      lable={t(
                        "Modules.Room categories (+ add new).PMS Room Types"
                      )}
                      updateform={handleChange}
                      inpName={"category_mappings"}
                      defaultValue={dropdownValue}
                      clearValue={(updateContent) => {
                        setDropDownValue([]);
                        setFieldValue("category_mappings", []);
                      }}
                      touched={touched}
                      errors={errors}
                    ></CategoryDropDown>
                  </Col>
                </Row>
                <Row className="mt-3" style={{ fontSize: "13px" }}>
                  <label
                    className="sub-header-text"
                    style={{ fontSize: "13px" }}
                  >
                    {t("Modules.Rooms List.Allow Room Upgrade")}
                    {/* <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-right-common">
                          {t("Modules.Rooms List.Activate room - Description")}
                        </Tooltip>
                      }
                    >
                      <QuestionIcon className="pointer" />
                    </OverlayTrigger> */}
                  </label>
                  <div>
                    <span className="switch-lable sub-header-text">
                      {t("Modules.Rooms List.Off")}
                    </span>
                    <Switch
                      onChange={(value) => {
                        if (value) {
                          setFieldValue("allow_room_upgrade", 1);
                        } else {
                          setFieldValue("allow_room_upgrade", 0);
                        }
                      }}
                      checked={values?.allow_room_upgrade}
                      name="allow_room_upgrade"
                    />
                    <span className="switch-lable sub-header-text">
                      {t("Modules.Rooms List.On")}
                    </span>
                  </div>
                </Row>
                <Row className="mt-3" style={{ fontSize: "13px" }}>
                  <label
                    className="sub-header-text"
                    style={{ fontSize: "13px" }}
                  >
                    {t("Modules.Rooms List.Check Room Type Availability")}
                    {/* <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-right-common">
                          {t("Modules.Rooms List.Activate room - Description")}
                        </Tooltip>
                      }
                    >
                      <QuestionIcon className="pointer" />
                    </OverlayTrigger> */}
                  </label>
                  <div>
                    <span className="switch-lable sub-header-text">
                      {t("Modules.Rooms List.Off")}
                    </span>
                    <Switch
                      disabled={shouldHideToggle()}
                      onChange={(value) => {
                        if (value) {
                          setFieldValue("allow_overbooking", 1);
                        } else {
                          setFieldValue("allow_overbooking", 0);
                        }
                      }}
                      checked={values?.allow_overbooking}
                      name="allow_overbooking"
                    />
                    <span className="switch-lable sub-header-text">
                      {t("Modules.Rooms List.On")}
                    </span>
                  </div>
                </Row>
                <hr className="border-line" />
                <Row>
                  <Col md={6}>
                    <div className="sub-header mb-2">
                      {t(
                        "Modules.Room categories (+ add new).Category description"
                      )}
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <div className="sub-header-text">
                      {t(
                        "Modules.Room categories (+ add new).Category description - Description"
                      )}{" "}
                      <span className="m-1">
                        <OverlayTrigger
                          key="right"
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-right`}>
                              <Row>
                                <Col md={3}>
                                  {t(
                                    "Modules.Room categories (+ add new).Category description - Question mark"
                                  )}
                                </Col>
                                <Col>
                                  <img
                                    src={categoryDes}
                                    className={"tooltip-image"}
                                    alt={"greeting-overview"}
                                  ></img>
                                </Col>
                              </Row>
                            </Tooltip>
                          }
                        >
                          <QuestionIcon className="pointer" />
                        </OverlayTrigger>
                      </span>
                    </div>
                    <div
                      className={
                        errors.description && touched.description
                          ? "red-border mt-2"
                          : "no-border mt-2"
                      }
                    >
                      <CKEditorComponent
                        name="description"
                        errors={errors}
                        editorState={editorState}
                        setFieldValue={setFieldValue}
                        newContent={content}
                        setEditorState={(updateContent) => {
                          //setFieldValue("description", updateContent);
                          updateEditorState(updateContent);
                        }}
                      />
                      {/* <TextEditor
                    name="description"
                    editorState={editorState}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setEditorState={(updateContent) => {
                      updateEditorState(updateContent, setFieldValue);
                    }}
                  /> */}
                    </div>
                  </Col>
                </Row>
                <hr className="border-line" />
                <Row>
                  <Col md={12}>
                    <div className="sub-header mb-2">
                      {t("Modules.Room categories (+ add new).Pictures")}
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <div className="sub-header-text">
                      {t(
                        "Modules.Room categories (+ add new).Pictures - Description"
                      )}{" "}
                      <span className="m-1">
                        <OverlayTrigger
                          key="right"
                          placement="top-end"
                          overlay={
                            <Tooltip id={`tooltip-right`}>
                              <Row>
                                <Col md={3}>
                                  {t(
                                    "Modules.Room categories (+ add new).Pictures - Question mark"
                                  )}
                                </Col>
                                <Col>
                                  <img
                                    src={catImg}
                                    className={"tooltip-image"}
                                    alt={"greeting-overview"}
                                  ></img>
                                </Col>
                              </Row>
                            </Tooltip>
                          }
                        >
                          <QuestionIcon className="pointer" />
                        </OverlayTrigger>
                      </span>
                    </div>
                    <div
                      className={`${
                        touched.images &&
                        !values.images &&
                        !fileUploaded?.length
                          ? "form-control is-invalid"
                          : ""
                      } mt-3`}
                    >
                      <DndProvider backend={HTML5Backend}>
                        <DragDropSortable
                          name="images"
                          maximumFile={props.isForEdit ? 10 : 0}
                          multiUpload={true}
                          handleChange={setFileUploaded}
                          values={imageFromApi}
                          errors={errors}
                          touched={touched}
                          path={props.isForEdit ? "update" : "add"}
                          setImageSortData={setImageSortData}
                        />
                      </DndProvider>
                      <div>
                        <div style={{ fontSize: "small" }}>
                          {t("Common Translations.Picture Validation")}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <ConfirmWindow
                  className="room-categories-confirm"
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  isoverride={isOverride}
                  isnotoverride={isNotOverride}
                  isreplaceAll={isReplaceAll}
                  header={"Confirm"}
                  yesButtonLable={t(
                    "Modules.Room categories (edit).Yes button"
                  )}
                  noButtonLable={t(
                    "Modules.Room categories (edit).Cancel button"
                  )}
                  replaceButtonLable={t(
                    "Modules.Room categories (edit).Replace"
                  )}
                  body={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "Modules.Room categories (edit).Category description - Edit text - Warning message"
                        ),
                      }}
                    ></div>
                  }
                />

                <hr className="border-line" />
                <Row className="mt-3" style={{ fontSize: "13px" }}>
                  <div className="sub-header mb-2">
                    {t("Modules.Room categories (+ add new).Embed 3D Model")}
                  </div>

                  <div className="sub-header-text">
                    {t(
                      "Modules.Room categories (+ add new).Embed 3D Model - Description"
                    )}
                    <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-right-common">
                          {t(
                            "Modules.Room categories (+ add new).Embed 3D Model - Tooltip"
                          )}
                        </Tooltip>
                      }
                    >
                      <QuestionIcon className="pointer" />
                    </OverlayTrigger>
                  </div>
                  <div>
                    <span className="switch-lable">
                      {t("Modules.Room categories (+ add new).Off")}
                    </span>
                    <ReactSwitch
                      onChange={(value) => {
                        setFieldValue("is_3dmodel", value);
                      }}
                      checked={values?.is_3dmodel}
                      name="is_3dmodel"
                    />
                    <span className="switch-lable">
                      {t("Modules.Room categories (+ add new).On")}
                    </span>
                  </div>
                </Row>
                {values?.is_3dmodel ? (
                  <Row className="mt-3">
                    <Col md={6}>
                      <bootstrapForm.Group>
                        <bootstrapForm.Label className={"sub-header-text"}>
                          {t(
                            "Modules.Room categories (+ add new).3D Model URL"
                          )}
                          <span style={{ color: "red" }}>*</span>
                        </bootstrapForm.Label>
                        <bootstrapForm.Control
                          type="text"
                          name="model_3d_url"
                          onChange={handleChange}
                          value={values?.model_3d_url?.trimStart()}
                          isInvalid={
                            touched?.model_3d_url && errors?.model_3d_url
                          }
                        ></bootstrapForm.Control>
                        <span className="sub-header-text">
                          {t(
                            "Modules.Room categories (+ add new).3D Model URL - Description"
                          )}
                        </span>
                      </bootstrapForm.Group>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row className="mt-3">
                  {!props.isForEdit ? (
                    <Col md={4} sm={6} xs={6}>
                      {allowAccess ? (
                        <Button
                          variant="primary"
                          disabled={
                            props.selectedLanguageForUpdate !== props.baseLangId
                          }
                          onClick={() => {
                            submitForm();
                          }}
                        >
                          {t(
                            "Modules.Room categories (+ add new).+ Add new room category"
                          )}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Col>
                  ) : (
                    <Col md={2} sm={6} xs={6}>
                      {allowAccess ? (
                        <Button
                          variant="primary"
                          disabled={
                            props.selectedLanguageForUpdate !== props.baseLangId
                          }
                          onClick={() => {
                            validateFileUpload();
                            submitForm();
                          }}
                        >
                          {t("Modules.Room categories (+ add new).Save button")}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Col>
                  )}
                  <Col md={2} sm={6} xs={6}>
                    <Button variant="secondary" onClick={onClickCancelButton}>
                      {t("Modules.Room categories (+ add new).Cancel button")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            ) : (
              <Spinner />
            )}
          </>
        )}
      </Formik>
    </React.Fragment>
  );
};
