import React, { useEffect, useState } from "react";
import CKEditor from "react-ckeditor-component";
import { TEXT_EDITOR_CONFIG } from "../../helper/constants";
import { Utils } from "./../../utils/utils";

export const CKEditorComponent = ({
  editorState,
  setEditorState,
  newContent,
  showValidation = false,
  allowAccess = true,
}) => {
  const config = TEXT_EDITOR_CONFIG.ckEditorConfig;
  const [content, setContent] = useState(newContent ? newContent : editorState);
  const [isExcuted, setExcuted] = useState(false);

  useEffect(() => {
    console.log(`Effect Content :${newContent} - ${editorState} `);
    if (isExcuted) {
      setContent(editorState);
    } else {
      setTimeout(() => {
        setExcuted(true);
        setContent(editorState);
      }, 400);
    }
  }, [editorState]);

  const rawContent = () => {
    if (content) {
      return Utils.getHTMLtoPlainText(content);
    } else {
      return "";
    }
  };

  const onContentChange = (updatedContent) => {
    const htmlContent = updatedContent.editor.getData();
    console.log(`Timer HTML :`);
    setContent(htmlContent);
    // converting to plain text
    const plainText = Utils.getHTMLtoPlainText(htmlContent);
    // if plain text exist then passing HTML content else blank
    setEditorState(plainText ? htmlContent : "");
  };
  const invalidStyle = {
    border: `1px solid #f70000`,
  };

  return (
    <div style={rawContent()?.length > 2000 ? invalidStyle : {}}>
      <CKEditor
        activeClass="p10"
        config={{ ...config, readOnly: !allowAccess }}
        content={content || ""}
        events={{
          change: onContentChange,
        }}
      />
      <div
        className={`${
          (rawContent()?.length === 0 ||
            rawContent()?.length > +TEXT_EDITOR_CONFIG.MAX_CHAR_LIMIT) &&
          showValidation
            ? "text-editor-error"
            : ""
        } editor-footer rich-editor-wrapper`}
      >
        {`${rawContent()?.length || 0} / ${TEXT_EDITOR_CONFIG.MAX_CHAR_LIMIT}`}
      </div>
    </div>
  );
};
