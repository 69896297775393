import axios from "../axiosInstance";
import constanceAxios from "../constantsInstance";

class AssetConstantsService {
  getConstantsData = async (fields) => {
    try {
      const response = await axios.get(
        `/constants?fields=${fields.join(",").trim()}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  getLanguages = async (hotelId) => {
    try {
      const response = await axios.get(`/hotel-group/${hotelId}/languages`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getCurrencies = async () => {
    try {
      const response = await axios.get(`/currencies/list`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getPms = async (hotelId, isForParent = 0) => {
    try {
      const response = await axios.get(
        `pms/rooms/list?hotel_id=${hotelId}&is_for_parent=${isForParent}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  getHotelRoomCategory = async (hotelId, defaultSelectedLanguage) => {
    try {
      const response = await axios.get(
        `/room-categories/list?hotel_id=${hotelId}&language_id=${defaultSelectedLanguage}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  getHotelFloorAssign = async (hotelId) => {
    try {
      const response = await axios.get(`/floor/list?hotel_id=${hotelId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  fetchGetCountries() {
    return constanceAxios.get(`/countries`);
  }

  fetchGetStates(country_id) {
    return constanceAxios.get(`/states/${country_id}`);
  }
}

export default AssetConstantsService;
