import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import Breadcrumbs from "./../Common/Breadcrumbs";
import { RoomCategoriesForm } from "./RoomCategoriesForm";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as Vectors } from "../Common/icons/vectors.svg";
import SoftDeleteAlertModal from "../Common/SoftDeleteAlertModal";
import { RES_CODE } from "../../helper/constants";

export const EditRoomCategory = (props) => {
  const [categoryName, setCategoryName] = useState("");
  const categoryId = props.match.params.id;
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const dispatch = useDispatch();
  const roomCategoryByIdData = useSelector(
    (state) => state.roomCategories.roomCategoriesById
  );
  const hotelName = useSelector((state) => state?.hotelDetails?.result?.name);
  const langId = useSelector((state) => state.constants.languages);
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );
  const { t } = useTranslation();
  const [cmsContent, setCMSContent] = useState(null);

  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotels" },
    { path: "/hotel-room-categories", title: hotelName },
    { path: "/hotel-room-categories", title: "Room categories" },
    { path: `/hotel-room-categories/edit/${categoryId}`, title: categoryName },
  ];
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );
  i18next.on("languageChanged", (selectedLanguage) => {
    const langFiltered = langId.filter(
      (option) => option.language.key === selectedLanguage
    );
    onLanguageSelect(langFiltered[0]?.language?.id);
  });

  const onLanguageSelect = (selectedLanguage) => {
    setDefaultLanguage(selectedLanguage);
  };
  useEffect(() => {
    if (!defaultSelectedLanguage) {
      // setting up initially 0th index as lang id
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
    }
  }, [availableLanguagesFromSelector]);

  useEffect(() => {
    dispatch(actions.getRoomCategoryById(categoryId, hotelId));
  }, []);
  useEffect(() => {
    if (roomCategoryByIdData.id) {
      const changedCategoryName = findExistingLanguage(
        roomCategoryByIdData?.category_ts,
        defaultSelectedLanguage
      );
      if (
        changedCategoryName &&
        changedCategoryName?.language_id &&
        changedCategoryName.name.length
      ) {
        setCategoryName(changedCategoryName?.name);
      } else {
        setCategoryName(roomCategoryByIdData?.category_ts[0].name);
      }
    }
  }, [roomCategoryByIdData, defaultSelectedLanguage]);
  useEffect(() => {
    if (!defaultSelectedLanguage) {
      // setting up initially 0th index as lang id
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
    }
  }, [availableLanguagesFromSelector]);

  const findExistingLanguage = (languageBLock, selectedLangId) => {
    return (languageBLock || []).find((translationContent) => {
      return +translationContent.language_id === +selectedLangId;
    });
  };

  const apiResponseListing = useSelector((state) => state.network.data);
  const [showSoftDeleteAlert, setShowAlert] = useState(false);
  const [softDeleteAlertType, setSoftDeleteAlertType] =
    useState("confirmation");

  useEffect(() => {
    switch (+apiResponseListing?.status) {
      case +RES_CODE.BAD_REQ: {
        setShowAlert(true);
        setSoftDeleteAlertType("dependency");
        break;
      }
      default: {
        setShowAlert(false);
        setSoftDeleteAlertType("confirmation");
        break;
      }
    }
  }, [apiResponseListing]);

  return (
    <React.Fragment>
      <SoftDeleteAlertModal
        show={showSoftDeleteAlert}
        type={softDeleteAlertType}
        primaryMessage={
          softDeleteAlertType === "dependency"
            ? t("Modal Messages.Dependency Message")
            : t("Modal Messages.General Message")
        }
        secondaryMessage={
          softDeleteAlertType === "dependency"
            ? t("Modal Messages.Dependency Info Message", {
                linked: t("Modal Messages.Linked Room To Category"),
              })
            : t("Modal Messages.General Info Message")
        }
        onConfirm={() =>
          dispatch(actions.softDeleteRoomCategory({ id: categoryId }))
        }
        closeButtonText={
          softDeleteAlertType === "confirmation"
            ? t("Modal Messages.Cancel")
            : t("Modal Messages.Close")
        }
        onClose={() => {
          setShowAlert(false);
          setSoftDeleteAlertType("confirmation");
        }}
      />
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12} xs={12} className="alignSelf alignRight">
            <>
              <Dropdown className="customer-dropdown">
                <Dropdown.Toggle size="sm" variant="white">
                  <Vectors />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowAlert(true)}>
                    {t(
                      "Modules.Room categories (edit).Deactivate Room Category"
                    )}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <div className="header margin-left">
              {t("Modules.Room categories (edit).Edit room category")}
            </div>
          </Col>
        </Row>
      </div>
      <div className="content mt-3">
        <div
          className="alert alert-info informationBox sub-header mt-1 mb-1"
          role="alert"
        >
          <h6>
            {t(
              "Modules.Room categories (+ add new).Add new room category  - Information box"
            )}
          </h6>
        </div>
        <Row>
          <Col md={6}></Col>
          <Col md={6} className="alignContentCenter">
            {/* <Languages
              onChange={onLanguageSelect}
              defaultValue={defaultSelectedLanguage}
              valueAsKey={true}
              extraClasses="small-dropdown"
            /> */}
          </Col>
        </Row>
        <div>
          <RoomCategoriesForm
            isForEdit={true}
            selectedLanguageForUpdate={defaultSelectedLanguage}
            categoryDetails={roomCategoryByIdData}
            baseLangId={availableFilteredLanguages[0]?.language?.id}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
