export const FETCH_SERVICE_LIST = "FETCH_SERVICE_LIST";
export const FETCH_SERVICE_LIST_SUCCESS = "FETCH_SERVICE_LIST_SUCCESS";
export const FETCH_SERVICE_LIST_FAILED = "FETCH_SERVICE_LIST_FAILED";

export const FETCH_SERVICE_BY_ID = "FETCH_SERVICE_BY_ID";
export const FETCH_SERVICE_BY_ID_SUCCESS = "FETCH_SERVICE_BY_ID_SUCCESS";
export const FETCH_SERVICE_BY_ID_FAILED = "FETCH_SERVICE_BY_ID_FAILED";

export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAILED = "UPDATE_SERVICE_FAILED";

export const ADD_SERVICE = "ADD_SERVICE";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_FAILED = "ADD_SERVICE_FAILED";

export const SEARCH_SERVICE = "SEARCH_SERVICE";

export const SORT_CATEGORIES = "SORT_CATEGORIES";

export const EMAIL_SERVICE = "EMAIL_SERVICE"
export const EMAIL_SERVICE_SUCCESS = "EMAIL_SERVICE_SUCCESS"
export const EMAIL_SERVICE_FAILED = "EMAIL_SERVICE_FAILED"

export const GET_EMAIL_SERVICE = "GET_EMAIL_SERVICE"
export const GET_EMAIL_SERVICE_SUCCESS = "GET_EMAIL_SERVICE_SUCCESS"
export const GET_EMAIL_SERVICE_FAILED = "GET_EMAIL_SERVICE_FAILED"

export const SET_DEALS_SORT = "SET_DEALS_SORT"
export const SET_DEALS_LIST_PAGE_NO = "SET_DEALS_LIST_PAGE_NO"
export const CLEAR_SERVICE_BY_ID = "CLEAR_SERVICE_BY_ID"


export const GET_DEAL_TYPES = "GET_DEAL_TYPES"
export const GET_DEAL_TYPES_SUCCESS = "GET_DEAL_TYPES_SUCCESS"
export const GET_DEAL_TYPES_FAILED = "GET_DEAL_TYPES_FAILED"

export const SET_SEARCH_DEAL_LIST = "SET_SEARCH_DEAL_LIST"

export const SOFT_DELETE_DEAL = "SOFT_DELETE_DEAL";
export const SOFT_DELETE_DEAL_SUCCESS = "SOFT_DELETE_DEAL_SUCCESS";
export const SOFT_DELETE_DEAL_FAILED = "SOFT_DELETE_DEAL_FAILED";

export const getService = (payload) => {
    return {
      type: FETCH_SERVICE_LIST,
      payload,
    };
  };

  export const getServiceById = (hotelId,serviceId) => {
    return {
      type: FETCH_SERVICE_BY_ID,
      hotelId,
      serviceId,
    };
  };

  export const addService = (data) => {
    return {
      type: ADD_SERVICE,
      data,
    };
  };
  export const updateService = (serviceId,data) => {
    return {
      type: UPDATE_SERVICE,
      serviceId,
      data,
    };
  };
  export const search_service = (hotelId,searchKey) =>{
    return {
        type : SEARCH_SERVICE,
        hotelId,
        searchKey
    }
}
export const sortCategories = (hotelId,data) =>{
  return {
      type : SORT_CATEGORIES,
      hotelId,
      data
  }
}

export const emailService = (payload) =>{
  return{
    type: EMAIL_SERVICE,
    payload
  }
}
export const getEmailService = (hotelId) =>{
  return{
    type: GET_EMAIL_SERVICE,
    hotelId
  }
}

export const getDealTypes = () =>{
  return{
    type: GET_DEAL_TYPES,
  }
}

export const setSearchDealList = (payload)=>{
  return{
    type: SET_SEARCH_DEAL_LIST,
    payload
  }
}

export const softDeleteDeal = (payload) => {
  return {
    type: SOFT_DELETE_DEAL,
    payload,
  };
};