import React, { Component, createContext } from "react";

// Helper functions

function move(arr, fromIndex, toIndex) {
  let itemRemoved = arr.splice(fromIndex, 1); // assign the removed item as an array
  arr.splice(toIndex, 0, itemRemoved[0]); // insert itemRemoved into the target index
  return arr;
}

// Context

const GridContext = createContext({ items: [] });

export class GridProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      moveItem: this.moveItem,
      setItems: this.setItems,
    };
  }

  render() {
    return (
      <GridContext.Provider value={this.state}>
        {this.props.children}
      </GridContext.Provider>
    );
  }

  setItems = (items) => this.setState({ items });
  moveItem = (sourceId, destinationId) => {
    const sourceIndex = this.state.items.findIndex(
      (item) => item.id === sourceId
    );
    const destinationIndex = this.state.items.findIndex(
      (item) => item.id === destinationId
    );

    // If source/destination is unknown, do nothing.
    if (sourceId === -1 || destinationId === -1) {
      return;
    }

    const offset = destinationIndex - sourceIndex;

    this.setItems(move(this.state.items, sourceIndex, destinationIndex));
  };
}

export default GridContext;
