import { useSelector } from "react-redux";

//? CUSTOME HOOK to VALIDATE current session and authentication tokens
function useAuthValidation() {
  // to access authentication tokens if available in application state
  const authToken = useSelector((state) => state.login.result.token);
  const sessionToken = useSelector((state) => state.login.result.sessionToken);

  // VALIDATE authentication or session token exists for the user
  return (
    (authToken && authToken.length > 1) ||
    (sessionToken && sessionToken.length > 1)
  );
}

export default useAuthValidation;
