import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { StorageService } from "../../services/storage.service";
import actions from "../../store/actions/actions";
import BootstrapTable from "../Common/BootstrapTable";
import { ReactComponent as CheckSVG } from "../Common/icons/check.svg";
import { ReactComponent as CloseIcon } from "../Common/icons/close.svg";
import { ReactComponent as PencilSVG } from "../Common/icons/pencil.svg";
import PaginationComponent from "../Common/PaginationComponent";
import SearchComponents from "../Common/SearchComponents";
import Spinner from "../Common/Spinner";
import { Utils } from "./../../utils/utils";
import { StatusButton } from "./../Common/StatusButton";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export const RoomPricingList = (props) => {
  const [tableMapper, setTableMapper] = useState([]);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setSearchHotelRoomList(""));
    dispatch(actions.setSearchFloorList(""));
    dispatch(actions.setSearchDealList(""));
    dispatch(actions.setSearchOfferList(""));
    dispatch(actions.setSearchUserList(""));
  }, []);

  const isLoading = useSelector((state) => state?.network?.isLoading);
  const [allowAccess, setAllowAccess] = useState(true);

  const seasonPricingListData = useSelector(
    (state) => state?.hotelSeasonPricing?.result
  );

  const seasonSearchTerm = useSelector((state) => state?.common?.seasonSearch);

  const [roomPricing, setRoomPricing] = useState(null);
  const [searchTerm, setSearchTerm] = useState(seasonSearchTerm || null);
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );

  const [totalCount, setTotalCount] = useState(null);
  const [headerDescription, setHeaderDescription] = useState();
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(null);

  const seasonListCommonDetails = useSelector((state) => state?.common);

  const [pageNo, setPageNo] = useState(
    seasonListCommonDetails.seasonPricingListPageNo
  );
  const [sortOrder, setSortOrder] = useState(
    seasonListCommonDetails.seasonPriceSortBy
  );
  const [sortingColumnName, setSortingColumnName] = useState(
    seasonListCommonDetails.seasonPriceColumnName
  );

  const sortColumn = useSelector((state) => state?.common.sortForSeasonPricing);
  const [sort, setSort] = useState(sortColumn);

  const columnDataMap = {
    "SEASON NAME": "name",
    "START DATE": "start_date",
    "END DATE": "end_date",
  };

  const sortColumns = [
    t("Modules.Room Pricing.Season Name"),
    t("Modules.Room Pricing.Start date"),
    t("Modules.Room Pricing.End date"),
  ];

  // listening on language Changed event to update table header
  // TODO: update listing on language change
  // i18n.on("languageChanged", (selectedLang) => {
  //   mapTableInfo();
  // });

  useEffect(() => {
    if (seasonListCommonDetails) {
      //set current page no
      setPageNo(seasonListCommonDetails.seasonPricingListPageNo);
    }
  }, [seasonListCommonDetails]);

  useEffect(() => {
    setAllowAccess(
      Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.room_pricing_create)
    );
    if (!defaultSelectedLanguage && availableLanguagesFromSelector) {
      // setting up initially 0th index as lang id
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
    }
  }, [availableLanguagesFromSelector]);

  // useEffect(() => {
  //   if (i18n?.language) {
  //     const langInfo = selectedLangId(i18n?.language?.toLowerCase());
  //     setDefaultLanguage(langInfo?.language?.id);
  //     mapTableInfo();
  //   } else {
  //     setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
  //   }
  // }, [i18next.language]);

  useEffect(() => {
    if (defaultSelectedLanguage) {
      getTableInfo(
        seasonSearchTerm || "",
        seasonListCommonDetails.seasonPricingListPageNo
          ? seasonListCommonDetails.seasonPricingListPageNo
          : pageNo,
        seasonListCommonDetails.seasonPriceSortColumnName
          ? seasonListCommonDetails.seasonPriceSortColumnName
          : columnDataMap[sortingColumnName] || "",
        seasonListCommonDetails.seasonPriceSortBy
          ? seasonListCommonDetails.seasonPriceSortBy
          : sortOrder
      );
    }
  }, [defaultSelectedLanguage]);

  useEffect(() => {
    if (seasonPricingListData) {
      setTotalCount(seasonPricingListData?.totalCount);
      setRoomPricing(seasonPricingListData);
    }
  }, [seasonPricingListData]);

  const selectedLangId = (selectedLang) => {
    return availableLanguagesFromSelector?.find((langInfo) => {
      return (
        langInfo?.language?.code?.toLowerCase() ===
          selectedLang.toLowerCase() ||
        langInfo?.language?.key?.toLowerCase() === selectedLang.toLowerCase()
      );
    });
  };

  useEffect(() => {
    mapTableInfo();
  }, [roomPricing]);

  const getTableInfo = (
    searchTerm = "",
    pageNumber = pageNo,
    sortBy = columnDataMap[sortingColumnName] || "",
    sortOrder = "",
    filterType = extractedSeasonStatusType
  ) => {
    const payload = {
      searchKey: searchTerm?.trim(),
      hotel_id: hotelId,
    };

    if (sortOrder) {
      payload.sortBy = sortOrder;
    }

    if (defaultSelectedLanguage) {
      payload.language_id = defaultSelectedLanguage;
    }

    if (sortBy) {
      payload.sort = sortBy;
    }

    if (pageNo) {
      payload.page = pageNumber;
    }

    if (filterType) {
      payload.type = filterType;
    }

    dispatch(actions.getHotelSeasonPricingList(payload));
  };

  const mapTableInfo = () => {
    const mapper = roomPricing?.records?.map((RoomPricingInfo, index) => {
      const tableHeader = {
        id: RoomPricingInfo.id,
      };
      tableHeader[`${t("Modules.Room Pricing.Season Name")}`] =
        RoomPricingInfo.name;
      tableHeader[`${t("Modules.Room Pricing.Start date")}`] =
        RoomPricingInfo?.is_default === 1
          ? ""
          : Utils.getDDMMYY(RoomPricingInfo.start_date);
      tableHeader[`${t("Modules.Room Pricing.End date")}`] =
        RoomPricingInfo?.is_default === 1
          ? ""
          : Utils.getDDMMYY(RoomPricingInfo.end_date);
      tableHeader[`${t("Modules.Rooms List.Active")}`] =
        +RoomPricingInfo?.is_active === 1 ? (
          <StatusButton pending={"completed"}></StatusButton>
        ) : (
          <StatusButton pending={"pending"}></StatusButton>
        );
      tableHeader[`${t("Modules.Room Pricing.Default")}`] =
        RoomPricingInfo?.is_default ? <CheckSVG /> : <CloseIcon />;
      return tableHeader;
    });

    // setting up the status tooltip
    const tableHeaderDesc = {
      [`${t("Modules.Room Pricing.Status")}`]: t(
        "Modules.Room Pricing.Status - Question mark"
      ),
    };
    setHeaderDescription(tableHeaderDesc);

    setTableMapper(mapper);
  };

  const sortingClick = (event, headerInfo) => {
    const innerText = event?.target?.innerText?.trim() || "";
    setSortingColumnName(innerText);
    try {
      if (!sort && !Object.keys(sort)?.length) {
        setSort({ ...sort, [innerText]: true });
      } else {
        setSort({
          ...sort,
          [innerText]: !sort[innerText],
        });
      }
    } catch (error) {
      setSort({ ...sort, [innerText]: true });
    }
    setSortingColumnName(innerText);
    const sortOrder = sort[innerText] ? "ASC" : "DESC";
    setSortOrder(sortOrder);
    getTableInfo(
      searchTerm || "",
      pageNo,
      columnDataMap[headerInfo],
      sortOrder
    );
  };

  // redirecting to update room
  const onEditRow = (seasonId) => {
    history.push("/hotel-room-pricing/edit/" + seasonId);
  };

  const updatePagination = (pageNo) => {
    setPageNo(pageNo);
    getTableInfo(
      searchTerm || "",
      pageNo,
      columnDataMap[sortingColumnName],
      sortOrder
    );
  };

  const onSearchEvent = (searchTxt = "") => {
    dispatch(actions.setSearchSeason(searchTxt));
    setPageNo(1);
    setSearchTerm(searchTxt);
    getTableInfo(
      searchTxt || "",
      1,
      columnDataMap[sortingColumnName] || "",
      sortOrder || ""
    );
  };

  const tableExtraActions = [
    {
      icon: <PencilSVG className={"svg-s25"} />,
      onClick: onEditRow,
    },
  ];

  const seasonStatusTypes = [
    t("Modules.Room Pricing.All Seasons"),
    t("Modules.Room Pricing.Active Seasons"),
    t("Modules.Room Pricing.Inactive Seasons"),
  ];
  const [selectedSeasonStatusType, setSelectedSeasonStatusType] = useState(
    seasonStatusTypes[0]
  );
  const [extractedSeasonStatusType, setExtractedSeasonStatusType] = useState("");

  useEffect(() => {
    const headerInfo = {
      searchKey: "",
      sortBy: "ASC",
      sort: "username",
      page: 1,
    };

    let type = "";
    if (selectedSeasonStatusType === seasonStatusTypes[1]) {
      type = "active";
    } else if (selectedSeasonStatusType === seasonStatusTypes[2]) {
      type = "deactive";
    }

    setExtractedSeasonStatusType(type);

    getTableInfo(
      searchTerm,
      pageNo,
      columnDataMap[headerInfo],
      sortOrder,
      type
    );
  }, [selectedSeasonStatusType]);

  return (
    <React.Fragment>
      <div className="header">
        {t("Modules.Room Pricing.Room category pricing")}
      </div>
      <Row className="mt-3">
        <Col>
          <Row className="mb-3">
          <Col
                className="mb-md-0 mb-3"
                xs={12}
              >
                <div className="content-box ">
                  <SearchComponents
                    search={onSearchEvent}
                    buttonLabel={t("Modules.Room Pricing.Add a new season")}
                    addBtn={allowAccess}
                    path={PRIVATE_ROUTES.room_pricing_create}
                    commonSearchTerm={seasonSearchTerm}
                    className="fit-text-room"
                    dropDownData={seasonStatusTypes}
                    selectedDropDownValue={selectedSeasonStatusType}
                    onDropDownSelect={(selectedType)=>{
                      setPageNo(1);
                      setSelectedSeasonStatusType(selectedType);
                    }}
                  />
                </div>
              </Col>            
          </Row>
          <Row>
            <Col>
              <BootstrapTable
                sortColumns={sortColumns}
                currentlySorted={sort}
                isLoading={isLoading}
                sortingColumnName={sortingColumnName}
                onSortClick={sortingClick}
                tableExtraActions={allowAccess ? tableExtraActions : []}
                headerDescription={headerDescription}
                component={"Room Pricing"}
                tableMapper={tableMapper}
              />
            </Col>
          </Row>
          {!isLoading ? (
            <>
              <Row>
                <Col>
                  <PaginationComponent
                    pageNo={pageNo}
                    totalCount={Math.ceil(totalCount / 10)}
                    onChange={(number) => {
                      updatePagination(number);
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Spinner />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};
