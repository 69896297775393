import { call, put, takeLatest } from "redux-saga/effects";
import ExtraService from "./../../services/ExtraService/index";
import { StorageService } from "./../../services/storage.service";
import actions from "./../actions/actions";

const storageService = new StorageService();
const extraServe = new ExtraService();

const columnMapForReducer = {
  name: "DEAL NAME",
  price: "PRICE",
  start_date: "START DATE",
  end_date: "END DATE",
  active: "STATUS",
};

const getService = async (payload) => {
  try {
    const response = await extraServe.getService(payload);
    return response;
  } catch (err) {
    throw err;
  }
};
const getServiceById = async (hotelId, serviceId) => {
  try {
    const response = await extraServe.getServiceById(hotelId, serviceId);
    return response;
  } catch (err) {
    throw err;
  }
};

const createService = async (data) => {
  try {
    const response = await extraServe.addService(data);
    return response;
  } catch (err) {
    throw err;
  }
};

const updateRoomCategory = async (serviceId, data) => {
  try {
    const response = await extraServe.updateService(serviceId, data);
    return response;
  } catch (err) {
    throw err;
  }
};
const fetchSearchService = async (hotelId, searchKey) => {
  try {
    const response = await extraServe.getSearchService(hotelId, searchKey);
    return response;
  } catch (err) {
    throw err;
  }
};

const postEmailService = async (payload) => {
  try {
    const response = await extraServe.postEmailService(payload);
    return response;
  } catch (error) {
    throw error;
  }
};

const getEmailService = async (hotelId) => {
  try {
    const response = await extraServe.getEmailService(hotelId);
    return response;
  } catch (error) {
    throw error;
  }
};

const getDealTypes = async () => {
  try {
    const response = await extraServe.getDealTypes();
    return response;
  } catch (err) {
    throw err;
  }
};

function* dispatchGetServices(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(getService, action.payload);
    if (response.data.success) {
      yield put({ type: actions.API_SUCCESS });
      yield put({
        type: actions.FETCH_SERVICE_LIST_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
      yield put({
        type: actions.SET_DEALS_LIST_PAGE_NO,
        page: response?.data?.data.page,
      });
      yield put({
        type: actions.CLEAR_SERVICE_BY_ID,
      });
      yield put({
        type: actions.SET_DEALS_SORT,
        sortBy: action.payload.sortBy ? action.payload.sortBy : "ASC",
        sortName: action.payload.sort,
        sortColumnName: columnMapForReducer[action.payload.sort]
          ? columnMapForReducer[action.payload.sort]
          : "DEAL NAME",
        sort: action.payload.sortBy === "DESC" ? true : false,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.FETCH_SERVICE_LIST_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.FETCH_FLOOR_LIST_FAILED, msg: error?.message });
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}
function* dispatchSearchServices(action) {
  try {
    const response = yield call(
      fetchSearchService,
      action.hotelId,
      action.searchKey
    );
    if (response.data.success) {
      yield put({
        type: actions.FETCH_SERVICE_LIST_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
    } else {
      yield put({
        type: actions.FETCH_SERVICE_LIST_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}
function* dispatchServiceById(action) {
  try {
    const response = yield call(
      getServiceById,
      action.hotelId,
      action.serviceId
    );
    yield put({ type: actions.API_FETCHING });
    if (response.data.success) {
      yield put({ type: actions.API_SUCCESS });
      yield put({
        type: actions.FETCH_SERVICE_BY_ID_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.FETCH_SERVICE_BY_ID_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.FETCH_FLOOR_LIST_FAILED, msg: error?.message });
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchAddService(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(createService, action.data);
    const data = response;
    if (response.data.success) {
      yield put({
        type: actions.ADD_SERVICE_SUCCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: data,
      });
    } else {
      yield put({
        type: actions.ADD_SERVICE_FAILED,
        msg: response.data,
      });
      yield put({
        type: actions.API_FAILED,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchUpdateService(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(
      updateRoomCategory,
      action.serviceId,
      action.data
    );
    if (response.data.success) {
      yield put({
        type: actions.UPDATE_SERVICE_SUCCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
    } else {
      yield put({
        type: actions.UPDATE_SERVICE_FAILED,
        msg: response.data,
      });
      yield put({
        type: actions.API_FAILED,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchEmailService(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(postEmailService, action?.payload);
    if (response?.data?.success) {
      yield put({
        type: actions.EMAIL_SERVICE_SUCCESS,
        msg: response?.data?.msg,
      });
      yield put({
        type: actions.GET_EMAIL_SERVICE,
        hotelId: action?.payload?.hotelId,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    yield put({
      type: actions.EMAIL_SERVICE_FAILED,
      msg: error?.response,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchGetEmailService(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(getEmailService, action?.hotelId);
    if (response?.data?.success) {
      yield put({
        type: actions.GET_EMAIL_SERVICE_SUCCESS,
        data: response?.data,
        msg: response?.data?.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_EMAIL_SERVICE_FAILED,
      msg: error?.response,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchGetDealTypes() {
  try {
    let response = yield call(getDealTypes);
    if (response.data.success) {
      yield put({
        type: actions.GET_DEAL_TYPES_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.GET_DEAL_TYPES_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_DEAL_TYPES_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

const softDeleteDeal = async (payload) => {
  try {
    const response = await extraServe.softDeleteDeal(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

function* dispatchSoftDeleteDeal(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(softDeleteDeal,action.payload);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS, data: response });
      yield put({
        type: actions.SOFT_DELETE_DEAL_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.SOFT_DELETE_DEAL_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.SOFT_DELETE_DEAL_FAILED,
      msg: error?.message,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

export function* listengetServices() {
  yield takeLatest(actions.FETCH_SERVICE_LIST, dispatchGetServices);
}

export function* listenServiceById() {
  yield takeLatest(actions.FETCH_SERVICE_BY_ID, dispatchServiceById);
}

//get PMS Room Types
export function* listenaddService() {
  yield takeLatest(actions.ADD_SERVICE, dispatchAddService);
}

export function* listeneditService() {
  yield takeLatest(actions.UPDATE_SERVICE, dispatchUpdateService);
}

export function* listeneditSearchService() {
  yield takeLatest(actions.SEARCH_SERVICE, dispatchSearchServices);
}

export function* listenEmailService() {
  yield takeLatest(actions.EMAIL_SERVICE, dispatchEmailService);
}

export function* listenGetEmailService() {
  yield takeLatest(actions.GET_EMAIL_SERVICE, dispatchGetEmailService);
}

export function* listenGetDealType() {
  yield takeLatest(actions.GET_DEAL_TYPES, dispatchGetDealTypes);
}

export function* fetchSoftDeleteDeal() {
  yield takeLatest(actions.SOFT_DELETE_DEAL, dispatchSoftDeleteDeal);
}
