import { FieldArray, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form as bootstrapForm, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PRIVATE_ROUTES, TRANSLATION_TAB } from "../../helper/constants";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import { CKEditorComponent } from "./../Common/CkEditor";
import PaginationComponent from "./../Common/PaginationComponent";

export const OffersTranslation = ({
  data,
  languageIds,
  languageNames,
  hotelId,
  filter,
  totalCount,
  onPageChange,
  pageNo,
  setHasDataChanged,
}) => {
  const defaultLimit = 10;
  const [categoryTranslation, setCategoryTranslationData] = useState([]);
  const dispatch = useDispatch();
  const [forHeader, setForHeader] = useState([
    {
      header: "Name",
    },
    {
      header: "Field",
    },
  ]);
  const { t } = useTranslation();
  const formikRef = useRef();
  const [allowAccess, setAllowAccess] = useState(true);
  const [offersEditorData, setOffersEditorData] = useState({
    translation: [],
  });

  const formInitialValue = {
    translation: categoryTranslation,
  };

  const triggerUnsavedChanges = useSelector(
    (state) => state?.translationTab?.triggerUnsavedChanges
  );

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.deal_create));

    if (data) {
      setForHeader([...forHeader, ...languageNames]);
      const finalData = Utils.refactorCategoryTranslationData(
        data,
        "h_offers_and_services_ts"
      );
      const filteredData = Utils.filterCategoryTranslation(
        finalData,
        [...languageIds],
        "Name",
        "Description",
        TRANSLATION_TAB.OFFERS
      );
      setCategoryTranslationData(filteredData);
      setOffersEditorData({
        translation: filteredData,
      });
      offersEditorData.translation = filteredData;
      formikRef.current.setFieldValue(
        "translation",
        offersEditorData.translation
      );
    }
  }, [data]);

  useEffect(() => {
    if (triggerUnsavedChanges) {
      updateDealsTranslation();
      dispatch(actions.setUnsavedChangesFlag(false));
    }
  }, [triggerUnsavedChanges]);

  const updateDealsTranslation = () => {
    const dealsData = offersEditorData?.translation;
    dealsData.forEach((offerInfo) => {
      delete offerInfo.descriptionLabel;
      delete offerInfo.nameLabel;
      delete offerInfo.name;
      offerInfo.h_offers_and_services_ts = offerInfo.fields;
    });
    setHasDataChanged(false);
    dispatch(
      actions.updateTranslation(
        { offer: dealsData },
        hotelId,
        filter,
        pageNo,
        defaultLimit
      )
    );
  };

  const updatePagination = (pageNo) => {
    onPageChange({ page: +pageNo });
  };

  return (
    <div className="row-vertical-scroll">
      <Row className="mb-3">
        {forHeader?.map((headerInfo, index) => (
          <Col key={`header-${index}`}>
            <strong>{headerInfo?.header}</strong>
          </Col>
        ))}
      </Row>
      <Formik
        innerRef={formikRef}
        initialValues={{ ...formInitialValue }}
        onSubmit={(values) => {
          updateDealsTranslation();
        }}
      >
        {({ handleChange, values, submitForm, setFieldValue }) => (
          <>
            <FieldArray name="translations">
              {() => {
                const translationArray = values?.translation;
                return (
                  <>
                    {translationArray && translationArray.length ? (
                      <>
                        <Row>
                          {translationArray?.map((categoryInfo, index) => (
                            <React.Fragment key={`form-${index}`}>
                              <Col
                                key={`form-col-name-${index}`}
                                className="full-wrap"
                              >
                                <bootstrapForm.Control
                                  key={`season-label-form-${index}`}
                                  className="mt-3 disable-form"
                                  disabled={true}
                                  value={categoryInfo?.name || "NA"}
                                />
                              </Col>
                              <Col key={`form-label-col-${index}`}>
                                <div key={`form-label-name-${index}`}>
                                  <bootstrapForm.Control
                                    key={`translation-label-${index}`}
                                    type="text"
                                    value={categoryInfo.nameLabel}
                                    disabled={true}
                                    className="mt-3"
                                  />
                                </div>
                                <div key={`form-label-description-${index}`}>
                                  <bootstrapForm.Control
                                    key={`translation-label-${index}`}
                                    type="text"
                                    disabled={true}
                                    value={categoryInfo.descriptionLabel}
                                    className="mt-3"
                                  />
                                </div>
                              </Col>
                              {categoryInfo?.fields.map(
                                (translationValue, tagIndex) => (
                                  <Col key={`form-${tagIndex}-${index}`}>
                                    <div key={`form-div-${tagIndex}-${index}`}>
                                      <bootstrapForm.Control
                                        key={`translation-form-${tagIndex}`}
                                        type="text"
                                        disabled={
                                          translationValue?.language_id === 1
                                        }
                                        value={translationValue?.name}
                                        className="mt-3"
                                        name={`translation.${index}.fields.${tagIndex}.name`}
                                        onChange={(event) => {
                                          if (
                                            event.target.value.trim().length > 0
                                          ) {
                                            let originalName =
                                              translationValue.original_name
                                                ? translationValue.original_name
                                                : "";

                                            const isDescriptionUpdated =
                                              originalName.localeCompare(
                                                event.target.value
                                              );
                                            if (isDescriptionUpdated)
                                              setHasDataChanged(true);

                                            offersEditorData.translation[
                                              index
                                            ].fields[tagIndex].name =
                                              event.target.value;
                                            handleChange(event);
                                          } else if (
                                            event.target.value.trim().length < 1
                                          ) {
                                            offersEditorData.translation[
                                              index
                                            ].fields[tagIndex].name = "";
                                            setFieldValue(
                                              `translation.${index}.fields.${tagIndex}.name`,
                                              ""
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="mt-3"
                                      key={`form-div-2-${tagIndex}-${index}`}
                                    >
                                      {/* <bootstrapForm.Control
                                        key={`translation-form-${tagIndex}`}
                                        type="text"
                                        value={translationValue?.body}
                                        className="mt-3"
                                        name={`translation.${index}.fields.${tagIndex}.body`}
                                        onChange={handleChange}
                                      /> */}
                                      <CKEditorComponent
                                        className="mt-3"
                                        editorState={translationValue?.body}
                                        allowAccess={
                                          translationValue?.language_id !== 1
                                        }
                                        newContent={translationValue?.body}
                                        setEditorState={(updateContent) => {
                                          let originalDesc =
                                            translationValue.original_description
                                              ? translationValue.original_description
                                              : ""
                                                  .replaceAll("\n", "\\n")
                                                  .replaceAll("\r", "");
                                          const updatedDes = updateContent
                                            .replaceAll("\n", "\\n")
                                            .replaceAll("\r", "\\r");

                                          if (!originalDesc.endsWith("\\n")) {
                                            originalDesc = originalDesc + "\\n";
                                          }

                                          const isDescriptionUpdated =
                                            originalDesc.localeCompare(
                                              updatedDes
                                            );
                                          if (isDescriptionUpdated)
                                            setHasDataChanged(true);
                                          if (
                                            !(
                                              index in
                                              offersEditorData.translation
                                            )
                                          ) {
                                            offersEditorData.translation[
                                              index
                                            ] = {
                                              fields: [],
                                            };
                                            offersEditorData.translation[
                                              index
                                            ].fields[tagIndex] = {};
                                          }

                                          offersEditorData.translation[
                                            index
                                          ].fields[tagIndex].body =
                                            updateContent;

                                          // setFieldValue(
                                          //   `translation.${index}.fields.${tagIndex}.body`,
                                          //   updateContent
                                          // );
                                        }}
                                      />
                                    </div>
                                  </Col>
                                )
                              )}
                              <span className="mt-2 mb-2">
                                <hr />
                              </span>
                            </React.Fragment>
                          ))}
                          {/* } */}
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              }}
            </FieldArray>
            <Row>
              <Col>
                <PaginationComponent
                  pageNo={+pageNo}
                  totalCount={Math.ceil(totalCount / 10) || 1}
                  onChange={(number) => {
                    updatePagination(number);
                  }}
                />
              </Col>
            </Row>

            {allowAccess ? (
              <Button
                className="mt-3"
                variant="primary"
                onClick={() => {
                  submitForm();
                }}
              >
                {t("Common Translations.Save")}
              </Button>
            ) : (
              <></>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};
