import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getToken } from "../../services/auth.service";
import { StorageService } from "../../services/storage.service";
import actions from "../../store/actions/actions";
import { ReactComponent as UserSVG } from "../Common/icons/SingleUser.svg";
import useAuthValidation from "../../hooks/useAuthValidation";

const LoginDropdown = () => {
  const storageService = new StorageService();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = getToken();
  const userProfile = useSelector((state) => state?.user?.userProfile);
  const isProfile = useSelector((state) => state?.user?.getUserProfile);
  const accessToken = useSelector((state) => state?.login?.result?.AccessToken);

  const [profilePic, setProfilePic] = useState();

  // use custom hook to validate current session
  const isValidSession = useAuthValidation();

  useEffect(() => {
    //! NOTE: Condition added to resolve an edge case that occurs for SET PASSWORD FLOW
    //? when [BaseLayout] is mounted but no tokens are available
    // RESTRICT API calls when tokens are missing
    if (isValidSession) {
      // IF EXISTS then trigger the private routes
      dispatch(actions.getUserProfile({}));
    }
  }, []);

  useEffect(() => {
    if (userProfile) {
      if (userProfile.profile_picture) {
        setProfilePic(userProfile.profile_picture);
      } else {
        setProfilePic(null);
      }
    }
  }, [userProfile]);

  const logOut = () => {
    const accessToken = storageService.getItem("AccessToken");
    dispatch(actions.logOut({ accessToken: accessToken }));
  };
  const profile = () => {
    history.push("/user/profile");
  };
  return (
    <Dropdown>
      <Dropdown.Toggle size="sm" variant="white">
        {profilePic ? (
          <img src={profilePic} width="40" height="40" />
        ) : (
          <UserSVG />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => profile()}>Profile</Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            let state = { ...history.location.state };
            delete state.transaction;
            history.replace({ ...history.location, state });
            logOut();
          }}
        >
          Sign Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LoginDropdown;
