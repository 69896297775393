export const FETCH_TRANSLATION_TAB_LIST = "FETCH_TRANSLATION_TAB_LIST";
export const FETCH_TRANSLATION_TAB_LIST_SUCCESS =
  "FETCH_TRANSLATION_TAB_LIST_SUCCESS";
export const FETCH_TRANSLATION_TAB_LIST_FAILED =
  "FETCH_TRANSLATION_TAB_LIST_FAILED";

export const SAVE_TRANSLATION = "SAVE_TRANSLATION";
export const SAVE_TRANSLATION_SUCCESS = "SAVE_TRANSLATION_SUCCESS";
export const SAVE_TRANSLATION_FAILED = "SAVE_TRANSLATION_FAILED";

export const SET_UNSAVED_CHANGES_FLAG = "SET_UNSAVED_CHANGES_FLAG";

export const getTranslationTabInfo = (payload) => {
  return {
    type: FETCH_TRANSLATION_TAB_LIST,
    payload,
  };
};

export const updateTranslation = (
  payload,
  hotelId,
  filterType,
  page = false,
  limit = 10
) => {
  return {
    type: SAVE_TRANSLATION,
    hotelId,
    payload,
    filter: filterType,
    page,
    limit,
  };
};

export const setUnsavedChangesFlag = (payload) => {
  return {
    type: SET_UNSAVED_CHANGES_FLAG,
    payload,
  };
};
