import React, { useEffect, useState } from "react";
import {
  Col,
  Form as bootstrapForm,
  OverlayTrigger,
  Row,
  Tooltip,
  Button,
  Badge,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import { ReactComponent as CheckIcon } from "../Common/icons/check.svg";
import MultiDropDown from "../Common/MultiDropDown";
import SingleDropDown from "../Common/singleDropDown";
import ModalCopmponents from "../Common/ModalComponent";
import { FloorConfiguration } from "../Floor/FloorConfiguration";
import Select from "react-select";
import { Link } from "react-router-dom";
import ReactSwitch from "react-switch";

export const BasicInformation = (props) => {
  const { t } = useTranslation();
  const [primaryDropdown, setPrimaryDropdown] = useState(null);
  const [showModal, setshowModal] = useState(false);
  /* Updating description if category is changed */
  const changeCategory = (inputName, value) => {
    props.filedValue(inputName, value);

    //console.log(`Input Name : ${inputName} & Value : ${value}`);

    if (inputName === "category_id" && value) {
      let description = props.hotelRoomCategory.filter(
        (option) => option.id === value
      )[0].description;

      props.updateEditorText(description, value);
    }
  };

  useEffect(() => {
    if (props.values?.pms_child_room_ids?.length > 0) {
      props.setIsParentRoom(true);
    }
  }, [props.values]);
  const getType = (value) => {
    return typeof value;
  };
  const getParentRooms = () => {
    return props.pmsRoomsForParent.map((row) => {
      return {
        id: row.value,
        name: row.label,
      };
    });
  };

  /* Trimming of white space */
  const onFieldChange = (event) => {
    if (
      getType(event.target.value) === "string" &&
      event.target.value.trim().length > 0
    ) {
      props.change(event);
    } else if (getType(event.target.value) === "number") {
      props.change(event);
    } else if (event.target.value.trim().length < 1) {
      props.filedValue("name", "");
    }
  };

  const toggleShowModel = () => {
    setshowModal(!showModal);
  };

  // if hotspot data is mapped
  const isHotSpotMapped = props.values?.hotspot_data;

  return (
    <React.Fragment>
      <div className="header">{t("Modules.Rooms List.Room information")}</div>
      <br></br>
      <Row className="mt-3" style={{ fontSize: "13px" }}>
        <label className="sub-header-text" style={{ fontSize: "13px" }}>
          {t("Modules.Rooms List.Activate Room")}
          <OverlayTrigger
            key="right"
            placement="right"
            overlay={
              <Tooltip id="tooltip-right-common">
                {t("Modules.Rooms List.Activate room - Description")}
              </Tooltip>
            }
          >
            <QuestionIcon className="pointer" />
          </OverlayTrigger>
        </label>
        <div>
          <span className="switch-lable sub-header-text">
            {t("Modules.Rooms List.Off")}
          </span>
          <Switch
            onChange={(value) => {
              if (value) {
                props.filedValue("is_active", 1);
              } else {
                props.filedValue("is_active", 0);
              }
            }}
            checked={props.values?.is_active}
            name="is_active"
          />
          <span className="switch-lable sub-header-text">
            {t("Modules.Rooms List.On")}
          </span>
        </div>
      </Row>
      <Row className="mt-3">
        <Col md={2}>
          <bootstrapForm.Group
            className={primaryDropdown === 1 ? "z-index-1000" : "z-index-0"}
            onClick={() => {
              setPrimaryDropdown(1);
            }}
          >
            <bootstrapForm.Label className={"sub-header-text"}>
              {t("Modules.Rooms List.PMS Room Number")}{" "}
              <span style={{ color: "red" }}>*</span>
            </bootstrapForm.Label>
            <SingleDropDown
              val={props.pmsHotel}
              inpName={"pms_room_id"}
              updateDropdown={props.filedValue}
              touched={props.touched}
              errors={props.error}
              values={props.values}
            ></SingleDropDown>
          </bootstrapForm.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <bootstrapForm.Group>
            <bootstrapForm.Label className={"sub-header-text"}>
              {t("Modules.Rooms List.Room Name")}{" "}
              <span style={{ color: "red" }}>*</span>
            </bootstrapForm.Label>

            <bootstrapForm.Control
              type="text"
              // placeholder="Room Name"
              name="name"
              isInvalid={props.touched.name && props.error?.name}
              value={
                props.values?.name.trim().length < 1
                  ? props.values?.name?.trim()
                  : props.values?.name
              }
              onChange={onFieldChange}
            ></bootstrapForm.Control>
          </bootstrapForm.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <bootstrapForm.Group
            className={primaryDropdown === 2 ? "z-index-1000" : "z-index-0"}
            onClick={() => {
              setPrimaryDropdown(2);
            }}
          >
            <bootstrapForm.Label className={"sub-header-text"}>
              {t("Modules.Rooms List.Room category")}{" "}
              <span style={{ color: "red" }}>*</span>
            </bootstrapForm.Label>
            <SingleDropDown
              val={props.hotelRoomCategory}
              inpName={"category_id"}
              updateDropdown={changeCategory}
              touched={props.touched}
              errors={props.error}
              values={props.values}
              updateEditorText={props.updateEditorText}
              defaultSelectedLanguage={props.defaultSelectedLanguage}
            ></SingleDropDown>
          </bootstrapForm.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <bootstrapForm.Group
            className={primaryDropdown === 3 ? "z-index-1000" : "z-index-0"}
            onClick={() => {
              setPrimaryDropdown(3);
            }}
          >
            <bootstrapForm.Label className={"sub-header-text"}>
              {t("Modules.Rooms List.Assign to a floor")}{" "}
              <span style={{ color: "red" }}>*</span>
            </bootstrapForm.Label>
            <SingleDropDown
              val={props.hotelAssignFloor}
              inpName={"floor_id"}
              updateDropdown={props.filedValue}
              touched={props.touched}
              errors={props.error}
              values={props.values}
            ></SingleDropDown>
          </bootstrapForm.Group>
        </Col>
        <Col style={{ marginTop: "1.8rem" }}>
          <Button
            variant={`${isHotSpotMapped ? "success" : "primary"}`}
            onClick={toggleShowModel}
            disabled={!props.values?.floor_id}
          >
            {isHotSpotMapped && (
              <>
                {" "}
                <CheckIcon />{" "}
                <span>{t("Modules.Rooms List.Assigned room")}</span>
              </>
            )}
            {!isHotSpotMapped && (
              <span>{t("Modules.Rooms List.Room assign")}</span>
            )}
          </Button>
        </Col>
      </Row>
      <Row className="parent-room-check">
        {!(
          props.values?.pms_parent_room && props.values?.pms_parent_room?.name
        ) && (
          <bootstrapForm.Group>
            {/* check for default season */}
            <bootstrapForm.Check
              className="mt-3 sub-header-text"
              inline
              name="isDefaultSeason"
              checked={props.isParentRoom}
              value={props.isParentRoom}
              onChange={() => props.setIsParentRoom(!props.isParentRoom)}
              label={
                <bootstrapForm.Label className={"sub-header-text"}>
                  {t("Modules.Rooms List.Is Parent Room")}
                  <OverlayTrigger
                    key="right"
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right-common">
                        {t("Modules.Rooms List.Parent Room - Tooltip")}
                      </Tooltip>
                    }
                  >
                    <QuestionIcon className="pointer" />
                  </OverlayTrigger>
                </bootstrapForm.Label>
              }
              type="checkbox"
            />
          </bootstrapForm.Group>
        )}
        {props.isParentRoom && (
          <Col md={4}>
            <bootstrapForm.Group
              className={primaryDropdown === 2 ? "z-index-1000" : "z-index-0"}
              onClick={() => {
                setPrimaryDropdown(2);
              }}
            >
              <bootstrapForm.Label className={"sub-header-text"}>
                {t("Modules.Rooms List.Children Rooms")}{" "}
                <span style={{ color: "red" }}>*</span>
              </bootstrapForm.Label>
              <MultiDropDown
                lang={getParentRooms()}
                updateform={(e) => {
                  props.filedValue(e.target.name, e.target?.value?.toString());
                }}
                sendOldHotelValues={props.values}
                inpName={"pms_child_room_ids"}
              />
            </bootstrapForm.Group>
          </Col>
        )}
      </Row>
      <Row>
        {props.values?.pms_parent_room &&
          props.values?.pms_parent_room?.name && (
            <bootstrapForm.Group>
              <bootstrapForm.Label className={"sub-header-text"}>
                PARENT ROOM
              </bootstrapForm.Label>

              <div className="parent-room-block">
                <Badge className="parent-badge" variant="dark">
                  <Link
                    to={
                      "/update-room/" +
                      props.values?.pms_parent_room?.rooms[0]?.id
                    }
                    target="_blank"
                  >
                    <strong className="sub-header-text">
                      {props.values?.pms_parent_room.name}
                    </strong>
                  </Link>
                </Badge>
              </div>
            </bootstrapForm.Group>
          )}
      </Row>

      {/* modal component for floor configuration */}
      <ModalCopmponents
        onHide={toggleShowModel}
        header={""}
        size="md"
        show={showModal}
        className="room-floor-config-popup"
        body={
          <FloorConfiguration
            isPopup={true}
            toggleShowModel={toggleShowModel}
            onFieldChange={onFieldChange}
            hotspot_data={props.values?.hotspot_data}
            match={{
              params: {
                floor_id: props.values.floor_id,
                room_id: props.roomId,
              },
            }}
          />
        }
      ></ModalCopmponents>
    </React.Fragment>
  );
};

export default BasicInformation;
