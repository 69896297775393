export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";

export const ADD_USERS = "ADD_USERS";
export const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS";
export const ADD_USERS_FAILED = "ADD_USERS_FAILED";

export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_FAILED = "UPDATE_USERS_FAILED";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED";

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED";

export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILED = "GET_USER_BY_ID_FAILED";

export const SET_USER_SORT = "SET_USER_SORT";
export const SET_USER_LIST_PAGE_NO = "SET_USER_LIST_PAGE_NO";
export const CLEAR_USER_BY_ID = "CLEAR_USER_BY_ID";
export const CLEAR_USER_STATE = "CLEAR_USER_STATE";

export const SET_SEARCH_USER_LIST = "SET_SEARCH_USER_LIST";

export const getUsers = (hotelGroupId, payload) => {
  return {
    type: FETCH_USERS,
    hotelGroupId,
    payload,
  };
};

export const createUser = (payload) => {
  return {
    type: ADD_USERS,
    payload,
  };
};

export const UpdateUser = (userId, payload) => {
  return {
    type: UPDATE_USERS,
    payload,
    userId,
  };
};

export const getUserProfile = (payload) => {
  return {
    type: GET_USER_PROFILE,
    payload,
  };
};

export const updateUserProfile = (payload) => {
  return {
    type: UPDATE_USER_PROFILE,
    payload,
  };
};

export const getUserById = (userId) => {
  return {
    type: GET_USER_BY_ID,
    userId,
  };
};

export const setSearchUserList = (payload) => {
  return {
    type: SET_SEARCH_USER_LIST,
    payload,
  };
};