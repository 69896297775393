import React, { useEffect, useRef, useState } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastService } from "../../services/toast.service";
import actions from "../../store/actions/actions";
import FloorNameDropDown from "../Common/FloorNameDropDown";
import { ReactComponent as HotSpotIcon } from "../Common/HotSpot.svg";
import DraggableHotSpot from "../FloorMapping/DraggableHotSpot";
import { FloorRoomToolTip } from "../FloorMapping/FloorRoomTooltip";
import HotSpot from "../FloorMapping/HotSpot";
import PanZoom from "../FloorMapping/PanZoom";
import { StorageService } from "./../../services/storage.service";
import Breadcrumbs from "./../Common/Breadcrumbs";

export const FloorConfiguration = (props) => {
  const { t } = useTranslation();
  const [floorId, setFloorId] = useState(props.match.params.floor_id);
  const room_Id = props.match.params.room_id;
  const hotelName = useSelector((state) => state?.hotelDetails?.result?.name);
  const [roomName, setRoomName] = useState();

  //Todo Transalation
  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotels" },
    { path: "/hotel-room-list", title: hotelName },
    { path: "/hotel-room-list", title: "Room list" },
    { path: `/assign-room/${floorId}/${room_Id}`, title: roomName },
    {
      path: `/assign-room/${floorId}/${room_Id}`,
      title: "Floor configuration",
    },
  ];

  const [selectedRoom, setSelectedRoom] = useState({
    element: null,
    style: "",
  });
  const [assignedRoom, setAssignedRoom] = useState({
    room_id: "",
    map_data: "",
  });
  const flooDetails = useSelector((state) => state?.floor?.result);
  const floorNameList = useSelector((state) => state?.floor?.floorNameList);
  const isRoomAssigned = useSelector((state) => state?.floor?.isRoomAssigned);
  const [floorUrl, setFloorUrl] = useState();
  const [svg, setSvg] = useState();
  const dispatch = useDispatch();
  const toast = new ToastService();
  const history = useHistory();
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const [FloorName, setFloorName] = useState([]);
  const [selectedFloorName, setSelectedFloorName] = useState();
  const [isimageLoad, setIsImageLoad] = useState(false);
  const [scale, setScale] = useState(0.5);
  const [hotspotWidth, setHotspotWidth] = useState(2);

  //Todo
  const [hotspotData, setHotspotData] = useState();
  const [roomList, setRoomList] = useState();
  const [existingHotSpotData, setExistingHotSpotData] = useState();
  const [existingRoomHotSpot, setExistingRoomHotSpot] = useState();
  const [isInvalid, setisInvalid] = useState(false);
  const [formData, setFormData] = useState({
    room_id: "",
    id: "",
    floor_id: "",
  });
  const [hotspotCoOrdinatesData, setHotspotCoOrdinatesData] = useState();

  const onCancelClick = () => {
    // if floor configuration popup is opened close the popup
    if (props?.isPopup) {
      props.toggleShowModel();
      return;
    }
    redirectToRoomList();
  };

  const redirectToRoomList = () => {
    history.push("/hotel-room-list");
  };

  useEffect(() => {
    if (isRoomAssigned && flooDetails && hotspotData) {
      redirectToRoomList();
    }
  }, [flooDetails, isRoomAssigned]);

  useEffect(() => {
    if (props?.isPopup && hotspotWidth) {
      props.onFieldChange({
        target: {
          name: "hotspot_width",
          value: hotspotWidth,
        },
      });
    }
  }, [hotspotWidth]);

  const assignRoomonMap = () => {
    // if floor configuration popup is opened
    // update field value and close the popup
    if (props?.isPopup) {
      if ("onFieldChange" in props) {
        props.onFieldChange({
          target: {
            name: "hotspot_data",
            value: hotspotCoOrdinatesData,
          },
        });
      }
      props.toggleShowModel();
      return;
    }
    if (selectedFloorName) {
      if (!hotspotCoOrdinatesData) {
        toast.onWarning(
          t("Modules.Floor Configuration.Location not assigned warning")
        );
        return;
      }

      assignedRoom["floor_id"] = floorId;
      assignedRoom["map_data"] = hotspotCoOrdinatesData;
      assignedRoom["room_id"] = props.match.params.room_id;
      assignedRoom["hotspot_width"] = hotspotWidth;
      const isUpdatedFloor = !(props.match.params.floor_id === floorId);
      if (
        formData.room_id === parseInt(props.match.params.room_id) &&
        !isUpdatedFloor
      ) {
        assignedRoom["id"] = formData.id;
      }
      dispatch(actions.addAssignRoom(floorId, assignedRoom));
    } else {
      toast.onWarning(t("Modules.Floor Configuration.Update-floor-warning"));
      setisInvalid(true);
    }
  };
  const updateFloor = () => {
    if (selectedFloorName) {
      const payload = {
        floor_id: selectedFloorName.value,
        room_id: props.match.params.room_id,
      };
      dispatch(actions.editFloorAssignment(payload));
      setFloorId(selectedFloorName.value);
    } else {
      toast.onWarning(t("Modules.Floor Configuration.Update-floor-warning"));
      setisInvalid(true);
    }
  };

  useEffect(() => {
    dispatch(actions.getFloorNameList(hotelId));
    dispatch(actions.getFloorDetail(floorId));
  }, []);

  useEffect(() => {
    setScale(0.5);
    setHotspotData(false);
    setHotspotCoOrdinatesData(false);
  }, [floorId]);

  useEffect(() => {
    const floorName = floorNameList?.map((floor) => {
      return { value: floor.value, id: floor.id };
    });
    setFloorName(floorName);
    floorNameList?.filter(function (e) {
      if (e.id === parseInt(props.match.params.floor_id)) {
        setSelectedFloorName({ label: e.value, value: e.id });
      }
    });
  }, [floorNameList]);

  useEffect(() => {
    const roomList = [];
    flooDetails?.rooms?.forEach((room, i) => {
      if (room?.id === parseInt(props.match.params.room_id)) {
        setRoomName(room?.pms_room?.name);
      }
    });

    flooDetails?.h_floor_room_mappings?.forEach((room, i) => {
      let name = "";
      if (room?.room?.pms_room) {
        name = room?.room?.pms_room?.name;
      }
      if (room.room_id === parseInt(props.match.params.room_id)) {
        setFormData({
          ...formData,
          room_id: room.room_id,
          id: room.id,
          floor_id: room.floor_id,
          hotspot_width: room?.hotspot_width,
        });
      }
      roomList.push({
        room_id: room.room_id,
        hostspot_data: room.map_data,
        hotspot_width: room?.hotspot_width,
        name,
      });

      setHotspotWidth(room?.hotspot_width);
    });
    setRoomList(roomList);
  }, [flooDetails]);

  useEffect(() => {
    if (roomList && roomList.length && isimageLoad) {
      const doesRoomExist = roomList.filter(
        (room) => room.room_id === parseInt(props.match.params.room_id)
      );

      if (doesRoomExist.length === 0 && !props?.isPopup) {
        addDraggableHotSpot();
      }

      generateHotspotsFromExistingData(hotspotWidth);
    }
    if (roomList && roomList.length === 0 && isimageLoad) {
      addDraggableHotSpot();
    }
  }, [roomList, isimageLoad]);

  const imageHotSpotRef = useRef(null);

  // on image load zoom to active hotspot
  useEffect(() => {
    setTimeout(() => {
      if (
        isimageLoad &&
        imageHotSpotRef.current &&
        "instance" in imageHotSpotRef.current
      ) {
        imageHotSpotRef.current.zoomToElement(`draggable-hotspot`, 0.9, 400);
        setScale(0.9);
      }
    }, 200);
  }, [isimageLoad]);

  const getNewDraggableObject = (hotSpot = null) => {
    return (
      <DraggableHotSpot
        key={hotspotData}
        isNew={true}
        content={ButtonContent}
        setHotspotCoOrdinatesData={setHotspotCoOrdinatesData}
        hotspotWidth={hotspotWidth}
        transform={
          hotspotCoOrdinatesData
            ? hotspotCoOrdinatesData
            : hotSpot?.hostspot_data
        }
        imageHotSpotRef={imageHotSpotRef.current}
        scale={scale}
      />
    );
  };

  useEffect(() => {
    if (hotspotData) {
      setHotspotData(getNewDraggableObject(hotspotCoOrdinatesData));
      generateHotspotsFromExistingData(hotspotWidth);
    }
  }, [scale, hotspotWidth]);

  //todo need proper config
  const addDraggableHotSpot = () => {
    // add hidden hotspot with draggable hotspot
    // to fix the zoom to element issue
    setHotspotData(
      <>
        <DraggableHotSpot
          key={hotspotData}
          isNew={true}
          content={ButtonContent}
          setHotspotCoOrdinatesData={setHotspotCoOrdinatesData}
          hotspotWidth={hotspotWidth}
          defaultPosition={{ x: 300, y: -300 }}
          imageHotSpotRef={imageHotSpotRef.current}
          style={{ postion: "absolute", top: "5%" }}
          scale={scale}
        />
        <HotSpot
          content={existingButtonContent("dark", "", 3)}
          visibility="hidden"
          id="draggable-hotspot"
        ></HotSpot>
      </>
    );
  };
  const existingButtonContent = (variant = "dark", name = "", width) => {
    return (
      <FloorRoomToolTip name={name}>
        <button
          style={{ border: "none", background: "transparent" }}
          className="disabled-hotspot"
        >
          <HotSpotIcon
            style={{ pointerEvents: "none", width: `${width}rem` }}
          />
        </button>
      </FloorRoomToolTip>
    );
  };

  const generateHotspotsFromExistingData = (hotspotwidth = undefined) => {
    let popupMapData = false;

    // if floor popup is openeed from room creation only
    // add new hotspot on floor
    if (props?.isPopup && !room_Id) {
      const hotSpot = {
        room_id: "popup-room",
        hotspot_width: hotspotwidth,
        hostspot_data: props?.hotspot_data,
      };
      setExistingRoomHotSpot(hotSpot);
      setHotspotData(getNewDraggableObject(hotSpot));
      // add hidden hotspot with draggable hotspot
      // to fix the zoom to element issue
      popupMapData = (
        <HotSpot
          key={`draggableHotSpot-${hotSpot?.room_id}`}
          content={existingButtonContent(
            "dark",
            hotSpot.name,
            hotspotwidth ? hotspotwidth : parseInt(hotSpot?.hotspot_width)
          )}
          transform={hotSpot.hostspot_data}
          visibility="hidden"
          id="draggable-hotspot"
        ></HotSpot>
      );
    }

    // map all hotspots on floor plan
    if (roomList?.length >= 0 && isimageLoad) {
      const mappedData = roomList.map((hotSpot) => {
        const { transform, width } = JSON.parse(hotSpot.hostspot_data);
        if (hotSpot?.room_id === parseInt(props.match.params.room_id)) {
          const [x, y] = transform.replace("translate(", "").split(",");
          setExistingRoomHotSpot(hotSpot);
          setHotspotData(getNewDraggableObject(hotSpot));
          // add hidden hotspot with draggable hotspot
          // to fix the zoom to element issue
          return (
            <HotSpot
              key={`draggableHotSpot-${hotSpot?.room_id}`}
              content={existingButtonContent(
                "dark",
                hotSpot.name,
                hotspotwidth ? hotspotwidth : parseInt(hotSpot?.hotspot_width)
              )}
              transform={hotSpot.hostspot_data}
              visibility="hidden"
              id="draggable-hotspot"
            ></HotSpot>
          );
        } else {
          return (
            <HotSpot
              key={`draggableHotSpot-${hotSpot?.room_id}`}
              id={`room-selector-${props.match.params.room_id}`}
              content={existingButtonContent(
                "dark",
                hotSpot.name,
                hotspotwidth ? hotspotwidth : parseInt(hotSpot?.hotspot_width)
              )}
              transform={hotSpot.hostspot_data}
            ></HotSpot>
          );
        }
      });
      setExistingHotSpotData([...mappedData, popupMapData ? popupMapData : []]);
    } else {
      addDraggableHotSpot();
    }
  };

  const ButtonContent = (
    <OverlayTrigger
      placement="right"
      trigger="click"
      rootClose={true}
      overlay={
        <Tooltip className={"tooltip-floor-hotspot"}>
          <div>
            <Row>
              <Button
                variant="primary"
                onClick={() => setHotspotWidth(parseInt(hotspotWidth) + 1)}
                style={{ borderRadius: "60px 60px 0px 0px" }}
              >
                +
              </Button>
            </Row>
            <br />
            <Row>
              <Button
                variant="primary"
                onClick={() => {
                  if (parseInt(hotspotWidth) - 1 <= 1) {
                    setHotspotWidth(2);
                  } else {
                    setHotspotWidth(parseInt(hotspotWidth) - 1);
                  }
                }}
                style={{ borderRadius: "0px 0px 60px 60px" }}
              >
                -
              </Button>
            </Row>
          </div>
        </Tooltip>
      }
    >
      <button style={{ border: "none", background: "transparent" }}>
        <HotSpotIcon
          style={{
            pointerEvents: "none",
            stroke: "#365099",
            width: `${hotspotWidth}rem`,
          }}
        />
      </button>
    </OverlayTrigger>
  );

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        {/* hide breadcrumbs if floor configuration popup is opened */}
        {!props?.isPopup && (
          <Row>
            <Col>
              <Breadcrumbs BreadcrumbData={breadcrumbs} />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3 className="header">
              {" "}
              {t("Modules.Floor Configuration.Floor Configuration")}{" "}
            </h3>
          </Col>
        </Row>
      </div>
      <div className="content">
        {/* hide floor dropdown if floor configuration popup is opened */}
        {!props?.isPopup && (
          <>
            <div className="sub-header">
              {t("Modules.Floor Configuration.Floor Selection")}{" "}
              <span style={{ color: "red" }}>*</span>
            </div>
            <Row className="mt-3">
              <Col md={3} xs={9} sm={6}>
                <FloorNameDropDown
                  val={FloorName}
                  inpName={"floor_name"}
                  updateDropdown={(SelectedFloorName) => {
                    setSelectedFloorName(SelectedFloorName);
                    setisInvalid(false);
                  }}
                  values={selectedFloorName}
                  isInvalid={isInvalid}
                ></FloorNameDropDown>
              </Col>
              <Col md={3} xs={3} sm={6}>
                <Button variant="primary" onClick={updateFloor}>
                  {t("Modules.Floor Configuration.Update floor")}
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col md={10}>
            <div className="sub-header mt-3 mb-3">
              {t("Modules.Floor Configuration.Assign room")} {roomName}{" "}
              {t("Modules.Floor Configuration.on map")}{" "}
            </div>
            <div
              className="alert alert-info informationBox sub-header mt-3 mb-3"
              role="alert"
            >
              <h6>{t("Modules.Floor Configuration.Assignment Info")}</h6>
            </div>
            {/* {flooDetails?.floor_plan && (
              <Scroller setScale={setScale} scale={scale}>
                <ImageHotSpot
                  setRef={setg}
                  image={flooDetails.floor_plan}
                  hotSpots={hotspotData}
                  existingHotSpotData={existingHotSpotData}
                  imageLoad={setIsImageLoad}
                />
              </Scroller>
            )} */}

            <br />
            <div style={{ border: "solid" }}>
              {flooDetails?.floor_plan && (
                <PanZoom
                  imageHotSpotRef={imageHotSpotRef}
                  scale={scale}
                  setScale={setScale}
                  image={flooDetails.floor_plan}
                  hotSpots={hotspotData}
                  existingHotSpotData={existingHotSpotData}
                  imageLoad={setIsImageLoad}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={2} sm={6} xs={4}>
            <Button variant="primary" onClick={assignRoomonMap}>
              {t("Modules.Floor Configuration.Save button")}
            </Button>
          </Col>
          <Col md={1} sm={6} xs={8}>
            <Button variant="secondary" onClick={onCancelClick}>
              {t("Modules.Floor Configuration.Cancel button")}
            </Button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
