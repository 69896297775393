import { put, takeLatest } from "redux-saga/effects";
import { STORAGE_KEYS } from "../../helper/constants";
import {
  getAccessToken,
  getDashboardLink,
  getToken,
  removeToken as removeTokenFunction,
  getIsTenantSet,
  getConfigData,
} from "../../services/auth.service";
import { Utils } from "../../utils/utils";
import actions from "../actions/actions";
import { StorageService } from "./../../services/storage.service";

const storageService = new StorageService();
function* dispatchSetToken(action) {
  try {
    removeTokenFunction();    
    const token = "logged-in-user";
    const hotelGroupToken = token;
    const authTokens = action.authTokens;
    const userDetails = action.userDetails;
    const isTenantSet = action.isTenantSet;
    const pages = action.userDetails.pages;
    const hotelGroups = action.userDetails.hotelgroups;
    const configData = action.userDetails.domainNames;

    const dashboardLinks = JSON.stringify(action.userDetails?.dashboard_links);

    if (token && token.length) {
      storageService.setItem(
        STORAGE_KEYS.CONFIG_DATA,
        JSON.stringify(configData)
      );
      storageService.setItem(STORAGE_KEYS.hotelGroupToken, hotelGroupToken);
      storageService.setItem(
        STORAGE_KEYS.UserFirstName,
        userDetails.first_name
      );
      storageService.setItem(
        STORAGE_KEYS.dashboardLinks,
        JSON.stringify(userDetails?.dashboard_links)
      );
      storageService.setItem(STORAGE_KEYS.CONFIG_SET, isTenantSet);
      if (pages.length > 1) {
        const allowedPagesAccess = JSON.stringify(Utils.filterRoles(pages));
        storageService.setItem(STORAGE_KEYS.allowedPages, allowedPagesAccess);
        // storageService.setItem(STORAGE_KEYS.hotelGroupId, hotelGroups[0]);
      }
      yield put({
        type: actions.FETCH_TOKEN_SUCCESS,
        token,
        dashboardLinks,
        isTenantSet,
      });
    } else {
      yield put({
        type: actions.FETCH_TOKEN_FAILED,
      });
    }
  } catch (err) {
    console.log(err.stack);
    yield put({
      type: actions.FETCH_TOKEN_FAILED,
    });
  }
}

function* dispatchSetSessionToken(action) {
  try {
    // initial token value
    removeTokenFunction();
    const { token } = action;
    if (token && token.length) {
      storageService.setItem("session-token", token);
      yield put({
        type: actions.FETCH_SESSION_TOKEN_SUCCESS,
        token,
      });
    } else {
      yield put({
        type: actions.FETCH_SESSION_TOKEN_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: actions.FETCH_SESSION_TOKEN_FAILED,
    });
  }
}

function* dispatchRemoveToken() {
  yield put({
    type: actions.FETCH_TOKEN_FAILED,
  });
}

function* dispatchGetToken() {
  try {
    // initial token value
    const token = getToken();
    const dashboardLinks = getDashboardLink();
    const isTenantSet = getIsTenantSet();
    const configData = getConfigData();
    // TODO: condition refactor
    if (token && token.length && token !== "null") {
      yield put({
        type: actions.FETCH_TOKEN_SUCCESS,
        token,
        dashboardLinks,
        isTenantSet,
        configData,
      });
    } else {
      yield put({
        type: actions.FETCH_TOKEN_FAILED,
      });
    }
  } catch (err) {
    console.log(err.stack);
    yield put({
      type: actions.FETCH_TOKEN_FAILED,
    });
  }
}

export function* listenGetToken() {
  yield takeLatest(actions.GET_TOKEN, dispatchGetToken);
}

export function* listenSetToken() {
  yield takeLatest(actions.SET_TOKEN, dispatchSetToken);
}

export function* removeToken() {
  yield takeLatest(actions.FETCH_TOKEN_FAILED, dispatchRemoveToken);
}

export function* listenSetSessionToken() {
  yield takeLatest(actions.SET_SESSION_TOKEN, dispatchSetSessionToken);
}
