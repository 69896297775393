import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form as bootstrapForm,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import * as yup from "yup";
import { ReactComponent as DeleteSVG } from "../../components/Common/icons/delete.svg";
import { PRIVATE_ROUTES, REGEX } from "../../helper/constants";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import Spinner from "./../Common/Spinner";

const RoomPricingForm = ({
  isForEdit,
  roomCategoryList,
  hotelId,
  payload,
  extraSeasons = [],
  seasonId = null,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [deleted_dates, set_deleted_dates] = useState([]);
  const [ActiveDeletedDates, setActiveDeletedDates] = useState([]);
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );
  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };
  const isLoading = useSelector((state) => state?.network?.isLoading);
  const langId = useSelector((state) => state.constants.languages);
  const [allowAccess, setAllowAccess] = useState(true);

  const [defaultSelectedLanguage, setDefaultLanguage] = useState(null);
  const gotSuccess = useSelector((state) => state?.hotelSeasonPricing?.success);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [categoryPricingHeader, setCategoryPricingHeader] = useState([]);
  const hotelDetails = useSelector((state) => state?.hotelDetails?.result);
  const currencySymbol = hotelDetails?.currency?.symbol;

  const formInitialValue = {
    season_name: "",
    active_season: true,
    seasons_dates: [
      {
        id: null,
        start_date: "",
        end_date: "",
      },
    ],
    seasons_template: [],
    isDefaultSeason: false,
  };

  const formikSeasonDateSchema = yup.array().when("isDefaultSeason", {
    is: false,
    then: yup.array().of(
      yup.object().shape({
        start_date: yup.date().required("start date required"),
        end_date: yup
          .date()
          .required("end date required")
          .min(yup.ref("start_date")),
      })
    ),
  });

  const formSchema = yup.object().shape({
    season_name: yup.string().trim().required("Please Enter Special offers"),
    isDefaultSeason: yup.boolean(),
    seasons_dates: formikSeasonDateSchema.test("uniqueDate", function (date) {
      let flag = false;
      let index = [];
      this.parent?.seasons_dates?.forEach((dates, index1) => {
        this.parent?.seasons_dates?.forEach((checkDate, index2) => {
          if (
            new Date(checkDate?.start_date).toString() ===
              new Date(dates?.start_date).toString() &&
            new Date(checkDate?.end_date).toString() ===
              new Date(dates?.end_date).toString() &&
            index1 != index2
          ) {
            flag = true;
            if (!index.includes(index2)) {
              index.push(index2);
            }
          }
        });
      });

      if (flag) {
        const errorMsg = {};
        index.map((id) => {
          errorMsg[[id]] = {
            start_date: "Two Dates cannot be same",
            end_date: "Two Dates cannot be same",
          };
        });

        return this.createError({ message: errorMsg });
      } else {
        return true;
      }
    }),
    seasons_template: yup.array().of(
      yup.object().shape({
        pricing: yup.array().of(
          yup.object().shape({
            upsell: yup
              .number()
              .min(0)
              .test(
                "It's valid value",
                "invalid value, please enter valid integer value",
                (value) => (value + "").match(REGEX.INT_DECIMAL_REGEX)
              ),
            //  yup.number().integer().min(0).required("price is required"),
          })
        ),
      })
    ),
  });

  const onLanguageSelect = (selectedLanguage) => {
    if (selectedLanguage?.language_id)
      setDefaultLanguage(selectedLanguage?.language_id);
  };

  useEffect(() => {
    setAllowAccess(
      Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.room_pricing_create)
    );
    if (gotSuccess && !isForEdit) {
      clearForm();
      if (isFormSubmitted) {
        history.push("/hotel-room-pricing");
      }
    }
  }, [gotSuccess]);

  const clearForm = () => {
    formikRef.current?.resetForm();
  };

  useEffect(() => {
    if (!defaultSelectedLanguage) {
      if (i18n?.language) {
        const langInfo = selectedLangId(i18n?.language?.toLowerCase());
        if (langInfo) {
          setDefaultLanguage(langInfo?.language?.id);
        } else {
          setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
        }
      } else {
        // setting up initially 0th index as lang id
        setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
      }
    }
  }, [availableLanguagesFromSelector]);

  useEffect(() => {
    if (!isForEdit && roomCategoryList?.length) {
      generateSeasonTempArray(roomCategoryList);
    }
  }, [roomCategoryList]);

  useEffect(() => {
    if (isForEdit) {
      formikRef.current.setValues({ ...payload });
      if (payload?.is_default) {
        formikRef.current.setFieldValue("active_season", true);
      }

      if (payload.isDefaultSeason && +payload.isDefaultSeason === 1) {
        const deleteDates = payload.seasons_dates.map((existingDates) => {
          return existingDates.id;
        });
        setActiveDeletedDates(deleteDates);
        formikRef.current.setFieldValue("seasons_dates", []);
      }
      onDefaultSeasonToggle(
        payload.isDefaultSeason && +payload.isDefaultSeason === 1 ? true : false
      );
      const availableCategories = payload?.seasons_template?.map(
        (categoryInfo) => {
          return categoryInfo.name;
        }
      );

      setCategoryPricingHeader(availableCategories);
    }
  }, [payload]);

  useEffect(() => {
    // if there's no season date then adding blank, as of now it's required
    if (
      !(
        formikRef?.current?.values?.seasons_dates &&
        formikRef?.current?.values?.seasons_dates?.length
      )
    ) {
      formikRef.current.setFieldValue("seasons_dates", [
        {
          id: null,
          start_date: "",
          end_date: "",
        },
      ]);
    }
  }, [formikRef?.current]);

  const onDefaultSeasonToggle = (toggleEvent) => {};

  useEffect(() => {
    if (i18n?.language) {
      const langInfo = langId.find(
        (option) => option.language.key === i18n?.language
      );
      if (langInfo) {
        setDefaultLanguage(langInfo?.language?.id);
        onLanguageSelect(langInfo?.language?.id);
      } else {
        setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
        onLanguageSelect(availableFilteredLanguages[0]?.language?.id);
      }
    } else {
      // setting up initially 0th index as lang id
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
      onLanguageSelect(availableFilteredLanguages[0]?.language?.id);
    }
  }, [i18n?.language]);

  useEffect(() => {
    if (!isForEdit && roomCategoryList?.length) {
      if (defaultSelectedLanguage) generateSeasonTempArray(roomCategoryList);
    }
  }, [defaultSelectedLanguage]);

  // creating default empty array for new season pricing
  const generateSeasonTempArray = (roomCategoryList) => {
    const pricingArray = [];
    roomCategoryList.forEach((categoryParentInfo, parentIndex) => {
      const dummyPricingArray = [];
      roomCategoryList.forEach((categoryChildInfo, childIndex) => {
        dummyPricingArray.push({
          source_category_id: categoryChildInfo.id,
          target_category_id: categoryParentInfo.id,
          upsell: 0,
        });
      });

      let enTagname = "";
      const langTagObj = categoryParentInfo?.category_ts.reduce((acc, curr) => {
        acc[curr.language_id] = curr?.name;
        return acc;
      }, {});

      enTagname = langTagObj["1"];

      if (defaultSelectedLanguage + "" in langTagObj) {
        if (langTagObj[defaultSelectedLanguage + ""]?.length > 0)
          enTagname = langTagObj[defaultSelectedLanguage + ""];
      }

      pricingArray.push({
        name: enTagname,
        pricing: dummyPricingArray,
      });
    });
    const availableCategories = pricingArray.map((categoryInfo) => {
      return categoryInfo.name;
    });
    setCategoryPricingHeader(availableCategories);
    // setting up the season template
    formikRef.current.setFieldValue("seasons_template", pricingArray);
  };

  const statusToggle = () => (
    <span className="m-2">
      <OverlayTrigger
        key="right"
        placement="right"
        overlay={
          <Tooltip id={`tooltip-right-common`}>
            {isForEdit
              ? t("Modules.Room pricing (edit).Default season - Question mark")
              : t(
                  "Modules.Room pricing (+ add new).Default season - Question mark"
                )}
          </Tooltip>
        }
      >
        <QuestionIcon className="pointer" />
      </OverlayTrigger>
    </span>
  );

  i18n.on("languageChanged", (selectedLang) => {
    if (availableLanguagesFromSelector) {
      const langInfo = selectedLangId(selectedLang.toLowerCase());
      if (langInfo) {
        onLanguageSelect({ language_id: langInfo?.language?.id });
      }
    }
  });

  const selectedLangId = (selectedLang) => {
    return availableLanguagesFromSelector?.find((langInfo) => {
      return (
        langInfo?.language?.code?.toLowerCase() ===
          selectedLang.toLowerCase() ||
        langInfo?.language?.key?.toLowerCase() === selectedLang.toLowerCase()
      );
    });
  };

  // season pricing date config
  const seasonPricingConfig = (
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    setFieldError
  ) => (
    <FieldArray name="seasons_dates">
      {(arrayHelpers) => {
        const seasonDates = values?.seasons_dates;
        return (
          <div className="mt-3">
            {seasonDates && seasonDates?.length ? (
              seasonDates?.map((date, index) => (
                <Row key={`season-dates-${index}`} className="flex-center">
                  <Col md={3} sm={4} xs={12}>
                    <bootstrapForm.Label
                      className={"sub-header-text"}
                      style={{ fontSize: "13px" }}
                    >
                      {isForEdit
                        ? t("Modules.Room pricing (edit).Season start date")
                        : t(
                            "Modules.Room pricing (+ add new).Season start date"
                          )}
                      <span style={{ color: "red" }}>*</span>
                    </bootstrapForm.Label>

                    <bootstrapForm.Control
                      type="date"
                      name={`seasons_dates.${index}.start_date`}
                      disabled={date?.is_disable && isForEdit}
                      isInvalid={
                        touched?.seasons_dates &&
                        touched?.seasons_dates[index]?.start_date &&
                        errors?.seasons_dates &&
                        errors?.seasons_dates[index]?.start_date
                      }
                      onChange={handleChange}
                      value={
                        values.seasons_dates &&
                        values.seasons_dates[index].start_date
                      }
                      max={
                        values.seasons_dates &&
                        values.seasons_dates[index].end_date &&
                        values.seasons_dates[index].end_date
                      }
                    ></bootstrapForm.Control>
                  </Col>
                  <Col md={3} sm={4} xs={12}>
                    <bootstrapForm.Label
                      className={"sub-header-text"}
                      style={{ fontSize: "13px" }}
                    >
                      {isForEdit
                        ? t("Modules.Room pricing (edit).Season end date")
                        : t("Modules.Room pricing (+ add new).Season end date")}
                      <span style={{ color: "red" }}>*</span>
                    </bootstrapForm.Label>
                    <bootstrapForm.Control
                      type="date"
                      name={`seasons_dates.${index}.end_date`}
                      disabled={date?.is_disable && isForEdit}
                      isInvalid={
                        touched?.seasons_dates &&
                        touched?.seasons_dates[index]?.end_date &&
                        errors?.seasons_dates &&
                        errors?.seasons_dates[index]?.end_date
                      }
                      onChange={(event) => {
                        setFieldValue(
                          `seasons_dates.${index}.end_date`,
                          event.target.value
                        );
                      }}
                      value={
                        values.seasons_dates &&
                        values.seasons_dates[index].end_date
                      }
                      min={
                        values.seasons_dates &&
                        values.seasons_dates[index].start_date &&
                        values.seasons_dates[index].start_date
                      }
                      // value={`seasons_dates.${index}.end_date`}
                    ></bootstrapForm.Control>
                  </Col>
                  <Col md={6} sm={4} xs={12} className="flex-end">
                    <Row>
                      <Col md={3} className="pt-3">
                        {(seasonDates?.length > 1 ||
                          (date?.is_disable && isForEdit)) && (
                          <div
                            className="pointer"
                            onClick={() => {
                              if (isForEdit && seasonDates[index]?.id) {
                                set_deleted_dates([
                                  ...deleted_dates,
                                  +seasonDates[index]?.id,
                                ]);
                              }
                              arrayHelpers.remove(index);
                            }}
                          >
                            <DeleteSVG />
                          </div>
                        )}
                      </Col>
                      <Col md={6} className="pt-3">
                        {seasonDates?.length - 1 === index &&
                          !(date?.is_disable && isForEdit) && (
                            <>
                              {allowAccess ? (
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      start_date: "",
                                      end_date: "",
                                    })
                                  }
                                >
                                  {isForEdit
                                    ? t(
                                        "Modules.Room pricing (edit).+ Add new date range"
                                      )
                                    : t(
                                        "Modules.Room pricing (+ add new).+ Add new date range"
                                      )}
                                </Button>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))
            ) : (
              <></>
            )}
          </div>
        );
      }}
    </FieldArray>
  );

  const handleOnPriceChange = (e, handleChange, name) => {
    const value = parseFloat(
      e.target.value?.replace(currencySymbol, "").replaceAll(",", "")
    );

    if (value === "" || value >= 0) {
      handleChange({
        target: {
          value,
          name,
        },
      });
      handleChange("price", value === "" ? "" : +(value * 1).toString());
    } else {
      handleChange("price", "");
    }
  };

  const categoryPricingConfig = (values, touched, errors, handleChange) => (
    <Row>
      <Col md={2} xs={4} sm={4}>
        <Row>
          <Col md={12} xs={12} sm={12} className="mt-3">
            <div className="opacity0"> Blank </div>
          </Col>
          <Col className="mt-3 mb-3 ">
            {categoryPricingHeader?.map((panelName, index) => (
              <div key={index} className="mt-3 col-height">
                <bootstrapForm.Control
                  key={`season-label-form-${index}`}
                  className="mt-3 disable-form"
                  disabled={true}
                  value={panelName || "NA"}
                />
              </div>
            ))}
          </Col>
        </Row>
      </Col>
      <Col md={10} xs={8} sm={8}>
        <>
          <FieldArray name="seasons_template">
            {() => {
              const seasonTempArray = values?.seasons_template;
              return (
                <>
                  <Row className="row-vertical-scroll">
                    {seasonTempArray?.map((seasonTempValue, index) => (
                      <Col
                        md={3}
                        sm={4}
                        xs={6}
                        key={`season-temp-index-${index}`}
                        className="mt-3 mb-3"
                      >
                        <div
                          key={`season-temp-index-div-${index}`}
                          className="wrap-and-scroll"
                        >
                          <strong>{seasonTempValue?.name}</strong>
                        </div>
                        <div
                          key={`season-temp-div-tags-${index}`}
                          className="season-pricing-grid"
                        >
                          {seasonTempValue?.pricing.map(
                            (seasonPricingValue, tagIndex) => (
                              <>
                                {Utils.NumberFormat(
                                  currencySymbol,
                                  `season-temp-form-${tagIndex}`,
                                  seasonPricingValue?.upsell,
                                  "mt-3",
                                  index < tagIndex,
                                  touched?.seasons_template &&
                                    touched?.seasons_template[index]?.pricing[
                                      tagIndex
                                    ]?.upsell &&
                                    errors?.seasons_template &&
                                    errors?.seasons_template[index]?.pricing[
                                      tagIndex
                                    ]?.upsell,
                                  `seasons_template.${index}.pricing.${tagIndex}.upsell`,
                                  (e) =>
                                    handleOnPriceChange(
                                      e,
                                      handleChange,
                                      `seasons_template.${index}.pricing.${tagIndex}.upsell`
                                    ),
                                  bootstrapForm.Control
                                )}
                              </>
                            )
                          )}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </>
              );
            }}
          </FieldArray>
        </>
      </Col>
    </Row>
  );

  // creating new season
  const createNewSeason = (payload) => {
    dispatch(actions.createHotelSeasonPricing(payload));
    setFormSubmitted(true);
  };

  const updateSeason = (payload) => {
    dispatch(actions.editHotelSeasonPricing(payload));
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          ...formInitialValue,
        }}
        innerRef={formikRef}
        validationSchema={formSchema}
        onSubmit={(values) => {
          const {
            season_name,
            active_season,
            seasons_dates,
            seasons_template,
            isDefaultSeason,
          } = values;
          const payload = {
            name: season_name,
            is_active: active_season,
            is_default: isDefaultSeason,
            hotel_id: hotelId,
            language_id: defaultSelectedLanguage,
            seasons_dates,
          };
          if (seasons_template && seasons_template?.length) {
            const finalSeasonTemp = seasons_template.map((seasonTemp) => {
              return seasonTemp.pricing;
            });
            payload.seasons_template = [].concat.apply([], finalSeasonTemp);
          }
          if (payload.is_default) {
            // fetching deleted dates and removing it
            payload.deleted_dates = payload?.seasons_dates
              ? payload?.seasons_dates
                  .filter((dateInfo) => {
                    return dateInfo?.id;
                  })
                  .map((date) => {
                    return date.id;
                  })
                  .toString()
              : "";
            delete payload.seasons_dates;
          } else {
            // payload.is_default = false;
          }
          if (!isForEdit) {
            createNewSeason(payload);
          } else {
            if (deleted_dates?.length) {
              payload.deleted_dates = deleted_dates.toString();
            }
            payload.seasonId = seasonId;
            if (ActiveDeletedDates && ActiveDeletedDates?.length) {
              payload.deleted_dates =
                payload.deleted_dates && payload.deleted_dates?.length
                  ? payload.deleted_dates + `,${ActiveDeletedDates.toString()}`
                  : ActiveDeletedDates.toString();
            }

            updateSeason(payload);
          }
        }}
      >
        {({
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
          submitForm,
        }) =>
          !isLoading ? (
            <Form>
              <Row>
                <Col md={6} sm={6} xs={6}>
                  <div className="header">
                    {isForEdit
                      ? t("Modules.Room pricing (edit).Season Information")
                      : t(
                          "Modules.Room pricing (+ add new).Season Information"
                        )}
                  </div>
                </Col>
                {isForEdit && (
                  <Col md={6} className="alignContentCenter">
                    {/* <Languages
                        onChange={onLanguageSelect}
                        defaultValue={defaultSelectedLanguage}
                        valueAsKey={true}
                        extraClasses="small-dropdown"
                      /> */}
                  </Col>
                )}
              </Row>
              <Row className="mt-3" style={{ fontSize: "13px" }}>
                <label
                  className={"sub-header-text"}
                  style={{ fontSize: "13px" }}
                >
                  {isForEdit
                    ? t("Modules.Room pricing (edit).Activate season?")
                    : t("Modules.Room pricing (+ add new).Activate season?")}
                </label>
                <div>
                  <span className="switch-lable sub-header-text">
                    {isForEdit
                      ? t("Modules.Room pricing (edit).Off")
                      : t("Modules.Room pricing (+ add new).Off")}
                  </span>
                  <Switch
                    onChange={(value) => {
                      if (!values.isDefaultSeason) {
                        setFieldValue("active_season", value);
                      }
                    }}
                    name="active_season"
                    checked={values.active_season}
                  />
                  <span className="switch-lable sub-header-text">
                    {isForEdit
                      ? t("Modules.Room pricing (edit).On")
                      : t("Modules.Room pricing (+ add new).On")}
                  </span>
                </div>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <bootstrapForm.Group>
                    <bootstrapForm.Label className={"sub-header-text"}>
                      {isForEdit
                        ? t("Modules.Room pricing (edit).Season name")
                        : t("Modules.Room pricing (+ add new).Season name")}
                      <span style={{ color: "red" }}>*</span>
                    </bootstrapForm.Label>
                    <bootstrapForm.Control
                      type="text"
                      // placeholder={t(
                      //   "Modules.Room pricing (+ add new).Season Name Placeholder"
                      // )}
                      name="season_name"
                      isInvalid={touched?.season_name && errors?.season_name}
                      onChange={handleChange}
                      value={values?.season_name?.trimStart()}
                    ></bootstrapForm.Control>
                  </bootstrapForm.Group>
                </Col>
              </Row>
              <div>
                {/* check for default season */}
                <bootstrapForm.Check
                  className="mt-3 sub-header-text"
                  inline
                  name="isDefaultSeason"
                  checked={values?.isDefaultSeason}
                  value={values?.isDefaultSeason}
                  onChange={() => {
                    const value = !values?.isDefaultSeason;
                    setFieldValue("isDefaultSeason", value);
                    // setting up validation on date according to default season
                    onDefaultSeasonToggle(value);
                    if (value) {
                      // setFieldValue("seasons_dates", []);

                      setFieldValue("active_season", true);
                    } else {
                      // setFieldValue("seasons_dates", [
                      //   {
                      //     start_date: "",
                      //     end_date: "",
                      //   },
                      // ]);
                    }
                  }}
                  label={
                    isForEdit
                      ? t("Modules.Room pricing (edit).Default season")
                      : t("Modules.Room pricing (+ add new).Default season")
                  }
                  type="checkbox"
                />
                {statusToggle()}
              </div>
              {/* season start and end dates */}
              {!values?.isDefaultSeason &&
                seasonPricingConfig(
                  values,
                  touched,
                  errors,
                  handleChange,
                  setFieldValue
                )}

              <hr className="border-line" />
              <div>
                <div className="sub-header">
                  {isForEdit
                    ? t("Modules.Room pricing (edit).Room category pricing")
                    : t(
                        "Modules.Room pricing (+ add new).Room category pricing"
                      )}
                </div>
                <div className="sub-header-text">
                  {isForEdit
                    ? t(
                        "Modules.Room pricing (edit).Room category pricing - Description"
                      )
                    : t(
                        "Modules.Room pricing (+ add new).Room category pricing - Description"
                      )}
                </div>
                {/* room category pricing form */}
                <div className="mt-3">
                  {!(roomCategoryList && roomCategoryList?.length) && (
                    // TODO: need to add dynamic msg
                    <strong>
                      Please Add room category first to add a new Season
                    </strong>
                  )}
                  {categoryPricingConfig(values, touched, errors, handleChange)}
                </div>
              </div>
              <Row className="mt-3">
                {!isForEdit ? (
                  <Col md={2} sm={6} xs={6}>
                    {hiddenIconAsPerRole(PRIVATE_ROUTES.room_pricing_create) ? (
                      <Button
                        variant="primary"
                        // allowing to submit if lang is primary/base lang
                        disabled={
                          defaultSelectedLanguage !==
                          availableFilteredLanguages[0]?.language?.id
                        }
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        {t("Modules.Room pricing (+ add new).+ Add new season")}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Col>
                ) : (
                  <Col md={2} sm={6} xs={6}>
                    {hiddenIconAsPerRole(PRIVATE_ROUTES.room_pricing_edit) ? (
                      <Button
                        // allowing to submit if lang is primary/base lang
                        disabled={
                          defaultSelectedLanguage !==
                          availableFilteredLanguages[0]?.language?.id
                        }
                        variant="primary"
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        {t("Modules.Room pricing (edit).Save button")}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Col>
                )}
                <Col md={2} sm={6} xs={6}>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      history.push("/hotel-room-pricing");
                    }}
                  >
                    {isForEdit
                      ? t("Modules.Room pricing (edit).Cancel button")
                      : t("Modules.Room pricing (+ add new).Cancel button")}
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Spinner />
          )
        }
      </Formik>
    </React.Fragment>
  );
};

export default RoomPricingForm;
