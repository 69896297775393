export const GET_ROLE = "GET_ROLE";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILED = "GET_ROLE_FAILED";
export const SET_HG = "SET_HG";
export const SET_HG_SUCCESS = "SET_HG_SUCCESS";
export const SET_HG_FAILED = "SET_HG_FAILED";

export const getRole = (hotelGroupId) => {
  return {
    type: GET_ROLE,
    hotelGroupId,
  };
};

export const setHG = (hotelGroupId) => {
  return {
    type: SET_HG,
    payload: hotelGroupId,
  };
};
