import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/app.scss";
import { Toaster } from "react-hot-toast";
import { TOAST_CONFIG } from "./helper/constants";
import { Routes } from "./routes/routes";
import { APIResponseHandling } from "./helper/APIResponseHandling";

function App() {
  return (
    <BrowserRouter>
      {/* toast management */}
      <Toaster position={TOAST_CONFIG.default.position} reverseOrder={true} />

      <APIResponseHandling />
      <Routes />
    </BrowserRouter>
  );
}

export default App;
