export const GET_HOTELS_FROM_HOTELGROUP = "GET_HOTELS_FROM_HOTELGROUP";
export const FETCH_HOTELS_FROM_HOTELGROUP_SUCESS =
  "FETCH_HOTELS_FROM_HOTELGROUP_SUCESS";
export const FETCH_HOTELS_FROM_HOTELGROUP_FAILED =
  "FETCH_HOTELS_FROM_HOTELGROUP_FAILED";
export const FETCH_HOTELS_BASIC_DETAILS = "FETCH_HOTELS_BASIC_DETAILS";
export const FETCH_HOTEL_TAGS = "FETCH_HOTEL_TAGS";

export const GET_HOTELGROUP_LIST = "GET_HOTELGROUP_LIST";
export const GET_HOTELGROUP_LIST_SUCCESS = "GET_HOTELGROUP_LIST_SUCCESS";
export const GET_HOTELGROUP_LIST_FAILED = "GET_HOTELGROUP_LIST_FAILED";

export const GET_HOTELS = "GET_HOTELS";
export const GET_HOTELS_SUCCESS = "GET_HOTELS_SUCCESS";
export const GET_HOTELS_FAILED = "GET_HOTELS_FAILED";

export const ADD_TRACKING_DATA = "ADD_TRACKING_DATA";
export const ADD_TRACKING_DATA_SUCCESS = "ADD_TRACKING_DATA_SUCCESS";
export const ADD_TRACKING_DATA_FAILED = "ADD_TRACKING_DATA_FAILED";

export const getHotelsFromHotelGroup = (id, page, limit) => {
  return {
    type: GET_HOTELS_FROM_HOTELGROUP,
    id,
    page,
    limit,
  };
};

export const getHotels = (id) => {
  return {
    type: GET_HOTELS,
    id,
  };
};

export const getHotelGroupList = () => {
  return {
    type: GET_HOTELGROUP_LIST,
  };
};

export const addTrackingData = (payload) => {
  return {
    type: ADD_TRACKING_DATA,
    payload,
  };
};
