import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../Common/icons/delete.svg";
import { ReactComponent as RoomcatDragDrop } from "../Common/icons/RoomcatDragDrop.svg";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import { ToastService } from "./../../services/toast.service";
import { Utils } from "./../../utils/utils";
import produce from "immer";
import { Flipper, Flipped } from "react-flip-toolkit";
import { arrayMoveImmutable } from "array-move";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
//import DragItem from "./DragItem";

export const AddDnDSingleTags = ({
  updateTags,
  removeTags,
  existingTags,
  setNewTags,
  selectedLanguage,
  allowAccess = true,
}) => {
  const { t } = useTranslation();
  const [inputTag, setInputTag] = useState("");
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );

  const toastService = new ToastService();

  useEffect(() => {
    let tags = [];
    // if language exist then
    if (+selectedLanguage) {
      tags = availableTags(selectedLanguage);

      if (!tags.length) {
        tags = availableTags(availableFilteredLanguages[0]?.language?.id);
      }
      tags = [].concat.apply([], tags);
    } else {
      tags = existingTags?.map((tagInfo) => {
        return tagInfo.name;
      });
    }
    const appendTags = updateTags.tags?.length ? [] : updateTags.tags;

    updateTags.setTags([...appendTags, ...tags]);
  }, [existingTags, selectedLanguage]);

  const availableTags = (langId) => {
    const availableTags = existingTags?.map((tagInfo) => {
      /* return tagInfo?.tags_ts
        ?.filter((translatedTag) => {
          let flagFound = false
          if(+translatedTag.language_id === +langId &&
            translatedTag?.name?.trim().length){
              flagFound=true
              return (
                +translatedTag.language_id === +langId &&
                translatedTag?.name?.trim().length
              );
            }
        })
        .map((info) => {
          if (info) {
            return info.name;
          }
        }); */

      return {
        name: Utils.langValueGenerator(tagInfo?.tags_ts, "name", +langId),
        id: tagInfo.id,
      };
    });
    return availableTags.filter((value) => value.name?.length);
  };

  const softDeleteTag = (tags, tagName) => {
    updateTags.setTags(tags.filter((tag, i) => tag.name !== tagName));
  };

  const removeTag = (tagName) => {
    const isExistingTag = filterTagsRemove(existingTags, true, tagName);

    if (isExistingTag && isExistingTag?.id) {
      removeTags.setDeletedTags([...removeTags.deletedTags, isExistingTag?.id]);
      softDeleteTag(updateTags.tags, tagName);
    } else {
      softDeleteTag(updateTags.tags, tagName);
    }

    const isNewTagExist = setNewTags.newTags.find((tagInfo) => {
      return tagName.trim() === tagInfo.name.trim();
    });

    if (isNewTagExist) {
      const newTags = setNewTags.newTags;
      const existingIdInTagIndex = newTags.indexOf(isNewTagExist);
      // removing from delete if already exist
      if (existingIdInTagIndex !== -1) {
        newTags.splice(existingIdInTagIndex, 1);
        setNewTags.setNewTags([...newTags]);
      }
    }
  };

  // finding filter tag and returning existing one
  const filterTags = (tagArray, isByName = false, tagName) => {
    const isExist = tagArray.find((tagInfo) => {
      const baseTag = (tagInfo?.tags_ts || []).filter(
        (tag) => tag.language_id === 1
      );
      let compareValue = "";
      if (isByName) {
        compareValue = baseTag[0]?.name.toLowerCase().trim();
      } else {      
        compareValue = tagInfo.name.toLowerCase().trim();
      }

      return tagName.toLowerCase().trim() === compareValue;
    });

    return isExist;
  };

  const filterTagsRemove = (tagArray, isByName = false, tagName) => {
    const isExist = tagArray.find((tagInfo) => {
      const baseTag = (tagInfo?.tags_ts || []).filter(
        (tag) => tag.language_id === 1
      );
      let compareValue = "";
      if (isByName) {
        compareValue = baseTag[0]?.name.trim();
      } else {
        compareValue = tagInfo.name.trim();
      }

      return tagName.trim() === compareValue;
    });

    return isExist;
  };

  const onDrag = (result) => {
    moveCard(result.source.index, result.destination.index);
  };

  const addTagsOnClick = () => {
    if (inputTag.trim()) {
      const isTagExist = filterTags(updateTags.tags, false, inputTag.trim());
      if (!isTagExist) {
        const isActualTagExist = filterTagsRemove(
          existingTags,
          true,
          inputTag.trim()
        );
        if (isActualTagExist && isActualTagExist?.id) {
          const deletedTags = removeTags.deletedTags;
          const existingIdInTagIndex = deletedTags.indexOf(
            isActualTagExist?.id
          );
          // removing from delete if already exist
          if (existingIdInTagIndex !== -1) {
            deletedTags.splice(existingIdInTagIndex, 1);
            removeTags.setDeletedTags([...deletedTags]);
          }
        }

        const isTagExistInAPI = filterTagsRemove(
          existingTags,
          true,
          inputTag.trim()
        );
        if (!isTagExistInAPI) {
          setNewTags.setNewTags([
            { name: inputTag.trim(), id: inputTag.trim() },
            ...setNewTags.newTags,
          ]);
        }
        updateTags.setTags([
          { name: inputTag.trim(), id: inputTag.trim() },
          ...updateTags.tags,
        ]);
        setInputTag("");
      } else {
        toastService.onWarning("tag already exist");
      }
    }
  };

  const moveCard = (dragIndex, hoverIndex) => {
    // Same group - just reorder    
    updateTags.setTags(
      arrayMoveImmutable(updateTags.tags, dragIndex, hoverIndex)
    );
  };

  const getTagData = () => (
    <DragDropContext onDragEnd={onDrag}>
      <Table className="tag-table" responsive striped borderless>
        <Droppable droppableId="droppable-table-id">
          {(provided) => (
            <>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    {t("Modules.Basic information.Room Features - Header")}{" "}
                    <OverlayTrigger
                      key="right"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          <Row>
                            <Col>
                              {t(
                                "Modules.Basic information.Room Features - Tooltip"
                              )}
                            </Col>
                          </Row>
                        </Tooltip>
                      }
                    >
                      <QuestionIcon className="pointer" />
                    </OverlayTrigger>
                  </th>
                  <th className="text-center">{t("Modules.Basic information.Action")}</th>
                </tr>
              </thead>
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {updateTags.tags.map((tagInfo, index) => (
                  <Draggable
                    draggableId={`draggable-${index}`}
                    index={index}
                    key={index}
                  >
                    {(providedTr) => (
                      <tr
                        key={`table-bodies-${index}`}
                        {...providedTr.draggableProps}
                        {...providedTr.dragHandleProps}
                        ref={providedTr.innerRef}
                      >
                        <td>
                          <RoomcatDragDrop />
                        </td>
                        <td key={`tags-${index}`}>
                          <strong>{tagInfo.name}</strong>
                        </td>
                        <td className="text-center delete-icon">
                          {allowAccess ? (
                            <CloseIcon
                              className="pointer"
                              onClick={() => {
                                removeTag(tagInfo.name);
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
              </tbody>
            </>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  );

  return (
    <React.Fragment>
      {allowAccess && (
        <Row>
          <Col md={3} xs={6}>
            <Form.Group>
              <Form.Control
                type="text"
                name="tags"
                onChange={(e) => setInputTag(e.target.value)}
                value={inputTag}
                //placeholder={t("Modules.Basic information.Type your tag")}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md={2} xs={3}>
            <Button variant="primary" onClick={addTagsOnClick}>
              {t("Modules.Basic information.Add room feature")}
            </Button>
          </Col>
          <Col md={2} xs={3} className="pt-1">
            <Link to="/hotel-translation">
              <strong>
                {t("Modules.Basic information.Manage translations")}
              </strong>
            </Link>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12} className="mt-3 noPadding">
          {/* for new tags */}
          <Flipper flipKey="sadasdasdsdas" spring="stiff">
            <div className="files-container">{getTagData()}</div>
          </Flipper>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AddDnDSingleTags;
