import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  MOBILE_SCREENS,
  PRIVATE_ROUTES,
  STORAGE_KEYS,
  TRACKING_TYPES,
} from "../../helper/constants";
import { StorageService } from "../../services/storage.service";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import { ReactComponent as Arrowright } from "./SidebarSvg/Arrowright.svg";
import { ReactComponent as DashboardIcon } from "./SidebarSvg/Dashboard.svg";
import { ReactComponent as HomeIcon } from "./SidebarSvg/Home.svg";
import { ReactComponent as HotelIcon } from "./SidebarSvg/Hotel.svg";
import { ReactComponent as LogoIcon } from "./SidebarSvg/Logo.svg";
import { ReactComponent as QuestionIcon } from "./SidebarSvg/Question.svg";
import { ReactComponent as TrueIcon } from "./SidebarSvg/Subtract.svg";
import { ReactComponent as UsersIcon } from "./SidebarSvg/Users.svg";
import { ReactComponent as VectorIcon } from "./SidebarSvg/Vector.svg";
import { ReactComponent as PrivacyPolicyIcon } from "./SidebarSvg/PrivacyPolicy.svg";
import ModalCopmponents from "./ModalComponent";
import toast from "react-hot-toast";

const Sidebar = (props) => {
  const [showModal, setshowModal] = useState(false);

  const dispatch = useDispatch();

  const locationRef = useLocation();
  const showSidebar = () => {
    props.onChangeSideBar(!props.sidebar);
  };
  const storageService = new StorageService();

  const checkActiveLinks = (sidebarType) => {
    return locationRef.pathname.split("/").includes(sidebarType)
      ? "active"
      : "";
  };
  const dashboard = useSelector(
    (state) => state?.login?.result?.dashboardLinks
  );
  //need to change name
  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };

  const handleSidebarForMobile = () => {
    // eslint-disable-next-line no-restricted-globals
    if (+MOBILE_SCREENS.MAX_WIDTH > +screen.width) {
      showSidebar();
    }
  };
  const urlReturn = () => {
    if (
      dashboard == "undefined" ||
      dashboard == undefined ||
      dashboard == null ||
      dashboard?.length < 1
    ) {
      return "";
    } else {
      return JSON.parse(dashboard)?.[0]?.dashboard_link;
    }
  };
  const redirectToDashboard = () => {
    if (storageService.getItem(STORAGE_KEYS.hotelGroupToken)) {
      if (dashboard) {
        const dashboard_url = urlReturn();
        if (dashboard_url.length > 1) {
          window.open(dashboard_url);
        }
      }
    } else {
      dispatch(actions.clearToken());
    }
  };

  const handleOnPolicyClick = (type) => {
    if (type === 1) {
      dispatch(actions.addTrackingData(TRACKING_TYPES.GENERAL_TERMS_CONDITION));
      return;
    } else if (type === 2) {
      dispatch(
        actions.addTrackingData(TRACKING_TYPES.DATA_PROCESSING_AGREEMENT)
      );
    }
  };

  return (
    <Nav
      activeKey={locationRef.pathname}
      className={`${
        props.sidebar ? "flex-column active-sidebar" : "flex-column"
      } sidebar-scroll`}
    >
      <Link className="sidebar__list-item--logo" to="/home">
        <LogoIcon />
      </Link>
      <div className="sidebar-content">
        {hiddenIconAsPerRole(PRIVATE_ROUTES.Hotel_management) ? (
          <Link
            to={PRIVATE_ROUTES?.Hotel_management}
            className={`${
              props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
            } ${checkActiveLinks("home")}`}
            onClick={handleSidebarForMobile}
          >
            <span className="sidebar-content">
              <HomeIcon />
              {props.sidebar && <span className="sidebar-label">Home </span>}
            </span>
          </Link>
        ) : (
          <></>
        )}
        <Link
          to="/home"
          as={NavLink}
          className={`${
            props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
          }`}
          onClick={(e) => {
            toast.success("Coming soon!");
            e.stopPropagation();
          }}
        >
          <span className="sidebar-content">
            <DashboardIcon />
            {props.sidebar && <span className="sidebar-label">Dashboard </span>}
          </span>
        </Link>

        {hiddenIconAsPerRole(PRIVATE_ROUTES.Home) ? (
          <Link
            to={PRIVATE_ROUTES?.Home}
            className={`${
              props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
            } ${checkActiveLinks("hotel")}`}
            onClick={handleSidebarForMobile}
          >
            <span className="sidebar-content">
              <HotelIcon />
              {props.sidebar && <span className="sidebar-label">Hotels</span>}
            </span>
          </Link>
        ) : (
          <></>
        )}

        {hiddenIconAsPerRole(PRIVATE_ROUTES.user_list) ? (
          <Link
            to={PRIVATE_ROUTES?.user_list}
            className={`${
              props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
            } ${checkActiveLinks("user")}`}
            onClick={handleSidebarForMobile}
          >
            <span className="sidebar-content">
              <UsersIcon />
              {props.sidebar && <span className="sidebar-label">Users</span>}
            </span>
          </Link>
        ) : (
          <></>
        )}

        <Link
          to="/bookings"
          className={`${
            props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
          } ${checkActiveLinks("bookings")}`}
          onClick={handleSidebarForMobile}
        >
          <span className="sidebar-content">
            <TrueIcon />
            {props.sidebar && <span className="sidebar-label">Bookings</span>}
          </span>
        </Link>
        <div className="sidebar__list-item--expand">
          {!props.sidebar ? (
            <div>
              <Nav.Link onClick={showSidebar}>
                <VectorIcon />
              </Nav.Link>
            </div>
          ) : (
            <div>
              <Nav.Link onClick={showSidebar} className="arrow-right">
                <Arrowright />
              </Nav.Link>
            </div>
          )}
        </div>
      </div>
      <div className={"sidebar-content-last"}>
        <div className="question-icon-sidebar">
          <Link
            to="/contact-us"
            className="nav-link"
            onClick={handleSidebarForMobile}
          >
            <span className="sidebar-content">
              <QuestionIcon />
              {props.sidebar && (
                <span className="sidebar-label">Contact Expect Me</span>
              )}
            </span>
          </Link>
        </div>
        <div className="privacy-policy">
          <Link
            className="nav-link"
            onClick={(e) => {
              e.preventDefault();
              setshowModal(!showModal);
            }}
          >
            <span className="sidebar-content">
              <PrivacyPolicyIcon />
              {props.sidebar && <span className="sidebar-label">Policies</span>}
            </span>
          </Link>
          <ModalCopmponents
            onHide={() => {
              setshowModal(!showModal);
            }}
            header={""}
            size="md"
            show={showModal}
            className="policy-modal"
            body={
              <>
                <p>
                  We all know that policies and terms & conditions can be long,
                  difficult and full of legal jargon. However, we do believe it
                  is important to inform you about how to use Expect Me and to
                  explain how we process data.
                </p>
                <p>
                  That is why the following policies were included in your Sales
                  Order. However, policies can change over time and we want to
                  make sure you always have access to the latest version through
                  these links below:{" "}
                </p>
                <a
                  href="https://www.expect-me.com/policies/terms-and-conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleOnPolicyClick(1)}
                >
                  General Terms and Conditions{" "}
                </a>
                <br />
                <a
                  href="https://www.expect-me.com/policies/data-processing-agreement/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleOnPolicyClick(2)}
                >
                  Data Processing Agreement
                </a>
              </>
            }
          ></ModalCopmponents>
        </div>
      </div>
    </Nav>
  );
};

export default Sidebar;
