import { call, put, takeLatest } from "redux-saga/effects";
import HotelManagementService from "../../services/HotelManagementService/index";
import actions from "../actions/actions";

const fetchHotelsFromHotelGroup = async (id, page, limit) => {
  try {
    let HotelManagementServe = new HotelManagementService();
    const response = await HotelManagementServe.getHotelsFromHotelGroup(
      id,
      page,
      limit
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const addTrackingData = async (type) => {
  try {
    let HotelManagementServe = new HotelManagementService();
    return await HotelManagementServe.postTrackingData({
      type,
    });
  } catch (error) {
    throw error;
  }
};

const fetchHotels = async (id, page, limit) => {
  try {
    let HotelManagementServe = new HotelManagementService();
    const response = await HotelManagementServe.getHotels(id);
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchHotelGroupList = async (id, page, limit) => {
  try {
    let HotelManagementServe = new HotelManagementService();
    const response = await HotelManagementServe.getHotelGroupList();
    return response;
  } catch (error) {
    throw error;
  }
};

function* dispatchGetHotelFromHotelGroups(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(
      fetchHotelsFromHotelGroup,
      action.id,
      action.page,
      action.limit
    );
    if (response.data.success) {
      yield put({ type: actions.API_SUCCESS });
      yield put({
        type: actions.FETCH_HOTELS_FROM_HOTELGROUP_SUCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.FETCH_HOTELS_FROM_HOTELGROUP_FAILED,
        msg: response?.data?.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.FETCH_FLOOR_LIST_FAILED, msg: error?.message });
    yield put({
      type: actions.FETCH_HOTELS_FROM_HOTELGROUP_FAILED,
      msg: error.message,
    });
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchGetHotelGroupList(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(fetchHotelGroupList);
    if (response.data.success) {
      yield put({ type: actions.API_SUCCESS });
      yield put({
        type: actions.GET_HOTELGROUP_LIST_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.GET_HOTELGROUP_LIST_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_HOTELGROUP_LIST_FAILED,
      msg: error.message,
    });
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchGetHotel(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(fetchHotels, action.id);
    if (response.data.success) {
      yield put({ type: actions.API_SUCCESS });
      yield put({
        type: actions.GET_HOTELS_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.GET_HOTELS_FAILED,
        msg: response?.data?.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchAddTrackingData(action) {
  try {
    const response = yield call(addTrackingData, action.payload);
    if (response.data.success) {
      yield put({
        type: actions.ADD_TRACKING_DATA_SUCCESS,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.ADD_TRACKING_DATA_FAILED,
        msg: response?.data?.msg,
      });
    }
  } catch (error) {
    throw error;
  }
}

function* dispatchHotelManagementResetState(action) {
  try {
    yield put({ type: actions.RESET_HOTEL_MANAGEMENT_STATE });
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

export function* listenGetHotelByHotelGroups() {
  yield takeLatest(
    actions.GET_HOTELS_FROM_HOTELGROUP,
    dispatchGetHotelFromHotelGroups
  );
}

export function* listenGetHotelGroupList() {
  yield takeLatest(actions.GET_HOTELGROUP_LIST, dispatchGetHotelGroupList);
}

export function* listenGetHotel() {
  yield takeLatest(actions.GET_HOTELS, dispatchGetHotel);
}

export function* listenHotelManagementResetState() {
  yield takeLatest(actions.GET_HOTELS, dispatchHotelManagementResetState);
}

export function* listenAddTrackingData() {
  yield takeLatest(actions.ADD_TRACKING_DATA, dispatchAddTrackingData);
}
