import confetti from "canvas-confetti";
import { useEffect, useRef, useState } from "react";

const Confetti = () => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      // go Buckeyes!
      const colors = ["#2C3B70", "#ffba42"];
      const end = Date.now() + 7 * 1000;

      const r = confetti.create(ref.current, {
        resize: true,
        useWorker: true,
      });
      (function frame() {
        r({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          colors: colors,
        });
        r({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          colors: colors,
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
      const duration = 7 * 1000;
      const animationEnd = Date.now() + duration;
      const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }

      const interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        r(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          })
        );
        r(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 250);
    }
  }, []);

  return (
    <canvas
      ref={ref}
      style={{ height: "100vh", width: "100vw", position: "fixed", top: "1px" }}
    ></canvas>
  );
};

export default Confetti;
