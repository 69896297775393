import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { ReactComponent as PencilSVG } from "../Common/icons/pencil.svg";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import BootstrapTable from "./../Common/BootstrapTable";
import PaginationComponent from "./../Common/PaginationComponent";
import SearchComponents from "./../Common/SearchComponents";
import Spinner from "./../Common/Spinner";
import { StatusButton } from "./../Common/StatusButton";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export const FloorList = (props) => {
  const { t, i18n } = useTranslation();
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setSearchHotelRoomList(""));
    dispatch(actions.setSearchSeason(""));
    dispatch(actions.setSearchDealList(""));
    dispatch(actions.setSearchOfferList(""));
    dispatch(actions.setSearchUserList(""));
  }, []);

  const isLoading = useSelector((state) => state?.network?.isLoading);
  const floorListSelector = useSelector((state) => state?.floor?.result);

  const floorSearchTerm = useSelector((state) => state?.common?.floorSearch);

  const [floorLists, setFloorList] = useState(null);
  const [searchTerm, setSearchTerm] = useState(floorSearchTerm || "");
  const [totalCount, setTotalCount] = useState(null);
  const [tableMapper, setTableMapper] = useState([]);
  const [headerDescription, setHeaderDescription] = useState();
  const [allowAccess, setAllowAccess] = useState(true);
  const langId = useSelector((state) => state.constants.languages);
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );

  //get Page no,SortBy and SortColumn from reducer
  const floorListCommonDetails = useSelector((state) => state?.common);

  const [pageNo, setPageNo] = useState(floorListCommonDetails.floorListPageNo);
  const [sortOrder, setSortOrder] = useState(
    floorListCommonDetails.floorSortBy
  );
  const [sortingColumnName, setSortingColumnName] = useState(
    floorListCommonDetails.floorColumnName
  );

  const sortColumn = useSelector((state) => state?.common.sortForFloor);
  const [sort, setSort] = useState(sortColumn);
  const columnDataMap = {
    FLOOR: "name",
    "FLOOR PLAN": "floor_plan",
    "ROOMS ASSIGNED": "room_assigned",
  };

  const sortColumns = [
    t("Modules.Floor Lists.Floor Name"),
    t("Modules.Floor Lists.Floor Plan"),
    t("Modules.Floor Lists.Room Assigned"),
  ];

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.floor_create));
    getTableInfo(
      floorSearchTerm || "",
      floorListCommonDetails.floorListPageNo
        ? floorListCommonDetails.floorListPageNo
        : pageNo,
      floorListCommonDetails.floorSortColumnName
        ? floorListCommonDetails.floorSortColumnName
        : columnDataMap[sortingColumnName] || "",
      floorListCommonDetails.floorSortBy
        ? floorListCommonDetails.floorSortBy
        : sortOrder
    );
  }, []);

  useEffect(() => {
    if (floorListCommonDetails) {
      //set current page no
      setPageNo(floorListCommonDetails.floorListPageNo);
    }
    if (sortColumn) {
      setSort(sortColumn);
    }
  }, [floorListCommonDetails, sortColumn]);

  useEffect(() => {
    if (floorListSelector) {
      setTotalCount(floorListSelector?.totalCount);
      setFloorList(floorListSelector);
    }
  }, [floorListSelector]);

  useEffect(() => {
    if (floorLists) {
      mapTableInfo();
    }
  }, [floorLists, defaultSelectedLanguage]);

  const onLanguageSelect = (selectedLanguage) => {
    if (selectedLanguage) {
      setDefaultLanguage(selectedLanguage);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mapTableInfo = () => {
    const mapper = floorLists?.records?.map((floorInfo, index) => {
      const tableHeader = {
        id: floorInfo.id,
      };
      tableHeader[`${t("Modules.Floor Lists.Floor Name")}`] =
        Utils.langValueGenerator(
          floorInfo?.h_floor_ts,
          "name",
          defaultSelectedLanguage
        );
      tableHeader[`${t("Modules.Floor Lists.Floor Plan")}`] =
        floorInfo.floor_plan;
      tableHeader[`${t("Modules.Floor Lists.Room Assigned")}`] =
        floorInfo.room_assigned;
      tableHeader[`${t("Modules.Floor Lists.Active")}`] =
        floorInfo.is_active === 1 ? (
          <StatusButton pending={"completed"}></StatusButton>
        ) : (
          <StatusButton pending={"pending"}></StatusButton>
        );
      return tableHeader;
    });
    const tableHeaderDesc = {
      [`${t("Modules.Floor Lists.Status")}`]: t(
        "Modules.Floor Lists.Status - Question mark"
      ),
    };
    setHeaderDescription(tableHeaderDesc);
    setTableMapper(mapper);
  };

  const sortingClick = (event, headerInfo) => {
    const innerText = event?.target?.innerText?.trim();
    setSortingColumnName(innerText);
    try {
      if (!sort && !Object.keys(sort)?.length) {
        setSort({ ...sort, [innerText]: true });
      } else {
        setSort({
          ...sort,
          [innerText]: !sort[innerText],
        });
      }
    } catch (error) {
      setSort({ ...sort, [innerText]: true });
    }
    setSortingColumnName(innerText);
    const sortOrder = sort[innerText] ? "ASC" : "DESC";
    setSortOrder(sortOrder);
    getTableInfo(
      searchTerm,
      pageNo,
      columnDataMap[headerInfo],
      sortOrder
    );
  };

  // redirecting to update floor
  const onEditRow = (floorId) => {
    props.history.push("/hotel-floor/edit/" + floorId);
  };

  const tableExtraActions = [
    {
      icon: <PencilSVG className={"svg-s25"} />,
      onClick: onEditRow,
    },
  ];

  const onSearchEvent = (searchTxt = "") => {
    dispatch(actions.setSearchFloorList(searchTxt));
    setPageNo(1);
    setSearchTerm(searchTxt);
    getTableInfo(
      searchTxt || "",
      1,
      columnDataMap[sortingColumnName] || "",
      sortOrder || ""
    );
  };

  const getTableInfo = (
    searchTerm = "",
    pageNumber = pageNo,
    sortBy = columnDataMap[sortingColumnName] || "",
    sortOrder = "",
    filterType = extractedFloorStatusType
  ) => {
    const payload = {
      hotel_id: hotelId,
    };
    if (searchTerm) {
      payload.searchKey = searchTerm;
    }
    if (sortOrder) {
      payload.sortBy = sortOrder;
    }

    if (sortBy) {
      payload.sort = sortBy;
    }

    if (pageNo) {
      payload.page = pageNumber;
    }

    if (filterType) {
      payload.type = filterType;
    }

    if (defaultSelectedLanguage) {
      payload.language_id = defaultSelectedLanguage;
    }
    dispatch(actions.getFloorList({ ...payload }));
  };

  i18next.on("languageChanged", (selectedLanguage) => {
    const langFiltered = langId.filter(
      (option) => option.language.key === selectedLanguage
    );

    onLanguageSelect(langFiltered[0]?.language?.id);
  });

  const updatePagination = (pageNo) => {
    setPageNo(pageNo);
    getTableInfo(
      searchTerm || "",
      pageNo,
      columnDataMap[sortingColumnName],
      sortOrder
    );
  };

  // listening on language Changed event to update table header
  // TODO: update listing on language change
  // i18n.on("languageChanged", (selectedLang) => {
  //   mapTableInfo();
  // });

  const floorStatusTypes = [
    t("Modules.Floor Lists.All Floors"),
    t("Modules.Floor Lists.Active Floors"),
    t("Modules.Floor Lists.Inactive Floors"),
  ];
  const [selectedFloorStatusType, setSelectedFloorStatusType] = useState(
    floorStatusTypes[0]
  );
  const [extractedFloorStatusType, setExtractedFloorStatusType] = useState("");

  useEffect(() => {  
    const headerInfo = {
      searchKey: "",
      sortBy: "ASC",
      sort: "username",
      page: 1,
    };

    let type = "";
    if (selectedFloorStatusType === floorStatusTypes[1]) {
      type = "active";
    } else if (selectedFloorStatusType === floorStatusTypes[2]) {
      type = "deactive";
    }

    setExtractedFloorStatusType(type);

    getTableInfo(
      searchTerm,
      pageNo,
      columnDataMap[headerInfo],
      sortOrder,
      type
    );
  }, [selectedFloorStatusType]);

  return (
    <React.Fragment>
      <div className="content">
        <Row>
          <Col>
            <Row className="mb-3">
              <Col
                className="mb-md-0 mb-3"
                xs={12}
              >
                <div className="content-box ">
                  <SearchComponents
                    buttonLabel={t("Modules.Floor Lists.Add a new floor plan")}
                    addBtn={allowAccess}
                    search={onSearchEvent}
                    path={PRIVATE_ROUTES.floor_create}
                    commonSearchTerm={floorSearchTerm}
                    dropDownData={floorStatusTypes}
                    selectedDropDownValue={selectedFloorStatusType}
                    onDropDownSelect={(selectedType)=>{
                      setPageNo(1);
                      setSelectedFloorStatusType(selectedType);
                    }}
                  />
                </div>
              </Col>              
            </Row>
            <Row>
              <Col>
                <BootstrapTable
                  sortColumns={sortColumns}
                  currentlySorted={sort}
                  sortingColumnName={sortingColumnName}
                  onSortClick={sortingClick}
                  tableExtraActions={allowAccess ? tableExtraActions : []}
                  tableMapper={tableMapper}
                  isLoading={isLoading}
                  headerDescription={headerDescription}
                />
              </Col>
            </Row>
            {!isLoading ? (
              <>
                <Row>
                  <Col>
                    <PaginationComponent
                      pageNo={pageNo}
                      totalCount={Math.ceil(totalCount / 10)}
                      onChange={(number) => {
                        updatePagination(number);
                      }}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <Spinner />
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
