import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Switch from "react-switch";
import * as yup from "yup";
import { STORAGE_KEYS } from "../../helper/constants";
import { StorageService } from "../../services/storage.service";
import actions from "../../store/actions/actions";
import Spinner from "../Common/Spinner";

const EmailSettings = () => {
  const [formInitialValue, setFormInitialValue] = useState({
    email_send_before_days: "",
    is_ses_enabled: "",
    url_uuid_config: "",
  });
  const formSchema = yup.object().shape({
    email_send_before_days: yup
      .number()
      .min(1)
      .required("Cannot save empty data"),
    is_ses_enabled: yup
      .boolean()
      .default(1)
      .required("This should be selected"),
  });
  const isLoading = useSelector((state) => state?.network?.isLoading);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setSearchHotelRoomList(""));
    dispatch(actions.setSearchFloorList(""));
    dispatch(actions.setSearchSeason(""));
    dispatch(actions.setSearchDealList(""));
    dispatch(actions.setSearchOfferList(""));
    dispatch(actions.setSearchUserList(""));
  }, []);

  const { t } = useTranslation();
  const storageService = new StorageService();
  const hotelId = storageService.getItem(STORAGE_KEYS.hotelId);
  useEffect(() => {
    dispatch(actions.getEmailService(hotelId));
  }, []);

  const emailData = useSelector((state) => state?.extraservices?.emailService);
  const hotelDetails = useSelector((state) => state.hotelDetails?.result);
  useEffect(() => {
    if (emailData) {
      setFormInitialValue({
        ...formInitialValue,
        email_send_before_days: emailData?.data?.number_of_days,
        is_ses_enabled: emailData?.data?.is_ses_enabled,
        url_uuid_config: emailData?.data?.url_uuid_config,
      });
    }
  }, [emailData]);

  const getURLId = () => {
    return formInitialValue?.url_uuid_config
      ? formInitialValue?.url_uuid_config
      : "RESERVATION-ID";
  };

  return !isLoading ? (
    <>
      <div className="m-3 content">
        <Formik
          initialValues={{
            ...formInitialValue,
          }}
          validationSchema={formSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            const payload = {
              hotelId: hotelId,
              noOfDays: values?.email_send_before_days,
              is_ses_enabled: values?.is_ses_enabled,
            };
            dispatch(actions.emailService(payload));
          }}
        >
          {({
            handleChange,
            errors,
            values,
            touched,
            submitForm,
            setFieldValue,
          }) => (
            <Form>
              <div className="m-3">
                <label className="sub-header-text" style={{ fontSize: "13px" }}>
                  {t("Modules.Email settings.Email Service - Question")}
                </label>
                <div>
                  <span className="switch-lable sub-header-text">
                    {t("Modules.Rooms List.Off")}
                  </span>
                  <Switch
                    onChange={(value) => {
                      if (value) {
                        setFieldValue("is_ses_enabled", 1);
                        setFormInitialValue({
                          ...formInitialValue,
                          is_ses_enabled: 1,
                        });
                      } else {
                        setFieldValue("is_ses_enabled", 0);
                        setFormInitialValue({
                          ...formInitialValue,
                          is_ses_enabled: 0,
                        });
                      }
                    }}
                    checked={values?.is_ses_enabled}
                    name="is_ses_enabled"
                  />
                  <span className="switch-lable sub-header-text">
                    {t("Modules.Rooms List.On")}
                  </span>
                </div>

                {values?.is_ses_enabled ? (
                  <React.Fragment>
                    <div
                      className="alert alert-info informationBox sub-header mt-1 mb-1"
                      role="alert"
                    >
                      <h6>
                        {t(
                          "Modules.Email settings.Email settings - Information box"
                        )}
                      </h6>
                    </div>
                    <Form.Group>
                      <Form.Label className={"sub-header-text"}>
                        {t("Modules.Email settings.Sent Booking Email")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="email_send_before_days"
                        onChange={(event) => {
                          handleChange(event);
                          setFormInitialValue({
                            ...formInitialValue,
                            email_send_before_days: event.target.value,
                          });
                        }}
                        value={formInitialValue?.email_send_before_days || ""}
                        min={1}
                        isInvalid={
                          touched?.email_send_before_days &&
                          errors?.email_send_before_days
                        }
                      ></Form.Control>
                    </Form.Group>
                  </React.Fragment>
                ) : (
                  <div
                    className="alert alert-info informationBox sub-header mt-1 mb-1"
                    role="alert"
                  >
                    <h6>
                      {t(
                        "Modules.Email settings.Email Service - Information box"
                      )}
                    </h6>
                  </div>
                )}
                <Form.Label className={"sub-header-text"}>
                  {t("Modules.Email settings.URL to be used for welcome Email")}{" "}
                </Form.Label>

                {hotelDetails?.hotel_uuid && (
                  <Form.Control
                    type="text"
                    disabled={true}
                    value={`https://${
                      process.env.REACT_APP_ENV === "dev"
                        ? "dev-"
                        : process.env.REACT_APP_ENV === "qa"
                        ? "qa-"
                        : ""
                    }app.expect-me.com/welcome/${
                      hotelDetails?.hotel_uuid
                    }~${getURLId()}`}
                  ></Form.Control>
                )}
              </div>
              <div className="m-3">
                <Row>
                  <Col md={2} sm={6} xs={6}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      {t("Modules.Email settings.Save button")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  ) : (
    <Spinner />
  );
};

export default EmailSettings;
