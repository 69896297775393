import { takeLatest, call, put } from "redux-saga/effects";
import AssetConstantsService from "../../services/AssetConstants";
import ConstantsService from "../../services/Constants";
import { ToastService } from "../../services/toast.service";
import actions from "../actions/actions";

const AssetConstantsServices = new AssetConstantsService();
const ConstantsServices = new ConstantsService();
const toast = new ToastService();

const fetchLanguages = async (hotelId) => {
  const response = await AssetConstantsServices.getLanguages(hotelId);
  return response;
};

const fetchConstants = async (fields) => {
  const response = await AssetConstantsServices.getConstantsData(fields);
  return response;
};

const fetchPms = async (hotelId, isForParent = 0) => {
  const response = await AssetConstantsServices.getPms(hotelId, isForParent);
  return response;
};

const fetchHotelRoomCategory = async (hotelId, defaultSelectedLanguage) => {
  const response = await AssetConstantsServices.getHotelRoomCategory(
    hotelId,
    defaultSelectedLanguage
  );
  return response;
};

const fetchHotelFloorAssign = async (hotelId) => {
  const response = await AssetConstantsServices.getHotelFloorAssign(hotelId);
  return response;
};

const fetchCurrencies = async () => {
  const response = await AssetConstantsServices.getCurrencies();
  return response;
};

//
const fetchGetCountries = async () => {
  const response = await ConstantsServices.fetchGetCountries();
  return response;
};

const fetchGetStates = async (country_id) => {
  const response = await ConstantsServices.fetchGetStates(country_id);
  return response;
};

function* dispatchGetLanguages(action) {
  try {
    let response = yield call(fetchLanguages, action.hotelId);
    if (response.data.success) {
      yield put({
        type: actions.FETCH_LANGUAGES_SUCCESS,
        data: response.data.data,
        msg: response.msg,
      });
    } else {
      yield put({
        type: actions.FETCH_LANGUAGES_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_LANGUAGES_FAILED,
      msg: error.message,
    });
  }
}

function* dispatchGetCurrencies(action) {
  try {
    let response = yield call(fetchCurrencies);
    if (response.data.success) {
      yield put({
        type: actions.FETCH_CURRENCIES_SUCCESS,
        data: response.data.data,
        msg: response.msg,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.FETCH_CURRENCIES_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_CURRENCIES_FAILED,
      msg: error.message,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}
function* dispatchGetConstants(action) {
  try {
    let response = yield call(fetchConstants, action.fields);
    if (response.data.success) {
      yield put({
        type: actions.FETCH_CONSTANTS_SUCCESS,
        data: response.data.data,
        msg: response.msg,
      });
    } else {
      yield put({
        type: actions.FETCH_CONSTANTS_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_CONSTANTS_FAILED,
      msg: error.response?.data?.msg,
    });
  }
}

function* dispatchGetPms(action) {
  try {
    let response = yield call(fetchPms, action.hotelId);
    if (response.data.success) {
      yield put({
        type: actions.GET_PMS_HOTEL_SUCCESS,
        data: response.data.data,
        msg: response.msg,
      });
    } else {
      yield put({
        type: actions.GET_PMS_HOTEL_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_PMS_HOTEL_FAILED,
      msg: error.response.data.msg,
    });
  }
}

function* dispatchGetPmsRoomsForParent(action) {
  try {
    let response = yield call(fetchPms, action.hotelId, 1);
    if (response.data.success) {
      yield put({
        type: actions.GET_PMS_ROOMS_FOR_PARENT_SUCCESS,
        data: response.data.data,
        msg: response.msg,
      });
    } else {
      yield put({
        type: actions.GET_PMS_ROOMS_FOR_PARENT_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_PMS_ROOMS_FOR_PARENT_FAILED,
      msg: error.response.data.msg,
    });
  }
}

function* dispatchGetHotelRoomCategory(action) {
  try {
    let response = yield call(
      fetchHotelRoomCategory,
      action.hotelId,
      action.defaultSelectedLanguage
    );
    if (response.data.success) {
      yield put({
        type: actions.GET_HOTEL_ROOM_CATEGORY_SUCCESS,
        data: response.data.data,
        msg: response.msg,
      });
    } else {
      yield put({
        type: actions.GET_HOTEL_ROOM_CATEGORY_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_HOTEL_ROOM_CATEGORY_FAILED,
      msg: error.response.data.msg,
    });
  }
}

function* dispatchGetHotelFloorAssign(action) {
  try {
    let response = yield call(fetchHotelFloorAssign, action.hotelId);
    if (response.data.success) {
      yield put({
        type: actions.GET_HOTEL_FLOOR_ASSIGN_SUCCESS,
        data: response.data.data,
        msg: response.msg,
      });
    } else {
      yield put({
        type: actions.GET_HOTEL_FLOOR_ASSIGN_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_HOTEL_FLOOR_ASSIGN_FAILED,
      msg: error.response.data.msg,
    });
  }
}

//
function* dispatchGetCountries() {
  try {
    let response = yield call(fetchGetCountries);
    if (response.data.success) {
      yield put({
        type: actions.GET_COUNTRY_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.GET_COUNTRY_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_COUNTRY_FAILED,
      msg: error.response.data.msg,
    });
  }
}

function* dispatchGetStates(action) {
  try {
    let response = yield call(fetchGetStates, action.country_id);
    if (response.data.success) {
      yield put({
        type: actions.GET_STATES_SUCCESS,
        data: response.data.data,
        country: action.country_id,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.GET_STATES_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_STATES_FAILED,
      msg: error.response.data.msg,
    });
  }
}

export function* listenGetLanguages() {
  yield takeLatest(actions.GET_LANGUAGES, dispatchGetLanguages);
}

export function* listenGetCurrencies() {
  yield takeLatest(actions.GET_CURRENCIES, dispatchGetCurrencies);
}

export function* listenGetConstants() {
  yield takeLatest(actions.GET_CONSTANTS, dispatchGetConstants);
}

export function* listenGetPms() {
  yield takeLatest(actions.GET_PMS_HOTEL, dispatchGetPms);
}

export function* listenGetPmsRoomsForParent() {
  yield takeLatest(
    actions.GET_PMS_ROOMS_FOR_PARENT,
    dispatchGetPmsRoomsForParent
  );
}

export function* listenGetHotelRoomCategory() {
  yield takeLatest(
    actions.GET_HOTEL_ROOM_CATEGORY,
    dispatchGetHotelRoomCategory
  );
}

export function* listenGetHotelFloorAssign() {
  yield takeLatest(actions.GET_HOTEL_FLOOR_ASSIGN, dispatchGetHotelFloorAssign);
}

//
export function* listenGetCountries() {
  yield takeLatest(actions.GET_COUNTRY, dispatchGetCountries);
}

export function* listenGetStates() {
  yield takeLatest(actions.GET_STATES, dispatchGetStates);
}
