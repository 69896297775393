import jwt_decode from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, useHistory, useLocation } from "react-router-dom";
import BaseLayout from "../components/layout/BaseLayout";
import { STATIC_HOTEL_ID } from "../helper/constants";
import { StorageService } from "./../services/storage.service";
import actions from "./../store/actions/actions";
import { PrivateRoute } from "./PrivateRoutes";
import { PublicRoute } from "./PublicRoutes";

export const Routes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const auth = useSelector((state) => state.login.result);
  const authSucceed = useSelector((state) => state.login.result.token);
  const sessionTokenExist = useSelector(
    (state) => state.login.result.sessionToken
  );
  const redirectToHome = useSelector((state) => state.login.redirectToHome);

  const dispatch = useDispatch();
  const storageService = new StorageService();
  const history = useHistory();
  const recentPath = useRef(history.location.pathname);
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.getToken());
    if (history.location.pathname !== "/login") {
      recentPath.current = history.location.pathname;
    }
    // setting up the hotel id
    storageService.setItem(
      "hotelGroupId",
      +storageService.getItem("hotelGroupId") || +STATIC_HOTEL_ID
    );
  }, []);

  useEffect(() => {
    if (
      (authSucceed && authSucceed.length > 1) ||
      (sessionTokenExist && sessionTokenExist.length > 1)
    ) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [auth, authSucceed, sessionTokenExist]);

  // useEffect(() => {
  //   setIsAuthenticated(sessionTokenExist && sessionTokenExist.length ? true : false);
  // }, [sessionTokenExist]);

  const publicRoutes = () => {
    return <PublicRoute />;
  };

  const privateRoutes = () => {
    if (sessionTokenExist) {
      recentPath.current = "/set-password";
      return <PrivateRoute recentPath={recentPath.current} />;
    } else {
      if (redirectToHome) {
        recentPath.current = "/home";
        return (
          <BaseLayout>
            <PrivateRoute recentPath={recentPath.current} />
          </BaseLayout>
        );
      } else {
        return (
          <BaseLayout>
            <PrivateRoute recentPath={recentPath.current} />
          </BaseLayout>
        );
      }
    }
  };

  const getRoutes = () => {
    return isAuthenticated ? privateRoutes() : publicRoutes();
  };

  return <Switch>{getRoutes()}</Switch>;
};
