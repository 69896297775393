import actions from "../actions/actions";

const initialState = {
  result: false,
  msg: false,
  success: false,
  isLoading: false,
  couponList: false,
  couponById: false,
  isAdd: false,
  isUpdate: false,
  emailService: false,
  types: [],
};

export const CouponReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_COUPON_LIST: {
      return {
        ...state,
        success: true,
        isAdd: false,
        isUpdate: false,
        couponList: false,
      };
    }
    case actions.FETCH_COUPON_LIST_SUCCESS: {
      state.couponList = action?.result;
      return {
        ...state,
        success: true,
        isAdd: false,
        isUpdate: false,
      };
    }
    case actions.FETCH_COUPON_LIST_FAILED: {
      return {
        ...state,
        result: {
          msg: action?.msg,
        },
        success: false,
      };
    }
    case actions.FETCH_COUPON_BY_ID_SUCCESS: {
      state.couponById = action?.result;
      return {
        ...state,
        success: true,
        isAdd: false,
        isUpdate: false,
      };
    }
    case actions.FETCH_COUPON_BY_ID_FAILED: {
      return {
        ...state,
        result: {
          msg: action?.msg,
        },
        success: false,
        isAdd: false,
        isUpdate: false,
      };
    }

    case actions.ADD_COUPON_SUCCESS: {
      return {
        ...state,
        success: true,
        isAdd: true,
      };
    }
    case actions.ADD_COUPON_FAILED: {
      return {
        ...state,
        result: {
          msg: action?.msg,
        },
        success: false,
      };
    }

    case actions.UPDATE_COUPON_SUCCESS: {
      return {
        ...state,
        success: true,
        isUpdate: true,
      };
    }
    case actions.UPDATE_COUPON_FAILED: {
      return {
        ...state,
        result: {
          msg: action?.msg,
        },
        success: false,
      };
    }

    case actions.EMAIL_COUPON_SUCCESS: {
      return {
        ...state,
        msg: action?.msg,
        success: true,
      };
    }

    case actions.EMAIL_COUPON_FAILED: {
      return {
        ...state,
        msg: action?.msg,
        success: false,
      };
    }

    case actions.GET_EMAIL_COUPON_SUCCESS: {
      return {
        ...state,
        emailService: action?.data,
        msg: action?.msg,
        success: true,
      };
    }

    case actions.GET_EMAIL_COUPON_FAILED: {
      return {
        ...state,
        msg: action?.msg,
        success: false,
      };
    }
    case actions.CLEAR_COUPON_BY_ID: {
      return {
        ...state,
        couponById: false,
      };
    }

    case actions.GET_COUPON_TYPES_SUCCESS: {
      return {
        ...state,
        types: action.data,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }

    case actions.GET_COUPON_TYPES_FAILED: {
      return {
        ...state,
        msg: action.msg,
        success: false,
      };
    }
    case actions.SOFT_DELETE_COUPON: {
      return {
        ...state,
        success: false,
        isUpdate: false,
      };
    }
    case actions.SOFT_DELETE_COUPON_SUCCESS: {
      return {
        ...state,
        success: true,
        isUpdate: true,
      };
    }
    case actions.SOFT_DELETE_COUPON_FAILED: {
      return {
        ...state,
        success: false,
        isUpdate: false,
      };
    }
    default: {
      return state;
    }
  }
};
