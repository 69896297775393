import axios from "axios";
import { STORAGE_KEYS } from "../helper/constants";
import { getTokens } from "./auth.service";
const reportInstance = axios.create({
  baseURL: `${process.env.REACT_APP_REPORT_API_URL}`,
  withCredentials: true,
});

// attaching auth header
// reportInstance.interceptors.request.use(function (config) {
//     const authToken = getToken();
//     config.headers.Authorization = 'Bearer '.concat(authToken);
//     return config;
// });

reportInstance.interceptors.request.use(function (config) {
  const authToken = getTokens();
  config.headers["Authorization"] = authToken.AccessToken;
  config.headers["Refresh-Token"] = authToken.RefreshToken;
  return config;
});

reportInstance.interceptors.response.use(
  (response) => {
    if (response.headers) {
      if (response.headers.isaccesstokenrefreshed === "1") {
        reportInstance.setItem(
          STORAGE_KEYS.AccessToken,
          response.headers.accesstoken
        );
      }
    }
    return response;
  },
  (error) => {
    throw error;
  }
);
export default reportInstance;
