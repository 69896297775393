import { FieldArray, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PRIVATE_ROUTES } from "../../helper/constants";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import { CKEditorComponent } from "./../Common/CkEditor";

export const GreetingMessagesTranslation = ({
  data,
  languageInfo,
  languageNames,
  hotelId,
  filter,
  setHasDataChanged,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const formInitialValue = {
    translation: [],
  };
  const [allowAccess, setAllowAccess] = useState(true);

  const { t } = useTranslation();
  const [forHeader, setForHeader] = useState([]);
  const [greetingsEditorData, setGreetingsEditorData] = useState({
    translation: [],
  });

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.deal_create));

    if (data) {
      setForHeader([...languageNames]);
      const filteredData = Utils.filterArrayForGreetings(data, languageInfo);
      setGreetingsEditorData({
        translation: filteredData,
      });
      formikRef.current.setFieldValue("translation", filteredData);
    }
  }, [data]);

  const updateGreetingMessage = (payload) => {
    dispatch(actions.updateTranslation(payload, hotelId, filter));
  };

  return (
    <>
      <div className="row-vertical-scroll">
        <Row className="mb-3">
          {forHeader?.map((headerInfo, index) => (
            <Col key={`header-key-${index}`}>
              <strong>{headerInfo?.header}</strong>
            </Col>
          ))}
        </Row>
        <Formik
          innerRef={formikRef}
          initialValues={{
            ...formInitialValue,
          }}
          onSubmit={(values) => {
            updateGreetingMessage({
              greeting: greetingsEditorData.translation,
            });
          }}
        >
          {({ handleChange, values, submitForm, setFieldValue }) => (
            <>
              <FieldArray name="translation">
                {() => {
                  const translationArray = values.translation;                  
                  return (
                    <>
                      <Row>
                        {translationArray?.map((languageInfo, index) => (
                          <Col key={`getting-msg-key-${index}`}>                            
                            <div className="mt-3">
                              <CKEditorComponent
                                allowAccess={languageInfo.language_id !== 1}
                                className="mt-3"
                                editorState={languageInfo.body}
                                newContent={languageInfo.body}
                                setEditorState={(updateContent) => {
                                  if (
                                    !(index in greetingsEditorData.translation)
                                  ) {
                                    greetingsEditorData.translation[index] = {};
                                  }

                                  greetingsEditorData.translation[index].body =
                                    updateContent;
                                  // setFieldValue(
                                  //   `translation.${index}.body`,
                                  //   updateContent
                                  // );
                                  // updateEditorState(updateContent, setFieldValue);
                                }}
                              />
                            </div>
                            {/* <bootstrapForm.Control
                              key={`translation-label-${index}`}
                              type="text"
                              value={languageInfo.body}
                              name={`translation.${index}.body`}
                              onChange={handleChange}
                              className="mt-3"
                            /> */}
                          </Col>
                        ))}
                      </Row>
                    </>
                  );
                }}
              </FieldArray>
              {allowAccess ? (
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  {t("Common Translations.Save")}
                </Button>
              ) : (
                <></>
              )}
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
