import { call, put, takeLatest } from "redux-saga/effects";
import ExtraCoupon from "../../services/Coupon";
import actions from "../actions/actions";

const couponService = new ExtraCoupon();

const columnMapForReducer = {
  name: "COUPON NAME",
  price: "PRICE",
  start_date: "START DATE",
  end_date: "END DATE",
  active: "STATUS",
};

const getCoupon = async (payload) => {
  try {
    const response = await couponService.getCoupon(payload);
    return response;
  } catch (err) {
    throw err;
  }
};
const getCouponById = async (hotelId, serviceId) => {
  try {
    const response = await couponService.getCouponById(hotelId, serviceId);
    return response;
  } catch (err) {
    throw err;
  }
};

const createCoupon = async (data) => {
  try {
    const response = await couponService.addCoupon(data);
    return response;
  } catch (err) {
    throw err;
  }
};

const updateRoomCategory = async (serviceId, data) => {
  try {
    const response = await couponService.updateCoupon(serviceId, data);
    return response;
  } catch (err) {
    throw err;
  }
};
const fetchSearchCoupon = async (hotelId, searchKey) => {
  try {
    const response = await couponService.getSearchCoupon(hotelId, searchKey);
    return response;
  } catch (err) {
    throw err;
  }
};

const getCouponTypes = async () => {
  try {
    const response = await couponService.getCouponTypes();
    return response;
  } catch (err) {
    throw err;
  }
};

function* dispatchGetCoupons(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(getCoupon, action.payload);
    if (response.data.success) {
      yield put({ type: actions.API_SUCCESS });
      yield put({
        type: actions.FETCH_COUPON_LIST_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
      yield put({
        type: actions.SET_COUPONS_LIST_PAGE_NO,
        page: response?.data?.data.page,
      });
      yield put({
        type: actions.CLEAR_COUPON_BY_ID,
      });
      yield put({
        type: actions.SET_COUPONS_SORT,
        sortBy: action.payload.sortBy ? action.payload.sortBy : "ASC",
        sortName: action.payload.sort,
        sortColumnName: columnMapForReducer[action.payload.sort]
          ? columnMapForReducer[action.payload.sort]
          : "COUPON NAME",
        sort: action.payload.sortBy === "DESC" ? true : false,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.FETCH_COUPON_LIST_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.FETCH_FLOOR_LIST_FAILED, msg: error?.message });
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}
function* dispatchSearchCoupons(action) {
  try {
    const response = yield call(
      fetchSearchCoupon,
      action.hotelId,
      action.searchKey
    );
    if (response.data.success) {
      yield put({
        type: actions.FETCH_COUPON_LIST_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
    } else {
      yield put({
        type: actions.FETCH_COUPON_LIST_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}
function* dispatchCouponById(action) {
  try {
    const response = yield call(
      getCouponById,
      action.hotelId,
      action.serviceId
    );
    yield put({ type: actions.API_FETCHING });
    if (response.data.success) {
      yield put({ type: actions.API_SUCCESS });
      yield put({
        type: actions.FETCH_COUPON_BY_ID_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.FETCH_COUPON_BY_ID_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.FETCH_FLOOR_LIST_FAILED, msg: error?.message });
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchAddCoupon(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(createCoupon, action.data);
    const data = response;
    if (response.data.success) {
      yield put({
        type: actions.ADD_COUPON_SUCCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: data,
      });
    } else {
      yield put({
        type: actions.ADD_COUPON_FAILED,
        msg: response.data,
      });
      yield put({
        type: actions.API_FAILED,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchUpdateCoupon(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(
      updateRoomCategory,
      action.serviceId,
      action.data
    );
    if (response.data.success) {
      yield put({
        type: actions.UPDATE_COUPON_SUCCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
    } else {
      yield put({
        type: actions.UPDATE_COUPON_FAILED,
        msg: response.data,
      });
      yield put({
        type: actions.API_FAILED,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchGetCouponTypes() {
  try {
    let response = yield call(getCouponTypes);
    if (response.data.success) {
      yield put({
        type: actions.GET_COUPON_TYPES_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.GET_COUPON_TYPES_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_COUPON_TYPES_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

const softDeleteCoupon = async (payload) => {
  try {
    const response = await couponService.softDeleteCoupon(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

function* dispatchSoftDeleteCoupon(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(softDeleteCoupon, action.payload);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS, data: response });
      yield put({
        type: actions.SOFT_DELETE_COUPON_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.SOFT_DELETE_COUPON_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.SOFT_DELETE_COUPON_FAILED,
      msg: error?.message,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

export function* listengetCoupons() {
  yield takeLatest(actions.FETCH_COUPON_LIST, dispatchGetCoupons);
}

export function* listenCouponById() {
  yield takeLatest(actions.FETCH_COUPON_BY_ID, dispatchCouponById);
}

//get PMS Room Types
export function* listenaddCoupon() {
  yield takeLatest(actions.ADD_COUPON, dispatchAddCoupon);
}

export function* listeneditCoupon() {
  yield takeLatest(actions.UPDATE_COUPON, dispatchUpdateCoupon);
}

export function* listeneditSearchCoupon() {
  yield takeLatest(actions.SEARCH_COUPON, dispatchSearchCoupons);
}

export function* listenGetCouponType() {
  yield takeLatest(actions.GET_COUPON_TYPES, dispatchGetCouponTypes);
}

export function* fetchSoftDeleteCoupon() {
  yield takeLatest(actions.SOFT_DELETE_COUPON, dispatchSoftDeleteCoupon);
}
