import { FieldArray, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form as bootstrapForm, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PRIVATE_ROUTES, TRANSLATION_TAB } from "../../helper/constants";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import { CKEditorComponent } from "./../Common/CkEditor";
import PaginationComponent from "./../Common/PaginationComponent";

export const RoomsTranslation = ({
  data,
  languageIds,
  languageNames,
  hotelId,
  filter,
  totalCount,
  onPageChange,
  pageNo,
  setHasDataChanged,
  hasDataChanged,
  setUnsavedActionHandler,
}) => {
  const defaultLimit = 10;
  const [forHeader, setForHeader] = useState([
    {
      header: "Room Number",
    },
    {
      header: "Field",
    },
  ]);

  const { t } = useTranslation();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [allowAccess, setAllowAccess] = useState(true);
  const [roomsEditorData, setRoomsEditorData] = useState({
    translation: [],
  });
  const formInitialValue = {
    translation: {},
  };

  const triggerUnsavedChanges = useSelector(
    (state) => state?.translationTab?.triggerUnsavedChanges
  );

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.deal_create));

    if (data) {
      setForHeader([...forHeader, ...languageNames]);
      const finalData = Utils.refactorCategoryTranslationData(data, "rooms_ts");
      const filteredData = Utils.filterCategoryTranslation(
        finalData,
        [...languageIds],
        "Room name",
        "Description",
        TRANSLATION_TAB.ROOMS
      );
      setRoomsEditorData({
        translation: filteredData,
      });
      formikRef.current.setFieldValue("translation", filteredData);
    }
  }, [data]);

  useEffect(() => {
    if (triggerUnsavedChanges) {
      updateCategoryTranslation();
      dispatch(actions.setUnsavedChangesFlag(false));
    }
  }, [triggerUnsavedChanges]);

  const updateCategoryTranslation = () => {
    const formValue = roomsEditorData?.translation;
    const categoryData = formValue;
    categoryData.forEach((categoryInfo) => {
      delete categoryInfo.descriptionLabel;
      delete categoryInfo.nameLabel;
      delete categoryInfo.name;
      categoryInfo.rooms_ts = categoryInfo.fields;
      // delete categoryInfo.fields;
      // categoryInfo.hotelId = hotelId;
    });
    setHasDataChanged(false);
    dispatch(
      actions.updateTranslation(
        { room: categoryData },
        hotelId,
        filter,
        pageNo,
        defaultLimit
      )
    );
  };

  const updatePagination = (pageNo) => {
    onPageChange({ page: +pageNo });
  };

  return (
    <div className="row-vertical-scroll">
      <Row className="mb-3">
        {forHeader?.map((headerInfo, index) => (
          <Col key={`header-${index}`}>
            <strong>{headerInfo?.header}</strong>
          </Col>
        ))}
      </Row>
      <Formik
        innerRef={formikRef}
        initialValues={{ ...formInitialValue }}
        onSubmit={(values) => {
          updateCategoryTranslation();
        }}
      >
        {({ handleChange, values, submitForm, setFieldValue }) => (
          <React.Fragment key={`form`}>
            <FieldArray name="translations">
              {() => {
                const translationArray = values?.translation;
                return (
                  <>
                    {translationArray && translationArray.length ? (
                      <>
                        <Row>
                          {translationArray?.map((categoryInfo, index) => (
                            <React.Fragment key={`translation-${index}`}>
                              <Col>
                                <bootstrapForm.Control
                                  key={`season-label-form-${index}`}
                                  className="mt-3 disable-form"
                                  disabled={true}
                                  value={categoryInfo?.name || "NA"}
                                />
                              </Col>
                              <Col>
                                <div key={`translation-div-1-${index}`}>
                                  <bootstrapForm.Control
                                    key={`translation-label-${index}`}
                                    type="text"
                                    value={categoryInfo.nameLabel}
                                    disabled={true}
                                    className="mt-3"
                                  />
                                </div>
                                <div key={`translation-div-2-${index}`}>
                                  <bootstrapForm.Control
                                    key={`translation-label-${index}`}
                                    type="text"
                                    disabled={true}
                                    value={categoryInfo.descriptionLabel}
                                    className="mt-3"
                                  />
                                </div>
                              </Col>
                              {categoryInfo?.fields.map(
                                (translationValue, tagIndex) => (
                                  <Col
                                    key={`translation-col-${index}-${tagIndex}`}
                                  >
                                    <div
                                      key={`translation-div-${index}-${tagIndex}`}
                                    >
                                      <bootstrapForm.Control
                                        key={`translation-form-${tagIndex}`}
                                        type="text"
                                        disabled={
                                          translationValue?.language_id === 1
                                        }
                                        value={translationValue?.name}
                                        className="mt-3"
                                        name={`translation.${index}.fields.${tagIndex}.name`}
                                        onChange={(event) => {
                                          if (
                                            event.target.value.trim().length > 0
                                          ) {
                                            let originalName =
                                              translationValue.original_name
                                                ? translationValue.original_name
                                                : "";

                                            const isDescriptionUpdated =
                                              originalName.localeCompare(
                                                event.target.value
                                              );
                                            if (isDescriptionUpdated)
                                              setHasDataChanged(true);

                                            roomsEditorData.translation[
                                              index
                                            ].fields[tagIndex].name =
                                              event.target.value;
                                            handleChange(event);
                                          } else if (
                                            event.target.value.trim().length < 1
                                          ) {
                                            roomsEditorData.translation[
                                              index
                                            ].fields[tagIndex].name = "";
                                            setFieldValue(
                                              `translation.${index}.fields.${tagIndex}.name`,
                                              ""
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="mt-3"
                                      key={`translation-div-2-${index}-${tagIndex}`}
                                    >
                                      {/* <bootstrapForm.Control
                                        key={`translation-form-${tagIndex}`}
                                        type="text"
                                        value={translationValue?.description}
                                        className="mt-3"
                                        name={`translation.${index}.fields.${tagIndex}.description`}
                                        onChange={handleChange}
                                      /> */}
                                      <CKEditorComponent
                                        className="mt-3"
                                        newContent={
                                          translationValue.description
                                        }
                                        allowAccess={
                                          translationValue?.language_id !== 1
                                        }
                                        setEditorState={(updateContent) => {
                                          let originalDesc =
                                            translationValue.original_description
                                              ? translationValue.original_description
                                              : ""
                                                  .replaceAll("\n", "\\n")
                                                  .replaceAll("\r", "");
                                          const updatedDes = updateContent
                                            .replaceAll("\n", "\\n")
                                            .replaceAll("\r", "\\r");

                                          if (!originalDesc.endsWith("\\n")) {
                                            originalDesc = originalDesc + "\\n";
                                          }

                                          const isDescriptionUpdated =
                                            originalDesc.localeCompare(
                                              updatedDes
                                            );
                                          if (isDescriptionUpdated)
                                            setHasDataChanged(true);

                                          if (
                                            !(
                                              index in
                                              roomsEditorData.translation
                                            )
                                          ) {
                                            roomsEditorData.translation[index] =
                                              {
                                                fields: [],
                                              };
                                            roomsEditorData.translation[
                                              index
                                            ].fields[tagIndex] = {};
                                          }

                                          roomsEditorData.translation[
                                            index
                                          ].fields[tagIndex].description =
                                            updateContent;
                                        }}
                                      />
                                    </div>
                                  </Col>
                                )
                              )}
                              <span className="mt-2 mb-2">
                                <hr />
                              </span>
                            </React.Fragment>
                          ))}
                          {/* } */}
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              }}
            </FieldArray>
            <Row>
              <Col>
                <PaginationComponent
                  pageNo={+pageNo}
                  totalCount={Math.ceil(totalCount / 10) || 1}
                  onChange={(number) => {
                    updatePagination(number);
                  }}
                />
              </Col>
            </Row>

            {allowAccess ? (
              <Button
                className="mt-3"
                variant="primary"
                onClick={() => {
                  submitForm();
                }}
              >
                {t("Common Translations.Save")}
              </Button>
            ) : (
              <></>
            )}
          </React.Fragment>
        )}
      </Formik>
    </div>
  );
};
