import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form as BootstrapForm, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { StorageService } from "../../../services/storage.service";
import actions from "../../../store/actions/actions";
import expectmelogo from "../icons/expectmelogo.svg";
import Logo from "../SidebarSvg/Logo.svg";

const SetPassword = () => {
  const history = useHistory();
  const storageService = new StorageService();
  const sessionToken = storageService.getItem("session-token");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const email_id = useSelector((state) => state?.login?.emailId);
  const isConfirmApiSuccess = useSelector(
    (state) => state?.login?.isConfirmPassword
  );
  const [availableFilteredLanguages, setAvailableFilteredLanguages] =
    useState(null);
  const isSetPassword = useSelector((state) => state?.login?.isSetPassword);
  const languages = useSelector(
    (state) => state?.hotelLanguageSettings?.languages
  );

  const valueAsKey = false;

  const formInitialValue = {
    password: "",
    confirm_password: "",
  };
  const formSchema = yup.object().shape({
    password: yup.string().required("Required"),
    confirm_password: yup
      .string()
      .required("")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf(
            [yup.ref("password")],
            `${t("Modules.Reset Password.password-verification-message")}`
          ),
      }),
  });
  const setPassword = (payload) => {
    if (email_id && sessionToken) {
      payload["email"] = email_id;
      payload["session"] = sessionToken;
      dispatch(actions.setPassword(payload));
    }
  };
  useEffect(() => {
    if (isConfirmApiSuccess) {
      history.push("/login");
    }
  }, [isConfirmApiSuccess]);

  useEffect(() => {
    if (isSetPassword) {
      history.push("/login");
    }
  }, [isSetPassword]);
  const backToLogin = () => {
    history.push("/login");
  };
  useEffect(() => {
    dispatch(actions.getLanguage());
  }, []);
  useEffect(() => {
    if (languages && languages?.length > 1) {
      setAvailableFilteredLanguages(languages);
    }
  }, [languages]);
  const onLanguageSelect = (languageId) => {
    //TODO:make integrete API for integration
    // const selectedLanguage = availableFilteredLanguages?.find((languageInfo) => {
    //   return +languageInfo?.language?.id === +languageId;
    // });
    //setDefaultLanguage(selectedLanguage?.language_id);
    i18n.changeLanguage(languageId);
  };
  const tempSelect = () => {
    return (
      <div
        className="set-absolute pt-3"
        style={{ position: "left", top: "1px", right: "10px" }}
      >
        <table>
          <tbody>
            <tr>
              <td>
                <div className="form-group">
                  <select
                    required=""
                    class="webkit-auto form-control dropdown"
                    onChange={(e) => {
                      onLanguageSelect(e.target.value);
                    }}
                  >
                    {availableFilteredLanguages?.map((languageInfo, index) => {
                      return (
                        <option key={index} value={+languageInfo?.id}>
                          {valueAsKey
                            ? languageInfo?.key?.toUpperCase()
                            : languageInfo?.code}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          ...formInitialValue,
        }}
        validationSchema={formSchema}
        onSubmit={(values) => {
          const payload = {
            ...values,
          };
          setPassword(payload);
        }}
      >
        {({
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
          submitForm,
        }) => (
          <Form>
            <Row className="noMargin">
              <Col md={6} sm={12} xs={12} className="loginleft">
                <div className="logo">
                  <img src={expectmelogo} />
                </div>
                {tempSelect()}
                <div className="dashborad-login-form">
                  <div>
                    <span className="welcometext">
                      {t("Modules.Set New Password.Set New Password")}
                    </span>
                  </div>
                  <BootstrapForm.Group className="mt-2">
                    <BootstrapForm.Label>
                      {t("Modules.Set New Password.NEW PASSWORD")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="password"
                      name="password"
                      onChange={handleChange}
                      isInvalid={touched?.password && errors?.password}
                      value={values?.password}
                    ></BootstrapForm.Control>
                  </BootstrapForm.Group>
                  <BootstrapForm.Group className="mt-2">
                    <BootstrapForm.Label>
                      {t("Modules.Set New Password.CONFIRM PASSWORD")}
                      <span style={{ color: "red" }}>*</span>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="password"
                      name="confirm_password"
                      onChange={handleChange}
                      isInvalid={
                        touched?.confirm_password && errors?.confirm_password
                      }
                      value={values?.confirm_password}
                    ></BootstrapForm.Control>
                  </BootstrapForm.Group>
                  <span style={{ color: "red" }}>
                    {touched?.confirm_password ? errors?.confirm_password : ""}
                  </span>
                  <Row className="mt-3">
                    <Col md={6} sm={6} xl={6}>
                      <Button variant="primary" type="submit">
                        {t("Modules.Set New Password.Save")}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col className="login-right-panel noPadding">
                <div className="background-login"></div>
                <div className="dashboard-header-login">
                  <img src={Logo} />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default SetPassword;
