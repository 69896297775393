import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import defaultImg from "./icons/defaultImg.svg";

export const DragDropImg = ({ uploadedFile, moveCard, index, setIsSortingChanged }) => {
  const ref = useRef(null);
  const imgType = ["image/jpg", "image/jpeg", "image/png"];
  const [{ handlerId }, drop] = useDrop({
    accept: "img",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards

      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return;
      // }
      // Dragging upwards
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return;
      // }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
      setIsSortingChanged(true);
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "img",
    item: () => {
      return { id: uploadedFile.arrId, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  return (
    <img
      ref={ref}
      data-handler-id={handlerId}
      alt="img"
      className="drag-image m-1 pointer"
      src={
        imgType.includes(uploadedFile?.type) ||
        !String(uploadedFile?.arrId).includes("n_")
          ? uploadedFile.preview
          : defaultImg
      }
      id={uploadedFile.arrId}
    ></img>
  );
};
