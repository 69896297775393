import axios from "../axiosInstance";

class HotelManagementService {
  getHotelsFromHotelGroup = async (id, pageNo, limit) => {
    try {
      const response = await axios.get(
        `/hotel-group/${id}/hotels?page=${pageNo}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  getHotelGroupList = async () => {
    try {
      const response = await axios.get(`/hotel-group/list`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getHotels = async (id) => {
    try {
      const response = await axios.get(`/hotel-group/${id}/hotels/list`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  postTrackingData = async (payload) => {
    try {
      const response = await axios.post(`/hotel-group/track`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default HotelManagementService;
