import React from "react";
import { Col, Row } from "react-bootstrap";
import { RoomPricingList } from "./../RoomSeasonPricing/RoomSeasonPricingList";

export const HotelRoomPricing = () => {
  return (
    <React.Fragment>
      <div className="content mt-3">
        <Row>
          <Col>
            <RoomPricingList />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
