import { call, put, takeLatest } from 'redux-saga/effects';
import RoomCategoriesService from '../../services/RoomCategoriesService/index';
import actions from '../actions/actions';
import { StorageService } from './../../services/storage.service';

const storageService = new StorageService();

const roomsCategoryServe = new RoomCategoriesService();
const getRoomCategories = async (payload) => {
  try {
    const response = await roomsCategoryServe.getRoomCategories(payload)
    return response
  }
  catch (err) {
    throw err;
  }
};

const getPMSRoomTypes = async (hotelId) => {
  try {
    const response = await roomsCategoryServe.getPMSRoomsTypes(hotelId)
    return response
  }
  catch (err) {
    throw err;
  }
};

const createRoomCategories = async (data) => {
  try {
    const response = await roomsCategoryServe.addRoomCategories(data)
    return response
  }
  catch (err) {
    throw err;
  }
}
const getRoomCategoryById = async (categoryId, hotelId) => {
  try {
    const response = await roomsCategoryServe.getRoomCategoryById(categoryId, hotelId)
    return response
  }
  catch (err) {
    throw err;
  }

}
const updateRoomCategory = async (categoryId, data) => {
  try {
    const response = await roomsCategoryServe.updateRoomCategory(categoryId, data)
    return response
  }
  catch (err) {
    throw err;
  }
}
const fetchSortCategories = async (hotelId, data) => {
  try {
    const response = await roomsCategoryServe.sortCategories(hotelId, data)
    return response
  }
  catch (err) {
    throw err;
  }

}

function* dispatchRoomCategories(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(getRoomCategories, action.payload);
    if (response.data.success) {
      yield put({
        type: actions.FETCH_ROOM_CATEGORIES_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
      yield put({
        type: actions.API_SUCCESS,
      
      });
    } else {
      yield put({
        type: actions.FETCH_ROOM_CATEGORIES_FAILED,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_FAILED,
        data: response.data,
      });
    }
  } catch (error) {
   
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}
function* dispatchRoomCategoryById(action) {
  try {
    yield put({type: actions.API_FETCHING})
    const response = yield call(getRoomCategoryById, action.categoryId, action.hotelId);
    if (response.data.success) {
      yield put({
        type: actions.FETCH_ROOM_CATEGORY_BY_ID_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
      yield put({
        type: actions.API_SUCCESS,
        // data: response,
      });
    } else {
      yield put({
        type: actions.FETCH_ROOM_CATEGORY_BY_ID_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}
function* dispatchPMSRoomTypes(action) {
  try {
    const response = yield call(getPMSRoomTypes, action.hotelId);
    if (response.data.success) {
      yield put({
        type: actions.FETCH_PMS_ROOM_TYPES_SUCCESS,
        msg: response.data.msg,
        result: response?.data?.data,
      });
    } else {
      yield put({
        type: actions.FETCH_PMS_ROOM_TYPES_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchAddRoomCategories(action) {
  try {
    yield put({type: actions.API_FETCHING})
    const response = yield call(createRoomCategories, action.data);
    if (response.data.success) {
      yield put({
        type: actions.ADD_ROOM_CATEGORIES_SUCCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
    } else {
      yield put({
        type: actions.ADD_ROOM_CATEGORIES_FAILED,
        msg: response.data,
      });
      yield put({
        type: actions.API_FAILED,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchUpdateRoomCategory(action) {
  try {
    yield put({type: actions.API_FETCHING})
    const response = yield call(updateRoomCategory, action.categoryId, action.data);
    yield put({ type: actions.API_FETCHING });
    if (response.data.success) {
      yield put({
        type: actions.UPDATE_ROOM_CATEGORY_SUCCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });

    } else {
      yield put({
        type: actions.UPDATE_ROOM_CATEGORY_FAILED,
        msg: response.data,
      });
      yield put({
        type: actions.API_FAILED,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

function* dispatchSortCategories(action) {
  try {
    const res = yield call(fetchSortCategories, action.hotelId, action.data);
    if (res.data.success) {
      const response = yield call(getRoomCategories, action.hotelId);
      if (response.data.success) {
        yield put({
          type: actions.FETCH_ROOM_CATEGORIES_SUCCESS,
          msg: response.data.msg,
          result: response?.data?.data,
        });
      } else {
        yield put({
          type: actions.FETCH_ROOM_CATEGORIES_FAILED,
          msg: response.data.msg,
        });
      }
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error.response,
    });
  }
}

const softDeleteRoomCategory = async (payload) => {
  try {
    const response = await roomsCategoryServe.softDeleteRoomCategory(
      payload
    );
    return response;
  } catch (err) {
    throw err;
  }
};

function* dispatchSoftDeleteRoomCategory(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(softDeleteRoomCategory, action.payload);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS, data: response });
      yield put({
        type: actions.SOFT_DELETE_ROOM_CATEGORY_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.SOFT_DELETE_ROOM_CATEGORY_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.SOFT_DELETE_ROOM_CATEGORY_FAILED,
      msg: error?.message,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

//get Room categories
export function* fetchRoomCategories() {
  yield takeLatest(actions.FETCH_ROOM_CATEGORIES, dispatchRoomCategories)
}

//get PMS Room Types
export function* fetchPMSRoomTypes() {
  yield takeLatest(actions.FETCH_PMS_ROOM_TYPES, dispatchPMSRoomTypes)
}

export function* addRoomCategories() {
  yield takeLatest(actions.ADD_ROOM_CATEGORIES, dispatchAddRoomCategories)
}

export function* fetchRoomCategoryById() {
  yield takeLatest(actions.FETCH_ROOM_CATEGORY_BY_ID, dispatchRoomCategoryById)
}

export function* editRoomCategory() {
  yield takeLatest(actions.UPDATE_ROOM_CATEGORY, dispatchUpdateRoomCategory)
}

export function* listensortCategories() {
  yield takeLatest(actions.SORT_CATEGORIES, dispatchSortCategories)
}

export function* fetchSoftDeleteRoomCategory() {
  yield takeLatest(actions.SOFT_DELETE_ROOM_CATEGORY, dispatchSoftDeleteRoomCategory);
}