import actions from "../actions/actions";

const initialState = {
  result: false,
  msg: false,
  success: false,
  triggerUnsavedChanges: false,
};

export const HotelTranslationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_TRANSLATION_TAB_LIST: {
      return {
        ...state,
        result: false,
        success: false,
      };
    }
    case actions.FETCH_TRANSLATION_TAB_LIST_SUCCESS: {
      return {
        ...state,
        result: action?.data?.data,
        msg: action?.result?.msg,
        success: true,
      };
    }
    case actions.FETCH_TRANSLATION_TAB_LIST_FAILED: {
      return {
        ...state,
        success: false,
      };
    }
    case actions.SAVE_TRANSLATION: {
      return {
        ...state,
        result: false,
        success: false,
      };
    }
    case actions.SAVE_TRANSLATION_SUCCESS: {
      return {
        ...state,
        result: action?.data?.data,
        msg: action?.result?.msg,
        success: true,
      };
    }
    case actions.SAVE_TRANSLATION_FAILED: {
      return {
        ...state,
        success: false,
      };
    }
    case actions.SET_UNSAVED_CHANGES_FLAG: {
      return {
        ...state,
        triggerUnsavedChanges: action.payload,
      };
    }
    default:
      return state;
  }
};
