import _NumberFormat from "react-number-format";
import { LANGUAGE, STORAGE_KEYS, TRANSLATION_TAB } from "../helper/constants";
import { StorageService } from "../services/storage.service";
const storageService = new StorageService();

export class Utils {
  // setting up the language response
  static setupLanguageRes = (response) => {
    return response
      .filter((languageInfo) => {
        return languageInfo?.language?.code;
      })
      .map((languageDetail) => {
        languageDetail.language.key =
          LANGUAGE[languageDetail?.language?.code?.toLowerCase()];
        return {
          ...languageDetail,
        };
      });
  };

  static appendData = (keyPairs) => {
    const bodyFormData = new FormData();
    Object.keys(keyPairs).forEach((value, index) => {
      if (value === "images") {
        keyPairs[value].data.forEach((v, i) => {
          bodyFormData.append(keyPairs[value].name, v);
        });
      } else {
        bodyFormData.append(`${value}`, keyPairs[value]);
      }
    });
    return bodyFormData;
  };
  static appendDataServiceAndOffers = (keyPairs) => {
    const bodyFormData = new FormData();
    Object.keys(keyPairs).forEach((value, index) => {
      if (value === "data") {
        bodyFormData.append(`${value}`, JSON.stringify(keyPairs[value]));
      }
      if (value === "image") {
        bodyFormData.append(`${value}`, keyPairs[value][0]);
      }
    });
    return bodyFormData;
  };

  static joinSortingKeys = (sortingData) => {
    return Object.keys(sortingData)
      .map((value, index) => {
        return value;
      })
      .join();
  };

  static refactorTranslationTags = (languages, tagsInfo) => {
    // getting tags which exist
    const availableTags = tagsInfo
      .filter((languageDetails, index) => {
        return languageDetails.tags_ts.length > 0;
      })
      .map((tagsInfo) => {
        return tagsInfo.tags_ts;
      });
    // merging all tags together
    const mergedTags = [].concat.apply([], availableTags);
    const filteredArray = languages.map((languageInfo) => {
      const availableTagByLangId = mergedTags.filter((tags) => {
        return tags.language_id === languageInfo.languageId;
      });

      return {
        language: languageInfo.name,
        languageId: languageInfo.languageId,
        tags: availableTagByLangId,
      };
    });

    const maxTagLengthPerLang = filteredArray.map((tags) => {
      return tags.tags.length || 0;
    });
    const maxTagCount = Math.max.apply(null, [...maxTagLengthPerLang]);
    filteredArray.forEach((filterTagInfo, index) => {
      const blankTags = [];
      if (!(filterTagInfo?.tags && filterTagInfo?.tags.length)) {
        for (let tagIndex = 0; tagIndex < maxTagCount; tagIndex++) {
          const defaultTagId = filteredArray[0].tags.find((tagInfo) => {
            return tagInfo.language_id === languages[0].languageId;
          });
          blankTags.push({
            language_id: filteredArray[index].languageId,
            name: "",
            tag_id: +defaultTagId.tag_id,
            // ?.tags[index]?.tag_id,
          });
        }
        filterTagInfo.tags = blankTags;
      }
    });
    return filteredArray;
  };

  static refactorTranslationReqPayload = (tags) => {
    const totalTags = tags.map((tags) => {
      return tags.tags;
    });
    const mergedTags = [].concat.apply([], totalTags);
    const uniqueTagIds = [
      ...new Set(
        mergedTags.map((tags) => {
          return tags.tag_id;
        })
      ),
    ];
    const filteredResult = [];
    uniqueTagIds.forEach((tagId) => {
      const translations = mergedTags
        .filter((tagInfo) => {
          return tagInfo.tag_id === tagId;
        })
        .map((tagInfo) => {
          return {
            language_id: tagInfo.language_id,
            name: tagInfo.name,
          };
        });

      filteredResult.push({
        tag_id: tagId,
        translations,
      });
    });
    return filteredResult;
  };

  static langValueGenerator = (infoArray, key, langId) => {
    /**
     *@param {infoArray} Object Enter the language array\
     *@param {key} string Enter the Key you want to search ofr in the array
     *@param {langId} number Enter the language id
     **/

    let langFlag = false;
    let value = "";
    if (infoArray) {
      infoArray.forEach((info) => {
        if (!langFlag) {
          if (info.language_id === langId) {
            if (info[key]?.trim().length > 0) {
              value = info[key];
              langFlag = true;
            }
          }
        }
      });

      infoArray.forEach((info) => {
        if (!langFlag) {
          if (info.language_id === 1) {
            value = info[key];
          }
        }
      });
    }
    return value;
  };
  static getDraggedContent = (content) => {
    const reorderData = [];
    content.map((data, i) => reorderData.push({ sort_order: i, id: data.id }));
    return reorderData;
  };

  static filterCategoryTranslation = (
    availableTranslation,
    languageIds,
    nameLabel,
    descriptionLabel,
    translationTab
  ) => {
    const totalCategories = availableTranslation.map((categoryInfo) => {
      return categoryInfo.parentId;
    });
    const uniqueFieldIds = [...new Set(totalCategories)];
    const filteredResult = [];
    uniqueFieldIds.forEach((fieldId, index) => {
      const refactorPayload = {};
      const setupFields = availableTranslation.filter((translationInfo) => {
        return translationInfo.parentId === fieldId;
      });
      refactorPayload.nameLabel = nameLabel;
      refactorPayload.descriptionLabel = descriptionLabel;
      refactorPayload.id = fieldId;

      // adding blank values for not existing fields
      if (setupFields.length !== languageIds.length) {
        const alreadyExistTranslation = setupFields.map((field) => {
          return field?.language_id;
        });
        const blankFields = [];
        languageIds.forEach((languageId) => {
          if (!alreadyExistTranslation.includes(languageId)) {
            blankFields.push({
              // id: fieldId,
              language_id: languageId,
              name: "",
              description: "",
            });
          }
        });
        refactorPayload.fields = [...setupFields, ...blankFields];
      } else {
        refactorPayload.fields = setupFields;
      }
      // sorting via lang ids
      refactorPayload.fields = refactorPayload?.fields.sort(
        (indexV1, indexV2) => {
          return indexV1.language_id - indexV2.language_id;
        }
      );
      // fetching default name for 0th index lang id
      const defaultName = setupFields.find((availableFields) => {
        return availableFields.language_id === +languageIds[0];
      });

      switch (translationTab) {
        case TRANSLATION_TAB.ROOMS: {
          refactorPayload.name = setupFields[0]?.pms_room?.name;
          break;
        }
        default: {
          refactorPayload.name = defaultName?.name;
        }
      }

      filteredResult.push(refactorPayload);
    });
    // sorting via categoryId
    return filteredResult.sort((indexV1, indexV2) => {
      return indexV1.categoryId - indexV2.categoryId;
    });
  };

  static refactorCategoryTranslationData = (existingData, key) => {
    const refactoredData = [];
    existingData.forEach((categoryData) => {
      categoryData[`${key}`].forEach((translation) => {
        const data = {
          ...translation,
          parentId: categoryData.id,
          pms_room: categoryData.pms_room,
        };
        data.original_description = data.description;
        data.original_name = data.name;
        refactoredData.push(data);
      });
    });
    return refactoredData;
  };

  static filterArrayForGreetings = (greetingsArray = [], availableLanguage) => {
    const availableGreetings = [];
    availableLanguage.forEach((languageInfo) => {
      const isExist = greetingsArray?.find((greetingMessage) => {
        return languageInfo?.language_id === greetingMessage?.language_id;
      });

      if (!isExist) {
        availableGreetings.push({
          body: "",
          language_id: languageInfo.language_id,
        });
      } else {
        isExist.body = isExist.body ? isExist.body : "";
        availableGreetings.push(isExist);
      }
    });

    return availableGreetings.sort((indexV1, indexV2) => {
      return indexV1.language_id - indexV2.language_id;
    });
  };

  static filterArrayForSeasons = (seasonArray, availableLanguage) => {
    const filteredResult = [];
    seasonArray.forEach((seasonInfo, index) => {
      const refactorPayload = {};
      refactorPayload.id = seasonInfo.id;
      refactorPayload.seasons_ts = seasonInfo?.seasons_ts.map(
        (seasonTranslation) => {
          // seasonTranslation.seasonId = seasonInfo.id;
          return seasonTranslation;
        }
      );
      if (refactorPayload?.seasons_ts && refactorPayload?.seasons_ts.length) {
        if (refactorPayload?.seasons_ts?.length !== availableLanguage.length) {
          const alreadyExistTranslation = refactorPayload.seasons_ts.map(
            (field) => {
              return field?.language_id;
            }
          );
          const blankFields = [];
          availableLanguage.forEach((languageId) => {
            if (!alreadyExistTranslation.includes(languageId)) {
              blankFields.push({
                // seasonId: seasonInfo.id,
                language_id: languageId,
                name: "",
              });
            }
          });
          refactorPayload.seasons_ts = [
            ...refactorPayload.seasons_ts,
            ...blankFields,
          ];
        }
        refactorPayload.seasons_ts = refactorPayload?.seasons_ts.sort(
          (indexV1, indexV2) => {
            return indexV1.language_id - indexV2.language_id;
          }
        );
        // setting up the default name for raw
        const defaultName = refactorPayload.seasons_ts.find(
          (availableFields) => {
            return availableFields.language_id === +availableLanguage[0];
          }
        );
        refactorPayload.name = defaultName?.name;
        filteredResult.push(refactorPayload);
      }
    });
    return filteredResult;
  };

  static setDragBtnSize = () => {
    const currentSize = window.innerWidth || window.screen.width;
    // TODO: len from const after confirmation
    if (currentSize > 768) {
      return "md";
    } else if (currentSize < 768) {
      return "sm";
    } else if (currentSize < 500) {
      return "xs";
    }
  };

  static getHTMLtoPlainText = (htmlContent) => {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = htmlContent;
    return (
      divContainer.textContent.trim() || divContainer.innerText.trim() || ""
    );
  };

  static getDDMMYY = (customDate) => {
    const date = new Date(customDate)?.toString()?.split(" ");
    const dateNumber = date[2];
    const monthName = date[1];
    const year = date[3];
    return dateNumber && monthName && year
      ? `${dateNumber}  ${monthName} ${year}`
      : "";
  };
  static filterRoles = (pages) => {
    if (pages.length) {
      const filterdRoles = pages.map((pageDetail) => {
        return {
          page: pageDetail.page.page,
          pageURl: pageDetail.page.url,
          isAccessAllowed:
            pageDetail.access && +pageDetail.access === 1 ? true : false,
        };
      });
      return filterdRoles;
    }
  };
  static isAccessAllowedByRoute = (path) => {
    const allowedPages = storageService.getItem(STORAGE_KEYS.allowedPages);
    if (allowedPages) {
      const filteredPages = JSON.parse(allowedPages);
      return (
        filteredPages?.find((pageInfo) => {
          return pageInfo.pageURl === path;
        })?.isAccessAllowed || false
      );
    } else {
      return false;
    }
  };

  static NumberFormat = (
    currencySymbol = "",
    key = new Date(),
    _value,
    className,
    isDisabled,
    isInvalid,
    name,
    handleChange,
    customComponent,
    suffix
  ) => {
    const getValue = () => {
      if (isDisabled && _value === 0) return "";
      return _value;
    };

    return (
      <_NumberFormat
        thousandSeparator={","}
        decimalSeparator={"."}
        prefix={currencySymbol}
        key={key}
        type="text"
        value={getValue()}
        className={className}
        disabled={isDisabled}
        isInvalid={isInvalid}
        name={name}
        onChange={handleChange}
        customInput={customComponent}
        decimalScale={2}
        fixedDecimalScale={true}
        suffix={suffix}
      />
    );
  };

  static NumberFormatText = (currency, value) => {
    return (
      <_NumberFormat
        value={value.toLocaleString("en", {
          useGrouping: false,
          minimumFractionDigits: 2,
        })}
        displayType={"text"}
        thousandSeparator={true}
        prefix={currency}
      />
    );
  };
}
