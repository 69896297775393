import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { PRIVATE_ROUTES } from "../../helper/constants";
import actions from "../../store/actions/actions";
import { ReactComponent as Vectors } from "../Common/icons/vectors.svg";
import ModalCopmponents from "../Common/ModalComponent";
import { Utils } from "./../../utils/utils";
import Breadcrumbs from "./../Common/Breadcrumbs";
import Spinner from "./../Common/Spinner";
import UserBasicInformation from "./UserBasicInformation";
import UserContactInformation from "./UserContactInformation";

const EditUser = (props) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const getUserById = useSelector((state) => state?.user?.userById);
  const userUpdated = useSelector((state) => state?.user?.updated);
  const languages = useSelector(
    (state) => state?.hotelLanguageSettings?.languages
  );
  const [languageList, setlanguageList] = useState([]);
  const [dropdownValue, setDropDownValue] = useState([]);
  const [idCountry, setCountryId] = useState();
  const countries = useSelector((state) => state.constants.countries);
  const states = useSelector((state) => state.constants.states[idCountry]);
  const [selectedLanguage, setselectedLanguage] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [emailId, setEmailId] = useState(null);
  const roles = useSelector((state) => state.authr.roles);
  const [roleList, setRoleList] = useState();
  const [allowAccess, setAllowAccess] = useState(true);
  const [showModal, setshowModal] = useState(false);
  const [countryISO, setCountryISO] = useState();

  const [formInitialValue, setFormInitialValue] = useState({
    first_name: "",
    email: "",
    middle_name: "",
    last_name: "",
    contact_address_street: "",
    contact_address_zipcode: "",
    contact_address_state_id: "",
    contact_address_country_id: "",
    contact_address_city: "",
    phone_number: "",
    phone_number_country: "",
    language_id: "",
    role_id: "",
    hotel_ids: [],
  });
  const formSchema = yup.object().shape({
    first_name: yup.string().trim().required("Please Enter"),
    last_name: yup.string().trim().required("Please Enter"),
    contact_address_street: yup.string().trim().required("Please Enter"),
    contact_address_zipcode: yup.string().trim().required("Please Enter"),
    phone_number: yup.string().trim().required("Please Enter"),
    email: yup.string().trim().required("Please Enter"),
    contact_address_country_id: yup.string().required("Please Enter"),
    language_id: yup.string().required("Please Enter"),
    role_id: yup.string().required("Please Enter"),
    hotel_ids: yup.number().integer().required(" "),
  });
  const breadcrumbs = [
    { path: "/user/list", title: "User management" },
    { path: `/user/create`, title: "Edit user information" },
  ];
  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };
  const updateUser = (payload) => {
    payload["language_id"] = selectedLanguage.value;
    dispatch(actions.UpdateUser(id, payload));
  };
  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.user_update));
    dispatch(actions.getUserById(id));
    dispatch(actions.getCountries());
  }, []);
  useEffect(() => {
    if (userUpdated) {
      history.push("/user/list");
    }
  }, [userUpdated]);
  useEffect(() => {
    if (languages) {
      const langList = languages?.map((lang) => {
        return { value: lang.code, id: lang.id };
      });
      setlanguageList(langList);
    }
  }, [languages]);
  const onCancelClick = () => {
    history.push("/user/list");
  };
  useEffect(() => {
    if (getUserById?.id) {
      setEmailId(getUserById.email);
      const hotel_ids = getUserById?.hotels.reduce((v, i) => {
        v.push(i["hotel_id"]);
        return v;
      }, []);
      
      formikRef.current.setValues({
        first_name: getUserById?.first_name,
        email: getUserById?.email,
        middle_name: getUserById?.middle_name,
        last_name: getUserById?.last_name,
        contact_address_street: getUserById?.contact_address_street,
        contact_address_zipcode: getUserById?.contact_address_zipcode,
        phone_number: getUserById?.phone_number,
        phone_number_country: getUserById?.phone_number_country,
        contact_address_city: getUserById?.contact_address_city,
        language_id: getUserById?.language?.id,
        contact_address_country_id: getUserById?.contact_address_country_id,
        role_id: getUserById?.h_user_roles.role_id,
        hotel_ids: hotel_ids,
      });
      setselectedLanguage({
        label: getUserById?.language?.code,
        value: getUserById?.language?.id,
      });
      setSelectedCountry({
        label: getUserById?.contact_address_country?.name,
        value: getUserById?.contact_address_country?.id,
      });
      setSelectedState({
        label: getUserById?.contact_address_state?.name,
        value: getUserById?.contact_address_state?.id,
      });
      setCountryId(getUserById?.contact_address_country_id);
      const dropDownValue = getUserById?.hotels.reduce((v, i) => {
        v.push({ value: i["hotel_id"], label: i?.hotel?.name });
        return v;
      }, []);
      if (roles) {
        const roleList = roles?.find((role) => {
          return role.id === getUserById?.h_user_roles?.role_id;
        });
        setRoleList({ label: roleList?.name, value: roleList?.id });
      }
      setDropDownValue([...dropDownValue]);
    }
  }, [getUserById, roles]);
  let setcountry = (e, setFieldValue) => {
    if (e) {
      setSelectedCountry(e);
      setCountryId(e.value);
      setFieldValue("contact_address_country_id", e.value);
      setSelectedState(null);
    } else {
      setSelectedCountry(null);
      setSelectedState(null);
      setCountryId(undefined);
      setFieldValue("contact_address_country_id", null);
      setFieldValue("contact_address_city", null);
    }
  };
  useEffect(() => {
    if (idCountry) {
      dispatch(actions.getStates(idCountry));
    }
  }, [idCountry, countries]);

  const updateLanguage = (editorState, setFieldValue) => {
    if (editorState) {
      setselectedLanguage(editorState);
      setFieldValue("language_id", editorState.value);
    } else {
      setselectedLanguage(null);
      setFieldValue("language_id", null);
    }
  };
  const updateState = (editorState, setFieldValue) => {
    if (editorState) {
      setSelectedState(editorState);
      setFieldValue("contact_address_state_id", editorState.value);
    } else {
      setSelectedState(null);
    }
  };
  const deactivateUser = () => {
    if (emailId) {
      const payload = { email: emailId, id: id };
      dispatch(actions.deactivateUser(payload));
    }
  };
  const activateUser = () => {
    if (emailId) {
      const payload = { email: emailId, id: id };
      dispatch(actions.activateUser(payload));
    }
  };
  const updateRoles = (editorState, setFieldValue) => {
    if (editorState) {
      setRoleList(editorState);
      setFieldValue("role_id", editorState.value);
    } else {
      setFieldValue("role_id", "");
      setRoleList(null);
    }
  };

  const modalBodyDeactivate = () => (
    <div className="alignCenter">
      <Row>
        <div className="mt-3 mb-3 alignCenter">
          {t("Modules.User management (edit).Deactivate confirmation")}
        </div>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            variant="primary"
            onClick={() => {
              deactivateUser();
              setshowModal(!showModal);
            }}
          >
            {t("Modules.User management (edit).Deactivate - button")}
          </Button>
        </Col>
        <Col>
          <Button
            variant="secondary"
            onClick={() => {
              setshowModal(!showModal);
            }}
          >
            {t("Common Translations.Cancel")}
          </Button>
        </Col>
      </Row>
    </div>
  );
  const modalBodyActivate = () => (
    <div className="alignCenter">
      <Row>
        <div className="mt-3 mb-3 alignCenter">
          {t("Modules.User management (edit).Activate confirmation")}
        </div>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            variant="primary"
            onClick={() => {
              activateUser();
              setshowModal(!showModal);
            }}
          >
            {t("Modules.User management (edit).Activate - button")}
          </Button>
        </Col>
        <Col>
          <Button
            variant="secondary"
            onClick={() => {
              setshowModal(!showModal);
            }}
          >
            {t("Common Translations.Cancel")}
          </Button>
        </Col>
      </Row>
    </div>
  );
  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row className="marginBottom">
          <Col md={6} sm={6} xs={6} className="extra-content">
            {/* <tr>
                            <td>
                                <Button variant="white" onClick={() => {
                                    props.history.push('/user/list')
                                }}>
                                    <ArrowLeft />
                                </Button>
                            </td>
                            <td className="p-3"> Back </td>
                        </tr> */}
          </Col>
          <Col md={6} sm={6} xs={6} className="alignSelf alignRight">
            <>
              <Dropdown className="customer-dropdown">
                <Dropdown.Toggle size="sm" variant="white">
                  <Vectors />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setshowModal(true);
                    }}
                  >
                    {getUserById?.is_active
                      ? t("Modules.User management (edit).Deactivate User")
                      : t("Modules.User management (edit).Activate User")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
            {/* <Button variant="white" onClick={deactivateUser}>
                            <Vectors />
                        </Button> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>
              {t("Modules.User management (edit).Edit user information")}{" "}
            </h3>
          </Col>
        </Row>
      </div>
      <Formik
        initialValues={{
          ...formInitialValue,
        }}
        validationSchema={formSchema}
        enableReinitialize={false}
        innerRef={formikRef}
        onSubmit={(values) => {
          const payload = {
            ...values,
            language_id: 1,
          };
          updateUser(payload);
        }}
      >
        {({
          handleChange,
          errors,
          values,
          touched,
          submitForm,
          setFieldValue,
        }) => (
          <Form>
            <div className="content">
              {getUserById ? (
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <UserBasicInformation
                          value={selectedLanguage}
                          language={languageList?.length ? languageList : []}
                          change={handleChange}
                          error={errors}
                          values={values}
                          touched={touched}
                          updateform={handleChange}
                          updateDropdown={(updateContent) => {
                            updateLanguage(updateContent, setFieldValue);
                          }}
                          updateRole={(updateContent) => {
                            updateRoles(updateContent, setFieldValue);
                          }}
                          dropdownValue={dropdownValue}
                          setDropDownValue={setDropDownValue}
                          setFieldValue={setFieldValue}
                          selectedRole={roleList}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <UserContactInformation
                          change={handleChange}
                          error={errors}
                          values={values}
                          touched={touched}
                          country={countries ? countries : []}
                          states={states ? states : []}
                          updateCountry={(updateContent) => {
                            setcountry(updateContent, setFieldValue);
                          }}
                          updateState={(updateContent) => {
                            updateState(updateContent, setFieldValue);
                          }}
                          updateform={handleChange}
                          idCountry={idCountry}
                          isedit={true}
                          OldCountryVal={selectedCountry}
                          selectedRole={roleList}
                          OldStateVal={selectedState}
                          setCountryISO={setCountryISO}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={6} xs={7}>
                        {allowAccess ? (
                          <Button
                            variant="primary"
                            onClick={() => {
                              submitForm();
                            }}
                          >
                            {t("Modules.User management (edit).Save button")}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col md={3} sm={6} xs={5}>
                        <Button variant="secondary" onClick={onCancelClick}>
                          {t("Common Translations.Cancel")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Spinner />
              )}
            </div>
          </Form>
        )}
      </Formik>
      <ModalCopmponents
        onHide={() => {
          setshowModal(!showModal);
        }}
        header={""}
        size="md"
        show={showModal}
        body={
          getUserById?.is_active ? modalBodyDeactivate() : modalBodyActivate()
        }
      ></ModalCopmponents>
    </React.Fragment>
  );
};

export default EditUser;
