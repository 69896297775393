import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "./icons/close.svg";

function SoftDeleteAlertModal(props) {
  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <span onClick={props.onClose} className="pointer text-end p-2">
        <CloseIcon />
      </span>
      <Modal.Body>
        <div>
          <p>{props.primaryMessage}</p>
          {props.secondaryMessage && <p>{props.secondaryMessage}</p>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {props.type === "confirmation" && (
          <Button className="alert-modal-btn" onClick={props.onConfirm}>
            {t("Modal Messages.Yes")}
          </Button>
        )}
        <Button className="alert-modal-btn" onClick={props.onClose}>
          {props.closeButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default SoftDeleteAlertModal;
