import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "24937206",
          formId: "391d3354-3afd-478c-b498-ff70d5a06437",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  const ref = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && ref.current.firstElementChild) {
        const style = document.createElement("style");
        style.textContent =
          ".hubspot-link__container.sproket {" + "  display: none;" + "}";

        ref.current.firstElementChild.contentDocument.head.appendChild(style);
      }
    }, 1000);
  }, [ref.current, ref.current?.firstElementChild]);

  return (
    <>
      <div ref={ref} id="hubspotForm"></div>

      {/* <div className="m-2 header">
        {" "}
        {t("Modules.Contact Us.Contact Us content - Title")}
      </div>
      <div className="m-3 content">
        {t("Modules.Contact Us.Contact Us content")}
        <div>
          <Button
            className={"m-2"}
            onClick={() => {
              window.open("mailto:support@expect-me.com");
            }}
          >
            {t("Modules.Contact Us.Contact Us content - Button")}
          </Button>
        </div>
      </div> */}
    </>
  );
};

export default ContactUs;
