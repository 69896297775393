import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { LANGUAGE } from "../../helper/constants";
import { ReactComponent as HamburgerSVG } from "../Common/icons/hamburger.svg";
import LoginDropdown from "../Common/LoginDropdown";
import Sidebar from "../Common/Sidebar";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import { Languages } from "./../Common/languages";
import { PRIVATE_ROUTES } from "../../helper/constants";
import useAuthValidation from "../../hooks/useAuthValidation";

const BaseLayout = ({ children }) => {
  const [sidebar, setSidebar] = useState(false);
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelGroupId");
  const { i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const langId = useSelector((state) => state?.constants?.languages);
  const localLang = useSelector((state) => state?.localLang?.language_id);
  useEffect(() => {
    if (localLang) {
      const langSymbol = langId.filter(
        (lang) => lang?.language_id === localLang
      )[0];

      i18n.changeLanguage(LANGUAGE[langSymbol?.language?.key]);
    }
  }, [localLang, langId]);

  // use custom hook to validate current session
  const isValidSession = useAuthValidation();

  useEffect(() => {
    //! NOTE: Condition added to resolve an edge case that occurs for SET PASSWORD FLOW
    //? when [BaseLayout] is mounted but no tokens are available
    // RESTRICT API calls when tokens are missing
    if (isValidSession) {
      // IF EXISTS then trigger the private routes
      // getting available lang for hotel
      dispatch(actions.getLanguages(0));
      // getting common currencies
      dispatch(actions.getCurrencies());
    }
  }, []);

  const onLanguageSelect = (content) => {
    // updating languages
    i18n.changeLanguage(content?.language?.key);
  };

  const isConfigPage = history.location.pathname === PRIVATE_ROUTES.CONFIG;

  const getHeader = () => {
    return (
      <>
        <Row>
          {!isConfigPage ? (
            <>
              <Col md={6} sm={6} xs={6}>
                <div className="mobile-sidebar set-absolute">
                  {!sidebar && (
                    <div
                      onClick={() => {
                        setSidebar(!sidebar);
                      }}
                    >
                      {" "}
                      <HamburgerSVG />{" "}
                    </div>
                  )}
                </div>
              </Col>
              <Col md={5} sm={5} xs={5} className="language-setup">
                <div className="set-absolute pt-3">
                  <table>
                    <tbody>
                      <tr>
                        {/* will be enabled in for future  */}
                        {/* <td>
                          {" "}
                          <Languages
                            onChange={onLanguageSelect}
                            defaultValue={localLang}
                          />{" "}
                        </td> */}
                        <td>
                          <span>
                            <LoginDropdown />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </>
          ) : (
            ""
          )}
          <Col
            className="noPadding pt-5"
            md={location.pathname === "/" ? 12 : 11}
          >
            {children}
          </Col>
        </Row>
        <br />
        <br />
      </>
    );
  };

  return (
    <Container fluid className="baseLayout-container">
      <Row>
        {!isConfigPage ? (
          <Col md={1} className="sidebar-wraper">
            <Sidebar onChangeSideBar={setSidebar} sidebar={sidebar} />
          </Col>
        ) : (
          ""
        )}

        <Col
          md={11}
          className={`
            ${
              sidebar ? "mainpage__transform" : "mainpage"
            } background-setup mb-3`}
          style={{ left: `${isConfigPage ? "0px" : "88px"}` }}
        >
          {getHeader()}
        </Col>
      </Row>
    </Container>
  );
};
export default BaseLayout;
