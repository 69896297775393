import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form as bootstrapForm,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Switch from "react-switch";
import { ToastService } from "../../services/toast.service";
import * as yup from "yup";
import { PRIVATE_ROUTES, REGEX } from "../../helper/constants";
import actions from "../../store/actions/actions";
import SingleDropDown from "../Common/singleDropDown";
import { StorageService } from "../../services/storage.service";
import { Utils } from "../../utils/utils";
import Spinner from "../Common/Spinner";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
const toast = new ToastService();

export const CouponForm = (props) => {
  //Todo Hotel Id Dynamic
  //const hotelId = 1
  const { t, i18n } = useTranslation();
  const formikRef = useRef();
  const { id } = useParams();
  const [tid, setTid] = useState(null);
  const history = useHistory();
  const hotelDetails = useSelector((state) => state?.hotelDetails?.result);
  const currencySymbol = hotelDetails?.currency?.symbol;
  const dispatch = useDispatch();
  const storageCoupon = new StorageService();
  const hotelId = +storageCoupon.getItem("hotelId");
  const [language, setLanguage] = useState(null);
  const isLoading = useSelector((state) => state?.network?.isLoading);
  const [allowAccess, setAllowAccess] = useState(true);
  const [couponTypes, setCouponType] = useState([]);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const typesAvailable = useSelector((state) => state?.coupons?.types);
  const couponsResult = useSelector((state) => state?.coupons?.result);

  useEffect(() => {
    const CouponType = typesAvailable?.map((type) => {
      return { value: type.value, id: type.id };
    });
    setCouponType(CouponType);
  }, [typesAvailable]);

  useEffect(() => {
    dispatch(actions.getCouponTypes());
  }, []);

  useEffect(() => {
    if (isFormSubmitted && couponsResult?.success) {
      setFormSubmitted(false);
      return history.push("/coupons");
    }
  }, [isFormSubmitted, couponsResult]);

  const [formInitialValue, setFormInitialValue] = useState({
    is_active: true,
    code: "",
    coupon_value: "",
    start_date: "",
    end_date: "",
    has_no_end_date: false,
    coupon_type: "",
    room_selection_same_category: true,
    room_selection_upsell: true,
    deals_and_offers: true,
  });

  const onClickCancelButton = () => {
    history.push("/coupons");
  };

  const formSchema = yup.object().shape({
    is_active: false,
    code: yup.string().trim().min(1).required("Please Enter Special offers"),
    // description: yup.string().required("Please Enter Description"),
    coupon_value: yup
      .number()
      .min(0)
      .required("Please Enter Valid Coupon Price"),
    start_date: yup.date().required("Please Enter Start date"),
    end_date: yup
      .date()
      .min(yup.ref("start_date"))
      .when("has_no_end_date", {
        is: false,
        then: yup.date().required("Must enter email address"),
      }),
    coupon_type: yup.number().required("Please Enter the field"),
    //end_date: yup.date().when("start_date", {is: value => value,then: yup.date().required().min(yup.ref("start_date"))
    // })
  });

  const formik = useFormik({
    initialValues: formInitialValue,
    validationSchema: formSchema,
    innerRef: formikRef,
    enableReinitialize: true,
    onSubmit: (values) => {
      setFormInitialValue(values);
      if (
        !values?.deals_and_offers &&
        !values?.room_selection_same_category &&
        !values?.room_selection_upsell
      ) {
        toast.onWarning("At least one toggle must be selected!");
        return;
      }

      if (!props.isForEdit) {
        const payload = {
          data: {
            ...values,
            code: values.code.trim(),
            hotel_id: hotelId,
            language_id: language,
            coupon_value:
              values.coupon_value && values.coupon_value > 0
                ? values.coupon_value
                : 0,
          },
        };
        if (values.has_no_end_date) {
          payload.data.has_no_end_date = 1;
          payload.data.end_date = null;
        }
        AddCoupon(payload);
        setFormSubmitted(true);
      } else {
        const payload = {
          data: {
            ...values,
            code: values.code.trim(),
            language_id: language,
          },
        };
        if (values.has_no_end_date) {
          payload.data.has_no_end_date = 1;
          payload.data.end_date = null;
        }
        updateCoupon(payload);
        setFormSubmitted(true);
      }
    },
  });

  useEffect(() => {
    if (isTypePercentage(formik?.values?.coupon_type)) {
      if (formik?.values?.coupon_value > 100) {
        formik.setFieldValue("coupon_value", 100);
      }
    }
  }, [formik.values]);

  const AddCoupon = (payload) => {
    dispatch(actions.addCoupon(payload));
  };
  const updateCoupon = (payload) => {
    dispatch(actions.updateCoupon(id, payload));
  };

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.deal_create));

    setLanguage(props.selectedLanguageForUpdate);
    if (props.couponDetails && props.isForEdit) {
      formik.setValues({
        is_active: props.couponDetails.is_active,
        code: props.couponDetails?.code,
        coupon_value: props.couponDetails?.amount,
        start_date: props.couponDetails?.start_date,
        end_date: props.couponDetails?.has_no_end_date
          ? props.couponDetails?.start_date
          : props.couponDetails?.end_date,
        has_no_end_date: props.couponDetails?.has_no_end_date,
        coupon_type: props.couponDetails?.type || null,
        room_selection_same_category:
          props.couponDetails?.coupon_rules?.[0]?.allow_same_category,
        room_selection_upsell:
          props.couponDetails?.coupon_rules?.[0]?.allow_upsell_category,
        deals_and_offers:
          props.couponDetails?.coupon_rules?.[0]?.allow_deals_offer,
        rule_id: props.couponDetails?.coupon_rules?.[0]?.id,
      });
    }
  }, [props.couponDetails, props.selectedLanguageForUpdate]);

  const hasNoEndDate = (values, errors) => {
    if (!values.end_date) {
      formik.setFieldValue("has_no_end_date", false);
    }
    if (errors.end_date) {
      formik.setFieldValue("end_date", values.start_date);
    }
  };

  const handleOnPriceChange = (e, setFieldValue) => {
    const re = REGEX.NEGATIVE_REGEX;
    const value = parseFloat(
      e.target.value?.replace(currencySymbol, "").replaceAll(",", "")
    );

    if (isTypePercentage(formik?.values?.coupon_type)) {
      if (value > 100) {
        setFieldValue("coupon_value", 100);
        return e.preventDefault();
      }
    }

    if (value === "" || value > 0 || re.test(value)) {
      setFieldValue(
        "coupon_value",
        value === "" ? "" : +(value * 1).toString()
      );
    } else {
      setFieldValue("coupon_value", "");
    }
  };

  const isTypePercentage = (coupon_type) => {
    const isPercentage = couponTypes.filter((t) => {
      return t.id === coupon_type && t.value !== "Fixed Amount";
    });
    return isPercentage.length > 0 ? true : false;
  };

  return !isLoading ? (
    <React.Fragment>
      <>
        {!isLoading ? (
          <>
            <Row className="mt-3 sub-header-text" style={{ fontSize: "13px" }}>
              <label style={{ fontSize: "13px" }}>
                {t("Modules.Coupons.Activate coupon?")}
                <OverlayTrigger
                  key="right"
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-right-common">                    
                      {t("Modules.Coupons.Activate coupon - Description")}
                    </Tooltip>
                  }
                >
                  <QuestionIcon className="pointer" />
                </OverlayTrigger>
              </label>
              <div>
                <span className="switch-lable">
                  {t("Modules.Deals and Offers (+ add new deal).Off")}
                </span>
                <Switch
                  onChange={(value) => {
                    formik.setFieldValue("is_active", value);
                  }}
                  checked={formik.values?.is_active ? true : false}
                  name="is_active"
                />
                <span className="switch-lable">
                  {t("Modules.Deals and Offers (+ add new deal).On")}
                </span>
              </div>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <bootstrapForm.Group>
                  <bootstrapForm.Label className={"sub-header-text"}>
                    {t("Modules.Coupons.Coupon Code")}
                    <span style={{ color: "red" }}>*</span>
                  </bootstrapForm.Label>
                  <bootstrapForm.Control
                    type="text"
                    name="code"
                    isInvalid={formik.touched?.code && formik.errors?.code}
                    onChange={formik.handleChange}
                    value={formik.values?.code.trimStart()}
                  ></bootstrapForm.Control>
                </bootstrapForm.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <bootstrapForm.Group>
                  <bootstrapForm.Label className="mt-2 sub-header-text">
                    TYPE
                    <span style={{ color: "red" }}>*</span>
                  </bootstrapForm.Label>
                  <SingleDropDown
                    val={couponTypes || []}
                    inpName={"coupon_type"}
                    updateDropdown={formik.setFieldValue}
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                  ></SingleDropDown>
                </bootstrapForm.Group>
              </Col>
              <Col md={3}>
                <bootstrapForm.Group>
                  <bootstrapForm.Label className="mt-2 sub-header-text">
                    {t("Modules.Coupons.Coupon Value")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </bootstrapForm.Label>
                  {Utils.NumberFormat(
                    !isTypePercentage(formik.values.coupon_type)
                      ? currencySymbol
                      : "",
                    `extra-service-price`,
                    formik.values?.coupon_value,
                    "",
                    false,
                    formik.touched?.coupon_value && formik.errors?.coupon_value,
                    "coupon_value",
                    (e) => {
                      handleOnPriceChange(e, formik.setFieldValue);
                    },
                    bootstrapForm.Control,
                    isTypePercentage(formik.values.coupon_type) ? "%" : ""
                  )}
                </bootstrapForm.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <bootstrapForm.Group>
                  <bootstrapForm.Label className={"sub-header-text"}>
                    {t("Modules.Coupons.Coupon start date")}
                    <span style={{ color: "red" }}>*</span>
                  </bootstrapForm.Label>
                  <bootstrapForm.Control
                    type="date"
                    name="start_date"
                    isInvalid={
                      formik.touched?.start_date && formik.errors?.start_date
                    }
                    onChange={formik.handleChange}
                    value={formik.values?.start_date}
                  ></bootstrapForm.Control>
                </bootstrapForm.Group>
              </Col>
              {!formik.values.has_no_end_date && (
                <Col md={3}>
                  <bootstrapForm.Group>
                    <bootstrapForm.Label className={"sub-header-text"}>
                      {t("Modules.Coupons.Coupon end date")}
                      <span style={{ color: "red" }}>*</span>
                    </bootstrapForm.Label>
                    <bootstrapForm.Control
                      type="date"
                      name="end_date"
                      isInvalid={
                        formik.touched?.end_date && formik.errors?.end_date
                      }
                      onChange={formik.handleChange}
                      value={formik.values?.end_date}
                      min={formik.values?.start_date}
                    ></bootstrapForm.Control>
                  </bootstrapForm.Group>
                </Col>
              )}
            </Row>
            <Row>
              <Col md={9}>
                <bootstrapForm.Group>
                  <bootstrapForm.Check
                    name="has_no_end_date"
                    className={"sub-header-text"}
                    label={t(
                      "Modules.Deals and Offers (+ add new deal).No end date - checkbox"
                    )}
                    onChange={(value) => {
                      formik.setFieldValue(
                        "has_no_end_date",
                        !formik.values.has_no_end_date
                      );
                      if (!formik.values.has_no_end_date) {
                        formik.setFieldValue(
                          "end_date",
                          formik.values.start_date
                        );
                      } else {
                        formik.setFieldValue("end_date", null);
                      }
                    }}
                    value={formik.values?.has_no_end_date}
                    checked={formik.values.has_no_end_date ? true : false}
                  />
                </bootstrapForm.Group>
              </Col>
            </Row>

            <hr className="border-line" />

            <Row className="mt-3 sub-header-text" style={{ fontSize: "13px" }}>
              <label
                className="sub-header"
                style={{
                  fontSize: "24px",
                  color: "#212529",
                }}
              >
                {t("Modules.Coupons.Rules - Title", {
                  defaultValue: "Applicable charges",
                })}
              </label>
              <label
                style={{
                  fontSize: "15px",
                  marginBottom: "1rem",
                  color: "#697089",
                }}
              >
                {t("Modules.Coupons.Rules - Description", {
                  defaultValue:
                    "Allow voucher to be applied to following charge(s):",
                })}
              </label>
              <label style={{ fontSize: "13px" }}>
                {t("Modules.Coupons.Room Selection - Same Category")}
              </label>
              <div>
                <span className="switch-lable">
                  {t("Modules.Coupons.Switch - Off")}
                </span>
                <Switch
                  onChange={(value) => {
                    formik.setFieldValue("room_selection_same_category", value);
                  }}
                  checked={
                    formik.values?.room_selection_same_category ? true : false
                  }
                  name="room_selection_same_category"
                />
                <span className="switch-lable">
                  {t("Modules.Coupons.Switch - On")}
                </span>
              </div>
            </Row>
            <Row className="mt-3 sub-header-text" style={{ fontSize: "13px" }}>
              <label style={{ fontSize: "13px" }}>
                {t("Modules.Coupons.Room Selection - Upsell")}
              </label>
              <div>
                <span className="switch-lable">
                  {t("Modules.Coupons.Switch - Off")}
                </span>
                <Switch
                  onChange={(value) => {
                    formik.setFieldValue("room_selection_upsell", value);
                  }}
                  checked={formik.values?.room_selection_upsell ? true : false}
                  name="room_selection_upsell"
                />
                <span className="switch-lable">
                  {t("Modules.Coupons.Switch - On")}
                </span>
              </div>
            </Row>
            <Row className="mt-3 sub-header-text" style={{ fontSize: "13px" }}>
              <label style={{ fontSize: "13px" }}>
                {t("Modules.Coupons.Deals Offers")}
              </label>
              <div>
                <span className="switch-lable">
                  {t("Modules.Coupons.Switch - Off")}
                </span>
                <Switch
                  onChange={(value) => {
                    formik.setFieldValue("deals_and_offers", value);
                  }}
                  checked={formik.values?.deals_and_offers ? true : false}
                  name="deals_and_offers"
                />
                <span className="switch-lable">
                  {t("Modules.Coupons.Switch - On")}
                </span>
              </div>
            </Row>
            <hr className="border-line" />
            <Row className="mt-3">
              {!props.isForEdit ? (
                <Col md={2} sm={6} xs={6}>
                  {allowAccess ? (
                    <Button
                      variant="primary"
                      disabled={
                        props.selectedLanguageForUpdate !== props.baseLangId
                      }
                      onClick={() => {
                        formik.submitForm();
                      }}
                    >
                      {" "}
                      {t("Modules.Coupons.Add new coupon")}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
              ) : (
                <Col md={2} sm={6} xs={6}>
                  {allowAccess ? (
                    <Button
                      variant="primary"
                      disabled={
                        props.selectedLanguageForUpdate !== props.baseLangId
                      }
                      onClick={() => {
                        hasNoEndDate(formik.values, formik.errors);
                        formik.submitForm();
                      }}
                    >
                      {t("Modules.Deals and Offers (edit offer).Save button")}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
              )}
              <Col md={2} sm={6} xs={6}>
                <Button variant="secondary" onClick={onClickCancelButton}>
                  {t("Modules.Deals and Offers (+ add new deal).Cancel button")}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <Spinner />
        )}
      </>
    </React.Fragment>
  ) : (
    <Spinner />
  );
};
