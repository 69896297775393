import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../Common/icons/close.svg";
import { Dropdown } from "react-bootstrap";

const SearchComponents = (props) => {
  const [searchValue, setSearchValue] = useState(props?.commonSearchTerm || "");
  const [isEnterPressed, setEnterPressed] = useState(false);

  const setSearch = (filterValue = searchValue) => {
    props.search(filterValue);
  };

  return (
    <Row>
      <Col md={5} sm={8} xs={12}>
        <Form.Group>
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchValue}
              onKeyUp={(event) => {
                if (+event?.keyCode === 13) {
                  event.preventDefault();
                  setEnterPressed(true);
                  setSearch();
                }
              }}
              onChange={(event) => {
                event.preventDefault();
                setSearchValue(event?.target?.value);
                if (!event?.target?.value && isEnterPressed) {
                  setEnterPressed(false);
                  setSearch("");
                }
              }}
            ></Form.Control>
            {searchValue && (
              <InputGroup.Text
                className="pointer inputGroupClose"
                onClick={() => {
                  if (isEnterPressed) {
                    setEnterPressed(false);
                    setSearchValue("");
                    setSearch("");
                  } else {
                    setSearchValue("");
                    setSearch("");
                  }
                }}
              >
                <CloseIcon />
              </InputGroup.Text>
            )}
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={7}>
        <div className="right-content d-flex align-items-center justify-content-end">
          <div className="me-2 d-flex align-items-center">
            {
            props.dropDownData?.length > 0 && (
              <>
                <span className="switch-lable sub-header-text">
                  {
                    props.selectedDropDownValue
                  }
                </span>

                <Dropdown className="customer-dropdown">
                  <Dropdown.Toggle size="sm" variant="white"></Dropdown.Toggle>
                  <Dropdown.Menu className={`dropdownAlignStart ${props.className ?? ""}`}>
                    {props?.dropDownData.map((type) => (
                      <Dropdown.Item
                        key={type}
                        onClick={() => {props.onDropDownSelect(type)}}
                      >
                        {type}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>             
              </>
            )}
          </div>

          <Form.Group>
            {props.addBtn ? (
              <Link to={props.path}>
                <Button>{props.buttonLabel}</Button>
              </Link>
            ) : (
              ""
            )}
          </Form.Group>
        </div>
      </Col>
    </Row>
  );
};
export default SearchComponents;
