export const FETCH_FLOOR_LIST = "FETCH_FLOOR_LIST";
export const FETCH_FLOOR_LIST_SUCCESS = "FETCH_FLOOR_LIST_SUCCESS";
export const FETCH_FLOOR_LIST_FAILED = "FETCH_FLOOR_LIST_FAILED";

export const ADD_NEW_FLOOR = "ADD_NEW_FLOOR";
export const ADD_NEW_FLOOR_SUCCESS = "ADD_NEW_FLOOR_SUCCESS";
export const ADD_NEW_FLOOR_FAILED = "ADD_NEW_FLOOR_FAILED";

export const EDIT_FLOOR = "EDIT_FLOOR";
export const EDIT_FLOOR_SUCCESS = "EDIT_FLOOR_SUCCESS";
export const EDIT_FLOOR_FAILED = "EDIT_FLOOR_FAILED";

export const GET_FLOOR_DETAIL = "GET_FLOOR_DETAIL";
export const GET_FLOOR_DETAIL_SUCCESS = "GET_FLOOR_DETAIL_SUCCESS";
export const GET_FLOOR_DETAIL_FAILED = "GET_FLOOR_DETAIL_FAILED";

export const ADD_ASSIGN_ROOM = "ADD_ASSIGN_ROOM";
export const ADD_ASSIGN_ROOM_SUCCESS = "ADD_ASSIGN_ROOM_SUCCESS";
export const ADD_ASSIGN_ROOM_FAILED = "ADD_ASSIGN_ROOM_FAILED";

export const FETCH_FLOOR_NAME_LIST = "FETCH_FLOOR_NAME_LIST";
export const FLOOR_NAME_LIST_SUCCESS = "FLOOR_NAME_LIST_SUCCESS";
export const FLOOR_NAME_LIST_FAILED = "FLOOR_NAME_LIST_FAILED";

export const EDIT_FLOOR_ASSIGNMENT = "EDIT_FLOOR_ASSIGNMENT";
export const EDIT_FLOOR_ASSIGNMENT_SUCCESS = "EDIT_FLOOR_ASSIGNMENT_SUCCESS";
export const EDIT_FLOOR_ASSIGNMENT_FAILED = "EDIT_FLOOR_ASSIGNMENT_FAILED";
export const SET_FLOOR_SORT = "SET_FLOOR_SORT"
export const SET_FLOOR_LIST_PAGE_NO = "SET_FLOOR_LIST_PAGE_NO"
export const CLEAR_FLOOR_STATE = "CLEAR_FLOOR_STATE"

export const SET_SEARCH_FLOOR_LIST  = "SET_SEARCH_FLOOR_LIST"

export const SOFT_DELETE_FLOOR = "SOFT_DELETE_FLOOR";
export const SOFT_DELETE_FLOOR_SUCCESS = "SOFT_DELETE_FLOOR_SUCCESS";
export const SOFT_DELETE_FLOOR_FAILED = "SOFT_DELETE_FLOOR_FAILED";

export const getFloorList = (payload) => {
    return {
        type: FETCH_FLOOR_LIST,
        payload
    }
}

export const addNewFloor = (payload) => {
    return {
        type: ADD_NEW_FLOOR,
        payload
    }
}

export const getFloorDetail = (floorId) => {
    return {
        type: GET_FLOOR_DETAIL,
        floorId
    }
}

export const editFloor = (payload) => {
    return {
        type: EDIT_FLOOR,
        payload
    }
}


export const addAssignRoom = (floorId,payload) => {
    return {
        type: ADD_ASSIGN_ROOM,
        floorId,
        payload
    }
}

export const getFloorNameList = (hotelId) => {
    return {
        type: FETCH_FLOOR_NAME_LIST,
        hotelId
    }
}

export const editFloorAssignment = (payload) => {
    return {
        type: EDIT_FLOOR_ASSIGNMENT,
        payload
    }
}

export const setSearchFloorList = (payload)=>{
    return{
        type: SET_SEARCH_FLOOR_LIST,
        payload
    }
}

export const softDeleteFloor = (payload) => {
    return {
      type: SOFT_DELETE_FLOOR,
      payload,
    };
  };