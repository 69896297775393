import { call, put, takeLatest } from "redux-saga/effects";
import actions from "../actions/actions";
import HotelFloorService from "./../../services/Hotel/hotelFloors";

const hotelFloorService = new HotelFloorService();

const columnMapForReducer = {
  "name":"FLOOR",
  "floor_plan":"FLOOR PLAN",
  "room_assigned":"ROOMS ASSIGNED",
  "active":"STATUS"
}

const getFloors = async (payload) => {
  try {
    const response = await hotelFloorService.getFloors(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const getFloorDetail = async (floorId) => {
  try {
    const response = await hotelFloorService.getFloorDetail(floorId);
    return response;
  } catch (err) {
    throw err;
  }
};

const addFloor = async (payload) => {
  try {
    const response = await hotelFloorService.createHotelFloor(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const editFloor = async (payload) => {
  try {
    const response = await hotelFloorService.editHotelFloor(payload);
    return response;
  } catch (err) {
    throw err;
  }
};
const addAssignRoom = async (floorId, payload) => {
  try {
    const response = await hotelFloorService.assignRoom(floorId, payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const getFloorNameList = async (hotelId) => {
  try {
    const response = await hotelFloorService.getFloorName(hotelId);
    return response;
  } catch (err) {
    throw err;
  }
};

//todo
const editFloorAssignment = async (payload) => {
  try {
    const response = await hotelFloorService.updateHotelFloorAssignment(
      payload
    );
    return response;
  } catch (err) {
    throw err;
  }
};

function* dispatchHotelFloors(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(getFloors, action.payload);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS });
      yield put({
        type: actions.FETCH_FLOOR_LIST_SUCCESS,
        data: response.data,
      });
      yield put({
        type: actions.SET_FLOOR_LIST_PAGE_NO,
        page: response?.data?.data.page,
      });
      yield put({
        type: actions.SET_FLOOR_SORT,
        sortBy: action.payload.sortBy?action.payload.sortBy:"ASC",
        sortName: action.payload.sort,
        sortColumnName: columnMapForReducer[action.payload.sort] ? columnMapForReducer[action.payload.sort] : "FLOOR",
        sort: action.payload.sortBy === "DESC" ? true : false
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.FETCH_FLOOR_LIST_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.FETCH_FLOOR_LIST_FAILED, msg: error?.message });
    yield put({
      type: actions.API_FAILED,
      data: error || error?.response,
    });
  }
}

function* dispatchHotelFloorNameList(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(getFloorNameList, action.hotelId);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS });
      yield put({
        type: actions.FLOOR_NAME_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.FLOOR_NAME_LIST_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.FETCH_FLOOR_LIST_FAILED, msg: error?.message });
    yield put({
      type: actions.API_FAILED,
      data: error || error?.response,
    });
  }
}

function* dispatchAddHotelFloor(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(addFloor, action.payload);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS, data: response });
      yield put({ type: actions.ADD_NEW_FLOOR_SUCCESS, data: response.data });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.ADD_NEW_FLOOR_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.ADD_NEW_FLOOR_FAILED, msg: error?.message });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

function* dispatchEditHotelFloor(action) {
  try {
    
    yield put({ type: actions.API_FETCHING });
    const response = yield call(editFloor, action.payload);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS, data: response });
      yield put({ type: actions.EDIT_FLOOR_SUCCESS });
      
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.EDIT_FLOOR_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.EDIT_FLOOR_FAILED, msg: error?.message });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

function* dispatchGetHotelFloorDetail(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(getFloorDetail, action.floorId);
    if (response?.data?.success) {
      yield put({
        type: actions.GET_FLOOR_DETAIL_SUCCESS,
        data: response.data,
      });
      yield put({ type: actions.API_SUCCESS });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.GET_FLOOR_DETAIL_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.GET_FLOOR_DETAIL_FAILED, msg: error?.message });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

function* dispatchAddAssignRoom(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(addAssignRoom, action.floorId, action.payload);
    const floorID = action.payload.floor_id;
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS, data: response });
      yield put({ type: actions.ADD_ASSIGN_ROOM_SUCCESS, data: response.data });
      // yield call(dispatchGetHotelFloorDetail, {floorId: floorID});
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.ADD_ASSIGN_ROOM_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.ADD_ASSIGN_ROOM_FAILED, msg: error?.message });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}
//todo
function* dispatchEditFloorAssignment(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(editFloorAssignment, action.payload);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS, data: response });
      yield put({
        type: actions.GET_FLOOR_DETAIL_SUCCESS,
        data: response.data,
      });
      //yield call(dispatchGetHotelFloorDetail, { floorId: floorID  });
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.EDIT_FLOOR_ASSIGNMENT_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.EDIT_FLOOR_ASSIGNMENT_FAILED,
      msg: error?.message,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

const softDeleteFloor = async (payload) => {
  try {
    const response = await hotelFloorService.softDeleteFloor(
      payload
    );
    return response;
  } catch (err) {
    throw err;
  }
};

function* dispatchSoftDeleteFloor(action) {
  try {
    yield put({ type: actions.API_FETCHING });
    const response = yield call(softDeleteFloor, action.payload);
    if (response?.data?.success) {
      yield put({ type: actions.API_SUCCESS, data: response });
      yield put({
        type: actions.SOFT_DELETE_FLOOR_SUCCESS,
        data: response.data,
      });      
    } else {
      yield put({ type: actions.API_WARNING, msg: response.data });
      yield put({
        type: actions.SOFT_DELETE_FLOOR_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.SOFT_DELETE_FLOOR_FAILED,
      msg: error?.message,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

export function* fetchHotelFloors() {
  yield takeLatest(actions.FETCH_FLOOR_LIST, dispatchHotelFloors);
}
export function* fetchHotelFloorNameList() {
  yield takeLatest(actions.FETCH_FLOOR_NAME_LIST, dispatchHotelFloorNameList);
}

export function* fetchAddHotelFloor() {
  yield takeLatest(actions.ADD_NEW_FLOOR, dispatchAddHotelFloor);
}

export function* fetchEditHotelFloor() {
  yield takeLatest(actions.EDIT_FLOOR, dispatchEditHotelFloor);
}

export function* fetchGetHotelFloorDetails() {
  yield takeLatest(actions.GET_FLOOR_DETAIL, dispatchGetHotelFloorDetail);
}

export function* fetchAddAssignRoom() {
  yield takeLatest(actions.ADD_ASSIGN_ROOM, dispatchAddAssignRoom);
}

//todo
export function* fetchEditFloorAssignment() {
  yield takeLatest(actions.EDIT_FLOOR_ASSIGNMENT, dispatchEditFloorAssignment);
}

export function* fetchSoftDeleteFloor() {
  yield takeLatest(actions.SOFT_DELETE_FLOOR, dispatchSoftDeleteFloor);
}