import actions from "../actions/actions";

const initialState = {
  hotels: [],
  hotelGroupList: [],
  page: 1,
  limit: 10,
  result: false,
  totalCount: 0,
  constants: {},
  languages: [],
  result: {},
  hotelslist:false
};

export const hotelmanagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_HOTELS_FROM_HOTELGROUP_SUCESS: {
      return {
        ...state,
        hotels: action.data.records,
        page: action.data.page,
        limit: action.data.limit,
        totalCount: action.data.totalCount,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.FETCH_HOTELS_FROM_HOTELGROUP_FAILED: {
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }

    case actions.GET_HOTELGROUP_LIST_SUCCESS: {
      return {
        ...state,
        hotelGroupList: action?.data?.data?.map((_) => {
          return { value: _.id, label: _.value };
        }),
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }

    case actions.GET_HOTELS_SUCCESS: {
      state.hotelslist = action.data;
      return {
        ...state,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.GET_HOTELS_FAILED: {
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }

    case actions.RESET_HOTEL_MANAGEMENT_STATE: {
      return initialState
    }
    
    default: {
      return state;
    }
  }
};
