import axios from "../couponsInstance";
import { Utils } from "../../utils/utils";

class Coupons {
  getCoupon = async (payload) => {
    try {
      const params = {
        ...payload,
      };
      const response = await axios.get(`coupons`, { params });
      return response;
    } catch (error) {
      throw error;
    }
  };
  getSearchCoupon = async (hotelId, searchKey) => {
    try {
      const response = await axios.get(
        `/coupons?hotel_id=${hotelId}&searchKey=${searchKey}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  addCoupon = async (payload) => {
    try {
      const response = await axios.post(`/coupon`, payload.data);
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getCouponById = async (hotelId, couponId) => {
    try {
      const response = await axios.get(
        `/coupon/${couponId}?hotel_id=${hotelId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  updateCoupon = async (categoryId, payload) => {
    try {
      const response = await axios.put(`/coupon/${categoryId}`, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getCouponTypes = async () => {
    try {
      const response = await axios.get(`/coupon/list/types`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  softDeleteCoupon = async (payload) => {
    try {
      const api = `/coupon/` + payload.id + `/status`;
      const response = await axios.patch(api);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default Coupons;
