export const CREATE_HOTEL_ROOOM = "CREATE_HOTEL_ROOOM";
export const CREATE_HOTEL_ROOOM_SUCCESS = "CREATE_HOTEL_ROOOM_SUCCESS";
export const CREATE_HOTEL_ROOOM_FAILED = "CREATE_HOTEL_ROOOM_FAILED";

export const GET_ROOM_DETAIL = "GET_ROOM_DETAIL";
export const GET_ROOM_DETAIL_SUCCESS = "GET_ROOM_DETAIL_SUCCESS";
export const GET_ROOM_DETAIL_FAILED = "GET_ROOM_DETAIL_FAILED";

export const GET_ROOM_LIST = "GET_ROOM_LIST";
export const GET_ROOM_LIST_SUCCESS = "GET_ROOM_LIST_SUCCESS";
export const GET_ROOM_LIST_FAILED = "GET_ROOM_LIST_FAILED";

export const UPDATE_HOTEL_ROOOM = "UPDATE_HOTEL_ROOOM";
export const UPDATE_HOTEL_ROOOM_SUCCESS = "UPDATE_HOTEL_ROOOM_SUCCESS";
export const UPDATE_HOTEL_ROOOM_FAILED = "UPDATE_HOTEL_ROOOM_FAILED";

export const RESET_HOTEL_MANAGEMENT_STATE = "RESET_HOTEL_MANAGEMENT_STATE";

//action for to store page no in reducer state
export const SET_PAGE_NO = "SET_PAGE_NO";
export const SET_SORT_BY = "SET_SORT_BY";
export const RESET_SORT_BY = "RESET_SORT_BY";
export const SET_ROOM_LIST_SORT = "SET_ROOM_LIST_SORT";
export const SET_ROOM_LIST_PAGE_NO = "SET_ROOM_LIST_PAGE_NO";
export const CLEAR_ROOM_LIST_STATE = "CLEAR_ROOM_LIST_STATE";

export const SET_SEARCH_HOTEL_ROOM_LIST = "SET_SEARCH_HOTEL_ROOM_LIST";

export const SOFT_DELETE_ROOM = "SOFT_DELETE_ROOM";
export const SOFT_DELETE_ROOM_SUCCESS = "SOFT_DELETE_ROOM_SUCCESS";
export const SOFT_DELETE_ROOM_FAILED = "SOFT_DELETE_ROOM_FAILED";

export const createRoom = (data) => {
  return {
    type: CREATE_HOTEL_ROOOM,
    data,
  };
};

export const getRoom = (room_id, defaultSelectedLanguage) => {
  return {
    type: GET_ROOM_DETAIL,
    room_id,
    defaultSelectedLanguage,
  };
};

export const getRoomList = (
  pageNo,
  limit,
  hotel_id,
  searchKey = "",
  sortBy = "",
  sortColumnName = "",
  floorId = 0,
  defaultSelectedLanguage = null,
  roomType = ""
) => {
  return {
    type: GET_ROOM_LIST,
    pageNo,
    limit,
    hotel_id,
    searchKey,
    sortBy,
    sortColumnName,
    floorId,
    defaultSelectedLanguage,
    roomType,
  };
};

export const updateRoom = (data, id) => {
  return {
    type: UPDATE_HOTEL_ROOOM,
    data,
    id,
  };
};

export const resetState = () => {
  return {
    type: RESET_HOTEL_MANAGEMENT_STATE,
  };
};

export const setSearchHotelRoomList = (payload)=>{
    return{
        type: SET_SEARCH_HOTEL_ROOM_LIST,
        payload
    }
}

export const softDeleteRoom = (payload) => {
  return {
    type: SOFT_DELETE_ROOM,
    payload,
  };
};
