import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Breadcrumbs from "../Common/Breadcrumbs";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import dealAdd from "../Common/overlayImages/dealAdd.jpg";
import { Utils } from "../../utils/utils";
import { CouponForm } from "./CouponForm";
import couponDesc from "../Common/overlayImages/couponDesc.png";

export const AddCoupon = () => {
  const { t, i18n } = useTranslation();
  const hotelName = useSelector((state) => state?.hotelDetails?.result?.name);

  const langId = useSelector((state) => state.constants.languages);
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );

  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotels" },
    { path: "/coupons", title: hotelName },
    { path: "/coupons", title: " Vouchers" },
    { path: "/coupon/create", title: " Add new coupon" },
  ];
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );

  useEffect(() => {
    if (i18n?.language) {
      const langInfo = langId.find(
        (option) => option.language.key === i18n?.language
      );
      if (langInfo) {
        setDefaultLanguage(langInfo?.language?.id);
        onLanguageSelect(langInfo?.language?.id);
      } else {
        setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
        onLanguageSelect(availableFilteredLanguages[0]?.language?.id);
      }
    } else {
      // setting up initially 0th index as lang id
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
      onLanguageSelect(availableFilteredLanguages[0]?.language?.id);
    }
  }, [i18n?.language]);

  // i18next.on("languageChanged", (selectedLanguage) => {
  //   const langFiltered = langId.filter(option => option.language.key === selectedLanguage)
  //   onLanguageSelect(langFiltered[0]?.language?.id)
  // })
  const onLanguageSelect = (selectedLanguage) => {
    setDefaultLanguage(selectedLanguage);
  };
  useEffect(() => {
    if (!defaultSelectedLanguage) {
      // setting up initially 0th index as lang id
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
    }
  }, [availableLanguagesFromSelector]);

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3>{t("Modules.Coupons.Coupons")}</h3>
          </Col>
        </Row>
      </div>
      <div className="content">
        <Row>
          <Col md={6}>
            <div className="header">{t("Modules.Coupons.Add new coupon")}</div>
            <div className="sub-header-text">
              {t("Modules.Coupons.Add new coupon - Description")}{" "}
              <span className="m-2">
                <OverlayTrigger
                  key="right"
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-right`}>
                      <Row>
                        <Col md={3}>
                          {t("Modules.Coupons.Add new coupon - Question mark")}
                        </Col>
                        <Col>
                          <img
                            src={couponDesc}
                            className={"tooltip-image"}
                            alt={"greeting-overview"}
                          ></img>
                        </Col>
                      </Row>
                    </Tooltip>
                  }
                >
                  <QuestionIcon className="pointer" />
                </OverlayTrigger>
              </span>
            </div>
          </Col>
          <Col md={6} className="alignContentCenter">
            {/* <Languages
              onChange={onLanguageSelect}
              defaultValue={defaultSelectedLanguage}
              valueAsKey={true}
              extraClasses="small-dropdown"
            /> */}
          </Col>
        </Row>
        <div>
          <CouponForm
            isForEdit={false}
            selectedLanguageForUpdate={defaultSelectedLanguage}
            baseLangId={availableFilteredLanguages[0]?.language?.id}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
