import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { ToastService } from "../../services/toast.service";
import defaultImg from "./icons/defaultImg.svg";
export const DragDrop = (props) => {
  const [uploadedImage, setUploadedImages] = useState([]);
  const [multiUpload, setMultiUpload] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const toast = new ToastService();
  const { t } = useTranslation();
  useEffect(() => {
    if (props.path === "update") {
      setUploadedImages(props.values);
      setMultiUpload(props.multiUpload);
    }
  }, [props.values]);
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: props.acceptingImageTypes
        ? props.acceptingImageTypes
        : "image/jpg, image/jpeg, image/png, .heic, .heif",
      maxFiles: props.maximumFile,
      onDropAccepted: (acceptedFiles) => {
        let files = acceptedFiles.map((uploadingFiles, id) =>
          Object.assign(uploadingFiles, {
            preview: URL.createObjectURL(uploadingFiles),
            arrId: "n_" + uploadedImage.length + id,
          })
        );
        if (props.multiUpload) {
          let tempUploadedImage = [...files];
          if (uploadedImage.length + files.length > 10) {
            toast.onWarning("Entity can have only 10 images associated!");
            const removeFilesCount =
              uploadedImage.length + files.length - props.maximumFile;

            tempUploadedImage = tempUploadedImage.slice(
              0,
              tempUploadedImage.length - removeFilesCount
            );
          }

          let heicImg = false;
          tempUploadedImage.forEach((image) => {
            if (
              image?.name.toLowerCase().includes(".heic") ||
              image?.name.toLowerCase().includes(".heif")
            ) {
              heicImg = true;
            }
          });
          if (heicImg) {
            toast.onWarning(t("Modules.Rooms List.iOSWarning"));
          }
          setUploadedImages([...uploadedImage, ...tempUploadedImage]);
        } else {
          setUploadedImages(files);
          setMultiUpload(false);
        }
      },
      onDropRejected: (rejected) => {
        if (!props.multiUpload && rejected.length > 1) {
          toast.onWarning("Multiple files are not allowed");
        } else {
          toast.onWarning(
            `Please provide valid file, correct file extensions are ${
              props.acceptingImageTypes
                ? props?.acceptingImageTypes?.replaceAll("image/", "")
                : "image/jpg, image/jpeg, image/png, .heic, .heif".replaceAll(
                    "image/",
                    ""
                  )
            }`
          );
        }
      },
    });

  const removeOnClick = (event) => {
    setUploadedImages(
      uploadedImage.filter((val, i) => val.arrId != event.target.id)
    );

    setMultiUpload(true);
  };
  useEffect(() => {
    try {
      props.handleChange(uploadedImage);
    } catch (err) {}
  }, [uploadedImage]);
  const imgType = ["image/jpg", "image/jpeg", "image/png"];

  const returnIndex = (array, id) => {
    const indexFound = [];
    array.forEach((element, index) => {
      if (element.arrId == id) {
        indexFound.push(index);
      }
    });

    return indexFound[0];
  };

  return (
    <React.Fragment>
      <Row>
        {(props.multiUpload || multiUpload || uploadedImage.length < 1) && (
          <Col md={3} sm={4}>
            <div
              {...getRootProps()}
              className={`${
                props.userProfile ? "imgUserProfile" : "imgMultiSelect"
              }`}
            >
              <Form.Control {...getInputProps()} />
              {isDragActive && !isDragReject ? (
                <p>Drop your files here...</p>
              ) : (
                <p></p>
              )}
              {isDragReject ? <p>Please provide valid file</p> : <p></p>}
            </div>
          </Col>
        )}
        {Array.isArray(uploadedImage) && (
          <Col
            md={9}
            sm={8}
            className={props.maximumFile !== 1 ? "scroll-right" : ""}
          >
            {uploadedImage.map((uploadedFile, i) => (
              <div
                key={i}
                id={uploadedFile?.arrId}
                className="img-container"
                onClick={() => {
                  setPhotoIndex(uploadedFile.arrId);
                  setShowModal(true);
                }}
              >
                <img
                  alt="img"
                  className="drag-image m-1 pointer"
                  src={
                    imgType.includes(uploadedFile?.type) ||
                    !String(uploadedFile?.arrId).includes("n_")
                      ? uploadedFile.preview
                      : defaultImg
                  }
                  id={uploadedFile.arrId}
                ></img>
                <div
                  className="image-overlay m-1  pointer"
                  id={uploadedFile.arrId}
                />
              </div>
            ))}
          </Col>
        )}
        {showModal && (
          <Lightbox
            mainSrc={
              uploadedImage.map((image) => image?.preview)[
                returnIndex(uploadedImage, photoIndex)
              ]
            }
            nextSrc={
              uploadedImage.map((image) => image?.preview)[
                returnIndex(uploadedImage, photoIndex) + 1
              ]
            }
            prevSrc={
              uploadedImage.map((image) => image?.preview)[
                returnIndex(uploadedImage, photoIndex) - 1
              ]
            }
            onCloseRequest={() => {
              setShowModal(false);
            }}
            onMovePrevRequest={() =>
              setPhotoIndex(
                uploadedImage.map((image) => image?.arrId)[
                  returnIndex(uploadedImage, photoIndex) - 1
                ]
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex(
                uploadedImage.map((image) => image?.arrId)[
                  returnIndex(uploadedImage, photoIndex) + 1
                ]
              )
            }
            toolbarButtons={[
              <Button
                variant="secondary"
                className={
                  "ril-zoom-in ril__toolbarItemChild ril__builtinButton ril__deleteButton"
                }
                id={photoIndex}
                onClick={(event) => {
                  removeOnClick(event);

                  if (uploadedImage.length <= 1) {
                    setShowModal(false);
                  } else {
                    if (
                      uploadedImage.map((image) => image?.arrId)[
                        returnIndex(uploadedImage, photoIndex) + 1
                      ]
                    ) {
                      setPhotoIndex(
                        uploadedImage.map((image) => image?.arrId)[
                          returnIndex(uploadedImage, photoIndex) + 1
                        ]
                      );
                    } else {
                      setPhotoIndex(
                        uploadedImage.map((image) => image?.arrId)[
                          returnIndex(uploadedImage, photoIndex) - 1
                        ]
                      );
                    }
                  }
                }}
              ></Button>,
            ]}
          ></Lightbox>
        )}
      </Row>
    </React.Fragment>
  );
};

export default DragDrop;
