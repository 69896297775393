import React, { useEffect } from "react";

const GratsText = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/animejs/2.0.2/anime.min.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      const ml4 = {};
      ml4.opacityIn = [0, 1];
      ml4.scaleIn = [0.2, 1];
      ml4.scaleOut = 3;
      ml4.durationIn = 800;
      ml4.durationOut = 600;
      ml4.delay = 500;

      if (window.anime)
        window.anime
          .timeline({ loop: false })
          .add({
            targets: ".ml4 .letters-1",
            opacity: ml4.opacityIn,
            scale: ml4.scaleIn,
            duration: ml4.durationIn,
          })
          .add({
            targets: ".ml4 .letters-1",
            opacity: 0,
            scale: ml4.scaleOut,
            duration: ml4.durationOut,
            easing: "easeInExpo",
            delay: ml4.delay,
          })
          .add({
            targets: ".ml4 .letters-2",
            opacity: ml4.opacityIn,
            scale: ml4.scaleIn,
            duration: ml4.durationIn,
          })
          .add({
            targets: ".ml4 .letters-2",
            opacity: 0,
            scale: ml4.scaleOut,
            duration: ml4.durationOut,
            easing: "easeInExpo",
            delay: ml4.delay,
          })
          .add({
            targets: ".ml4 .letters-3",
            opacity: ml4.opacityIn,
            scale: ml4.scaleIn,
            duration: ml4.durationIn,
          })
          .add({
            targets: ".ml4 .letters-3",
            opacity: 0,
            scale: ml4.scaleOut,
            duration: ml4.durationOut,
            easing: "easeInExpo",
            delay: ml4.delay,
          })
          .add({
            targets: ".ml4",
            opacity: 0,
            duration: 500,
            delay: 500,
          });
    });
  }, []);
  return (
    <h1 class="ml4">
      <span class="letters letters-1">Congratulations</span>
      <span class="letters letters-2">You are</span>
      <span class="letters letters-3">Live!</span>
    </h1>
  );
};

export default GratsText;
