export const FETCH_COUPON_LIST = "FETCH_COUPON_LIST";
export const FETCH_COUPON_LIST_SUCCESS = "FETCH_COUPON_LIST_SUCCESS";
export const FETCH_COUPON_LIST_FAILED = "FETCH_COUPON_LIST_FAILED";

export const FETCH_COUPON_BY_ID = "FETCH_COUPON_BY_ID";
export const FETCH_COUPON_BY_ID_SUCCESS = "FETCH_COUPON_BY_ID_SUCCESS";
export const FETCH_COUPON_BY_ID_FAILED = "FETCH_COUPON_BY_ID_FAILED";

export const UPDATE_COUPON = "UPDATE_COUPON";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAILED = "UPDATE_COUPON_FAILED";

export const ADD_COUPON = "ADD_COUPON";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const ADD_COUPON_FAILED = "ADD_COUPON_FAILED";

export const SEARCH_COUPON = "SEARCH_COUPON";

export const SORT_CATEGORIES = "SORT_CATEGORIES";

export const EMAIL_COUPON = "EMAIL_COUPON";
export const EMAIL_COUPON_SUCCESS = "EMAIL_COUPON_SUCCESS";
export const EMAIL_COUPON_FAILED = "EMAIL_COUPON_FAILED";

export const GET_EMAIL_COUPON = "GET_EMAIL_COUPON";
export const GET_EMAIL_COUPON_SUCCESS = "GET_EMAIL_COUPON_SUCCESS";
export const GET_EMAIL_COUPON_FAILED = "GET_EMAIL_COUPON_FAILED";

export const SET_COUPONS_SORT = "SET_COUPONS_SORT";
export const SET_COUPONS_LIST_PAGE_NO = "SET_COUPONS_LIST_PAGE_NO";
export const CLEAR_COUPON_BY_ID = "CLEAR_COUPON_BY_ID";

export const GET_COUPON_TYPES = "GET_COUPON_TYPES";
export const GET_COUPON_TYPES_SUCCESS = "GET_COUPON_TYPES_SUCCESS";
export const GET_COUPON_TYPES_FAILED = "GET_COUPON_TYPES_FAILED";

export const SET_SEARCH_COUPON_LIST = "SET_SEARCH_COUPON_LIST";

export const SOFT_DELETE_COUPON = "SOFT_DELETE_COUPON";
export const SOFT_DELETE_COUPON_SUCCESS = "SOFT_DELETE_COUPON_SUCCESS";
export const SOFT_DELETE_COUPON_FAILED = "SOFT_DELETE_COUPON_FAILED";

export const getCoupon = (payload) => {
  return {
    type: FETCH_COUPON_LIST,
    payload,
  };
};

export const getCouponById = (hotelId, serviceId) => {
  return {
    type: FETCH_COUPON_BY_ID,
    hotelId,
    serviceId,
  };
};

export const addCoupon = (data) => {
  return {
    type: ADD_COUPON,
    data,
  };
};
export const updateCoupon = (serviceId, data) => {
  return {
    type: UPDATE_COUPON,
    serviceId,
    data,
  };
};
export const search_service = (hotelId, searchKey) => {
  return {
    type: SEARCH_COUPON,
    hotelId,
    searchKey,
  };
};
export const sortCategories = (hotelId, data) => {
  return {
    type: SORT_CATEGORIES,
    hotelId,
    data,
  };
};

export const emailCoupon = (payload) => {
  return {
    type: EMAIL_COUPON,
    payload,
  };
};
export const getEmailCoupon = (hotelId) => {
  return {
    type: GET_EMAIL_COUPON,
    hotelId,
  };
};

export const getCouponTypes = () => {
  return {
    type: GET_COUPON_TYPES,
  };
};

export const setSearchCouponList = (payload) => {
  return {
    type: SET_SEARCH_COUPON_LIST,
    payload,
  };
};

export const softDeleteCoupon = (payload) => {
  return {
    type: SOFT_DELETE_COUPON,
    payload,
  };
};
