import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { Utils } from "../../utils/utils";
import BootstrapTable from "../Common/BootstrapTable";
import { ReactComponent as PencilSVG } from "../Common/icons/pencil.svg";
import StatusButton from "../Common/StatusButton";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import PaginationComponent from "./../Common/PaginationComponent";
import SearchComponents from "./../Common/SearchComponents";
import Spinner from "./../Common/Spinner";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export const SpecialOffersList = (props) => {
  const [allowAccess, setAllowAccess] = useState(true);

  const { t, i18n } = useTranslation();
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  //const hotelId = 1
  const history = useHistory();
  const [tableMapper, setTableMapper] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setSearchHotelRoomList(""));
    dispatch(actions.setSearchFloorList(""));
    dispatch(actions.setSearchSeason(""));
    dispatch(actions.setSearchUserList(""));
  }, []);

  const isLoading = useSelector((state) => state?.network?.isLoading);
  const spacialOffers = useSelector((state) => state.spacialOffres.offersList);

  const offerSearchTerm = useSelector((state) => state?.common?.offerSearch);

  const [searchTerm, setSearchTerm] = useState(offerSearchTerm || "");
  const [totalCount, setTotalCount] = useState(null);
  const [headerDescription, setHeaderDescription] = useState();
  const langId = useSelector((state) => state.constants.languages);
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );

  const hotelDetails = useSelector((state) => state?.hotelDetails?.result);
  const currencySymbol = hotelDetails?.currency?.symbol;
  const columnDataMap = {
    "OFFER NAME": "name",
    "PRICE ": "price",
    "START DATE": "start_date",
    "END DATE": "end_date",
  };
  const sortColumns = [
    t("Modules.Deals and offers (main page).Offer name AZ"),
    t("Modules.Deals and offers (main page).Price", {
      symbol: currencySymbol && currencySymbol,
    }),
    t("Modules.Deals and offers (main page).Start Date"),
    t("Modules.Deals and offers (main page).End Date"),
  ];
  const onSearchEvent = (searchTxt = "") => {
    // setSearchTerm(searchTxt);
    // getTableInfo(searchTxt, 1, "", "", "");
    dispatch(actions.setSearchOfferList(searchTxt));
    setPageNo(1);
    const updatedInfo = sortingColumnName.replace(currencySymbol, "");
    const sortBy = columnDataMap[updatedInfo];
    const updatedSearchTxt = searchTxt.replace(",", "");
    setSearchTerm(updatedSearchTxt);
    getTableInfo(updatedSearchTxt || "", 1, sortBy, sortOrder);
  };

  //get Page no,SortBy and SortColumn from reducer
  const offerListCommonDetails = useSelector((state) => state?.common);

  const [pageNo, setPageNo] = useState(offerListCommonDetails.offerPageNo);
  const [sortOrder, setSortOrder] = useState(
    offerListCommonDetails.offerSortBy
  );
  const [sortingColumnName, setSortingColumnName] = useState(
    offerListCommonDetails.offerColumnName
  );

  const sortColumn = useSelector((state) => state?.common.sortForOffer);
  const [sort, setSort] = useState(sortColumn);

  useEffect(() => {
    if (offerListCommonDetails) {
      //set current page no
      setPageNo(offerListCommonDetails.offerPageNo);
    }
  }, [offerListCommonDetails]);
  useEffect(() => {
    if (sortColumn) {
      //set current page no
      if (sortColumn["PRICE"]) {
        const priceKey = "PRICE " + currencySymbol;
        setSort({ ...sort, [`${priceKey}`]: true });
      }
    }
  }, [sortColumn]);
  useEffect(() => {
    if (spacialOffers) {
      mapTableInfo();
    }
  }, [spacialOffers, hotelDetails]);

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.offer_create));
    if (defaultSelectedLanguage) {
      getTableInfo(
        offerSearchTerm || "",
        offerListCommonDetails.offerPageNo
          ? offerListCommonDetails.offerPageNo
          : pageNo,
        offerListCommonDetails.offerSortColumnName
          ? offerListCommonDetails.offerSortColumnName
          : columnDataMap[sortingColumnName] || "",
        offerListCommonDetails.offerSortBy
          ? offerListCommonDetails.offerSortBy
          : sortOrder
      );
    }
  }, [defaultSelectedLanguage]);

  i18next.on("languageChanged", (selectedLanguage) => {
    if (selectedLanguage) {
      const langFiltered = langId.filter(
        (option) => option.language.key === selectedLanguage
      );
      onLanguageSelect(langFiltered[0]?.language?.id);
    }
  });
  const onLanguageSelect = (selectedLanguage) => {
    if (selectedLanguage) {
      setDefaultLanguage(selectedLanguage);
    }
  };
  
  const getTableInfo = (
    searchTerm = "",
    pageNumber = pageNo,
    sortBy = columnDataMap[sortingColumnName] || "",
    sortOrder = "",
    filterType = extractedOfferStatusType
  ) => {
    const payload = {
      searchKey: encodeURIComponent(searchTerm),
      hotel_id: hotelId,
      limit: 10,
      language_id: defaultSelectedLanguage ? defaultSelectedLanguage : 1,
    };
    if (sortOrder) {
      payload.sortBy = sortOrder;
    }
    if (sortBy) {
      payload.sort = sortBy;
    }
    if (pageNo) {
      payload.page = pageNumber;
    }

    if (filterType) {
      payload.type = filterType;
    }

    dispatch(actions.getOffers({ ...payload }));
  };
  const sortingClick = (event, headerInfo) => {
    const innerText = event.target.innerText?.trim();
    setSortingColumnName(innerText);
    try {
      if (!sort && !Object.keys(sort)?.length) {
        setSort({ ...sort, [innerText]: true });
      } else {
        setSort({
          ...sort,
          [innerText]: !sort[innerText],
        });
      }
    } catch (error) {
      setSort({ ...sort, [innerText]: true });
    }
    const updatedInfo = headerInfo.replace(currencySymbol, "");
    setSortingColumnName(innerText);
    const sortOrder = sort[innerText] ? "ASC" : "DESC";
    setSortOrder(sortOrder);
    getTableInfo(
      searchTerm || "",
      pageNo,
      columnDataMap[updatedInfo],
      sortOrder
    );
  };
  const mapTableInfo = () => {
    const mapper = spacialOffers.records?.map((SpecialOffersInfo, index) => {
      const tableHeader = {
        id: SpecialOffersInfo.id,
      };
      //tableHeader[`${ t("Modules.Deals and offers (main page).Offer name AZ")}`] = Utils.langValueGenerator(SpecialOffersInfo?.h_offers_and_services_ts,'name',defaultSelectedLanguage);
      tableHeader[
        `${t("Modules.Deals and offers (main page).Offer name AZ")}`
      ] = SpecialOffersInfo.name;
      tableHeader[
        `${t("Modules.Deals and offers (main page).Price", {
          symbol: currencySymbol && currencySymbol,
        })}`
      ] = Utils.NumberFormatText(
        hotelDetails?.currency?.symbol,
        SpecialOffersInfo.price
      );
      tableHeader[`${t("Modules.Deals and offers (main page).Start Date")}`] =
        Utils.getDDMMYY(SpecialOffersInfo.start_date || "");
      tableHeader[`${t("Modules.Deals and offers (main page).End Date")}`] =
        Utils.getDDMMYY(SpecialOffersInfo.end_date || "");
      tableHeader[`${t("Modules.Deals and offers (main page).Active")}`] = (
        <StatusButton
          pending={SpecialOffersInfo.is_active == 1 ? "completed" : "pending"}
        ></StatusButton>
      );
      return tableHeader;
    });
    const tableHeaderDesc = {
      [`${t("Modules.Deals and offers (main page).Status")}`]: t(
        "Modules.Deals and offers (main page).Status - Question mark-offers"
      ),
    };
    setHeaderDescription(tableHeaderDesc);
    setTableMapper(mapper);
  };
  const onEditRow = (categoryId) => {
    history.push("/special-offers/edit/" + categoryId);
  };
  const tableExtraActions = [
    {
      icon: <PencilSVG className={"svg-s25"} />,
      onClick: onEditRow,
    },
  ];

  useEffect(() => {
    //dispatch(actions.getOffers(hotelId));
    // getTableInfo();
  }, []);

  useEffect(() => {
    spacialOffers.records
      ? mapTableInfo(spacialOffers.records)
      : mapTableInfo([]);
    setTotalCount(spacialOffers?.totalCount);
  }, [spacialOffers, hotelDetails]);
  const updatePagination = (pageNo) => {
    setPageNo(pageNo);
    const updatedInfo = sortingColumnName.replace(currencySymbol, "");
    getTableInfo(
      searchTerm || "",
      pageNo,
      offerListCommonDetails.offerSortColumnName
        ? offerListCommonDetails.offerSortColumnName
        : columnDataMap[sortingColumnName] || "",
      offerListCommonDetails.offerSortBy
        ? offerListCommonDetails.offerSortBy
        : sortOrder
    );
  };
  // listening on language Changed event to update table header
  // TODO: update listing on language change
  // i18n.on("languageChanged", (selectedLang) => {
  //     mapTableInfo();
  //   });

  const offerStatusTypes = [
    t("Modules.Deals and offers (main page).All Offers"),
    t("Modules.Deals and offers (main page).Active Offers"),
    t("Modules.Deals and offers (main page).Inactive Offers"),
  ];
  const [selectedOfferStatusType, setSelectedOfferStatusType] = useState(
    offerStatusTypes[0]
  );
  const [extractedOfferStatusType, setExtractedOfferStatusType] = useState("");

  useEffect(() => {
    const headerInfo = {
      searchKey: "",
      sortBy: "ASC",
      sort: "username",
      page: 1,
    };

    let type = "";
    if (selectedOfferStatusType === offerStatusTypes[1]) {
      type = "active";
    } else if (selectedOfferStatusType === offerStatusTypes[2]) {
      type = "deactive";
    }

    setExtractedOfferStatusType(type);

    getTableInfo(
      searchTerm,
      pageNo,
      columnDataMap[headerInfo],
      sortOrder,
      type
    );
  }, [selectedOfferStatusType]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Row>
            <Col>
              <h4>{t("Modules.Deals and offers (main page).Offers")}</h4>
            </Col>
          </Row>
          <Row>
            <div
              className="alert alert-info informationBox sub-header mt-1 mb-1"
              role="alert"
            >
              <h6>
                {t(
                  "Modules.Deals and offers (main page).Offer Information box"
                )}
              </h6>
            </div>
          </Row>
          <Row className="mb-3">       
            <Col
                className="mb-md-0 mb-3"
                xs={12}
                >
                <div className="content-box ">
                  <SearchComponents
                    buttonLabel={t(
                      "Modules.Deals and offers (main page).+ Add new offer"
                    )}
                    addBtn={allowAccess}
                    path={PRIVATE_ROUTES.offer_create}
                    search={onSearchEvent}
                    commonSearchTerm={offerSearchTerm}
                    dropDownData={offerStatusTypes}
                    selectedDropDownValue={selectedOfferStatusType}
                    onDropDownSelect={(selectedType)=>{
                      setPageNo(1);
                      setSelectedOfferStatusType(selectedType);
                    }}
                  />
                </div>
              </Col>            
          </Row>

          <Row>
            <Col>
              <BootstrapTable
                sortColumns={sortColumns}
                currentlySorted={sort}
                sortingColumnName={
                  sortingColumnName === "PRICE"
                    ? sortingColumnName + " " + currencySymbol
                    : sortingColumnName
                }
                onSortClick={sortingClick}
                isLoading={isLoading}
                tableExtraActions={allowAccess ? tableExtraActions : []}
                component={"Deals and Offers"}
                headerDescription={headerDescription}
                tableMapper={tableMapper}
              />
            </Col>
          </Row>
          {!isLoading ? (
            <>
              <Row>
                <Col>
                  <PaginationComponent
                    pageNo={pageNo}
                    totalCount={Math.ceil(totalCount / 10)}
                    onChange={(number) => {
                      updatePagination(number);
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Spinner />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default SpecialOffersList;
