import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AddTags from "../Common/AddTags";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import categoryDes from "../Common/overlayImages/categoryDes.jpg";
import { CKEditorComponent } from "./../Common/CkEditor";
import AddTagsMulti from "../Common/AddTagsMulti";

const DetailedInformation = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="header">
        {t("Modules.Rooms List.Detailed Information")}
      </div>
      <br></br>
      <Row>
        <Col md={6}>
          <div className="sub-header">{t("Modules.Rooms List.Decription")}</div>
          <div className="sub-header-text">
            {t("Modules.Rooms List.Description message")}
            <OverlayTrigger
              key="right"
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-right">
                  <Row>
                    <Col md={3}>
                      {t("Modules.Rooms List.Room description - Question mark")}
                    </Col>
                    <Col>
                      <img
                        src={categoryDes}
                        className={"tooltip-image"}
                        alt={"greeting-overview"}
                      ></img>
                    </Col>
                  </Row>
                </Tooltip>
              }
            >
              <QuestionIcon className="pointer" />
            </OverlayTrigger>
          </div>
          <br />
          {/* <TextEditor
            editorState={props.editorValue}
            setEditorState={props.updateEditorText}
          ></TextEditor> */}
          <CKEditorComponent
            editorState={props.editorValue}
            newContent={props.content}
            setEditorState={props.updateEditorText}
          />
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col>
          <div className="sub-header">
            {t("Modules.Rooms List.Define room features")}
          </div>
          <div className="sub-header-text">
            {t("Modules.Rooms List.Define room features - Description")}
            <span style={{ color: "red" }}>*</span>
          </div>
        </Col>

        <AddTagsMulti
          updateTags={props.updateTags}
          removeTags={props.removeTags}
          setNewTags={props.setNewTags}
          existingTags={props.existingTags}
          searchTags={props.searchTags}
          tagsApiId={props.tagsApiId}
          setFieldValue={props.setFieldValue}
          values={props.values}
          path={props.path}
          errors={props.error}
          touched={props.touched}
          disabled={props.disabled}
          initialValues={props.initialValues}
          defaultSelectedLanguage={props.defaultSelectedLanguage}
        ></AddTagsMulti>
        <Col md={2} xs={3} className="pt-1">
          <Link to="/hotel-translation">
            <strong>
              {t("Modules.Basic information.Manage translations")}
            </strong>
          </Link>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DetailedInformation;
