import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import actions from "../../store/actions/actions";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import offerImg from "../Common/overlayImages/offerImg.jpg";
import { StorageService } from "./../../services/storage.service";
import { Utils } from "./../../utils/utils";
import Breadcrumbs from "./../Common/Breadcrumbs";
import Spinner from "./../Common/Spinner";
import { SpecialOffersForm } from "./SpecialOffersForm";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as Vectors } from "../Common/icons/vectors.svg";
import SoftDeleteAlertModal from "../Common/SoftDeleteAlertModal";

export const EditSpecialOffer = () => {
  const dispatch = useDispatch();
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const history = useHistory();
  const { t } = useTranslation();
  const hotelName = useSelector((state) => state?.hotelDetails?.result?.name);
  const [offerName, setOfferName] = useState(null);
  const offerById = useSelector((state) => state.spacialOffres.offerById);
  const { id } = useParams();
  const [cmsContent, setCMSContent] = useState(null);
  const langId = useSelector((state) => state.constants.languages);
  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );
  const breadcrumbs = [
    { path: "/hotel-management", title: "Hotels" },
    { path: "/deals-and-offer", title: hotelName },
    { path: "/hotel-management", title: " Deals and offers" },
    { path: `/special-offers/edit/${id}`, title: offerName },
  ];
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );
  const isLoading = useSelector((state) => state?.spacialOffres?.offerById);

  useEffect(() => {
    dispatch(actions.getOffersById(hotelId, id));
  }, []);

  const isUpdated = useSelector((state) => state?.spacialOffres?.isUpdate);

  useEffect(() => {
    if (isUpdated) {
      history.push("/deals-and-offer");
    }
  }, [isUpdated]);

  useEffect(() => {
    setStates();
  }, [offerById]);

  useEffect(() => {
    if (!defaultSelectedLanguage) {
      // setting up initially 0th index as lang id
      setDefaultLanguage(availableFilteredLanguages[0]?.language?.id);
    }
  }, [availableLanguagesFromSelector]);

  const setStates = () => {
    if (offerById) {
      const CMSContent = defaultSelectedLanguage
        ? findExistingLanguage(
            offerById?.h_offers_and_services_ts,
            defaultSelectedLanguage
          )
        : offerById?.h_offers_and_services_ts[0]?.body;
      setCMSContent(CMSContent?.body);
    }
  };
  i18next.on("languageChanged", (selectedLanguage) => {
    const langFiltered = langId.filter(
      (option) => option.language.key === selectedLanguage
    );
    onLanguageSelect(langFiltered[0]?.language?.id);
  });
  const onLanguageSelect = (selectedLanguage) => {
    setDefaultLanguage(selectedLanguage);
    const selectedLangId = selectedLanguage;
    if (selectedLangId) {
      const changedCMSContent = findExistingLanguage(
        offerById && offerById?.h_offers_and_services_ts
          ? offerById?.h_offers_and_services_ts
          : [],
        selectedLangId
      );
      if (changedCMSContent && changedCMSContent?.language_id) {
        if (changedCMSContent?.body) {
          setCMSContent(changedCMSContent?.body);
        } else {
          setCMSContent(offerById?.h_offers_and_services_ts[0]?.body);
        }
      } else {
        setCMSContent("");
      }
    }
  };
  const findExistingLanguage = (cmsBlock, selectedLangId) => {
    return (cmsBlock || []).find((translationContent) => {
      return +translationContent.language_id === +selectedLangId;
    });
  };
  useEffect(() => {
    if (offerById.id) {
      const changedDealName = findExistingLanguage(
        offerById?.h_offers_and_services_ts,
        defaultSelectedLanguage
      );
      if (
        changedDealName &&
        changedDealName?.language_id &&
        changedDealName.name.length
      ) {
        setOfferName(changedDealName?.name);
      } else {
        setOfferName(offerById?.h_offers_and_services_ts[0].name);
      }
    }
  }, [offerById, defaultSelectedLanguage]);

  const [showSoftDeleteAlert, setShowSoftDeleteAlert] = useState(false);

  return (
    <React.Fragment>
      <SoftDeleteAlertModal
        show={showSoftDeleteAlert}
        type={"confirmation"}
        primaryMessage={t("Modal Messages.General Message")}
        secondaryMessage={t("Modal Messages.General Info Message")}
        onConfirm={() => dispatch(actions.softDeleteOffer({ id }))}
        closeButtonText={t("Modal Messages.Cancel")}
        onClose={() => setShowSoftDeleteAlert(false)}
      />
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12} xs={12} className="alignSelf alignRight">
            <>
              <Dropdown className="customer-dropdown">
                <Dropdown.Toggle size="sm" variant="white">
                  <Vectors />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowSoftDeleteAlert(true)}>
                    {t(
                      "Modules.Deals and Offers (edit offer).Deactivate Offer"
                    )}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h3>{t("Modules.Deals and offers (main page).Offers")} </h3>
          </Col>
        </Row>
      </div>
      {isLoading ? (
        <div className="content">
          <Row>
            <Col md={6}>
              <div className="header">
                {t("Modules.Deals and Offers (edit offer).Edit offer")}
              </div>
              <div className="sub-header-text">
                {t(
                  "Modules.Deals and Offers (edit offer).Edit offer - Description"
                )}{" "}
                <span className="m-2">
                  <OverlayTrigger
                    key="right"
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-right`}>
                        <Row>
                          <Col md={3}>
                            {t(
                              "Modules.Deals and Offers (edit offer).Edit offer - Question mark"
                            )}
                          </Col>
                          <Col>
                            <img
                              src={offerImg}
                              className={"tooltip-image"}
                              alt={"greeting-overview"}
                            ></img>
                          </Col>
                        </Row>
                      </Tooltip>
                    }
                  >
                    <QuestionIcon className="pointer" />
                  </OverlayTrigger>
                </span>
              </div>
            </Col>
            <Col md={6} className="alignContentCenter">
              {/* <Languages
              onChange={onLanguageSelect}
              defaultValue={defaultSelectedLanguage}
              valueAsKey={true}
              extraClasses="small-dropdown"
            /> */}
            </Col>
          </Row>
          <div>
            <SpecialOffersForm
              cmsContent={cmsContent}
              isForEdit={true}
              selectedLanguageForUpdate={defaultSelectedLanguage}
              baseLangId={availableFilteredLanguages[0]?.language?.id}
              offersDetails={offerById}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </React.Fragment>
  );
};
