import axios from "axios";
import { STORAGE_KEYS } from "../helper/constants";
import { StorageService } from "./storage.service";
import { getTokens } from "./auth.service";

const storageService = new StorageService();

const couponsInstance = axios.create({
  baseURL: `${process.env.REACT_APP_DEALS_OFFERS_API_URL}`,
  withCredentials: true,
  // paramsSerializer: (params) => {
  //     return Object.keys(params).reduce((acc, _p) => { return acc + _p + "=" + params[_p]+"&" }, "")
  // }
});

// attaching auth header
// servicesInstance.interceptors.request.use(function (config) {
//     const authToken = getToken();
//     config.headers.Authorization = 'Bearer '.concat(authToken);
//     return config;
// });

couponsInstance.interceptors.request.use(function (config) {
  const authToken = getTokens();
  config.headers["Authorization"] = authToken.AccessToken;
  config.headers["Refresh-Token"] = authToken.RefreshToken;
  return config;
});

couponsInstance.interceptors.response.use(
  (response) => {
    if (response.headers) {
      if (response.headers.isaccesstokenrefreshed === "1") {
        storageService.setItem(
          STORAGE_KEYS.AccessToken,
          response.headers.accesstoken
        );
      }
    }
    return response;
  },
  (error) => {
    throw error;
  }
);
// checking each request for auth status

// instance.interceptors.response.use( (response) => {
//     // for success
//     return response;
// }, (error) => {
//     if(+error.response.status === 401) {
//         redirectToLogin();
//     }

//     if(!authToken || !authToken.length) {
//         redirectToLogin();
//     }
// });

// const redirectToLogin = () => {
//         // history push
//         // removeToken();
//         // history.push("/login")
// }

export default couponsInstance;
