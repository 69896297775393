import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { default as NumberFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { Utils } from "../../utils/utils";
import BootstrapTable from "../Common/BootstrapTable";
import { ReactComponent as PencilSVG } from "../Common/icons/pencil.svg";
import StatusButton from "../Common/StatusButton";
import { StorageService } from "../../services/storage.service";
import actions from "../../store/actions/actions";
import PaginationComponent from "../Common/PaginationComponent";
import SearchComponents from "../Common/SearchComponents";
import Spinner from "../Common/Spinner";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export const CouponList = (props) => {
  const { t, i18n } = useTranslation();
  const isLoading = useSelector((state) => state?.network?.isLoading);
  const history = useHistory();
  const [totalCount, setTotalCount] = useState(null);
  const [tableMapper, setTableMapper] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setSearchHotelRoomList(""));
    dispatch(actions.setSearchFloorList(""));
    dispatch(actions.setSearchSeason(""));
    dispatch(actions.setSearchUserList(""));
  }, []);

  const coupons = useSelector((state) => state.coupons.couponList);

  const dealSearchTerm = useSelector((state) => state?.common?.dealSearch);

  const [searchKey, setSearchKey] = useState(dealSearchTerm || null);
  const [searchTerm, setSearchTerm] = useState(dealSearchTerm || "");
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const [headerDescription, setHeaderDescription] = useState();
  const langId = useSelector((state) => state.constants.languages);
  const [allowAccess, setAllowAccess] = useState(true);

  const [defaultSelectedLanguage, setDefaultLanguage] = useState(
    langId.filter((option) => option.language.key === i18next.language)[0]
      ?.language?.id
  );
  const [currencySymbol, setCurrencySymbol] = useState();
  const hotelDetails = useSelector((state) => state?.hotelDetails?.result);
  //const currencySymbol = hotelDetails?.currency?.symbol;
  //const hotelId = 1
  const columnDataMap = {
    CODE: "code",
    "AMOUNT ": "amount",
    TYPE: "type",
    "START DATE": "start_date",
    "END DATE": "end_date",
    "IS ACTIVE": "is_active",
  };
  const sortColumns = [
    t("Modules.Deals and offers (main page).Deal name AZ"),
    t("Modules.Deals and offers (main page).Price", {
      symbol: currencySymbol && currencySymbol,
    }),
    t("Modules.Deals and offers (main page).Start Date"),
    t("Modules.Deals and offers (main page).End Date"),
  ];

  //get Page no,SortBy and SortColumn from reducer
  const dealsListCommonDetails = useSelector((state) => state?.common);

  const [pageNo, setPageNo] = useState(dealsListCommonDetails.dealsListPageNo);
  const [sortOrder, setSortOrder] = useState(dealsListCommonDetails.dealSortBy);
  const [sortingColumnName, setSortingColumnName] = useState(
    dealsListCommonDetails.dealColumnName
  );

  const sortColumn = useSelector((state) => state?.common.sortForDeals);
  const [sort, setSort] = useState(sortColumn);

  const onSearchEvent = (searchTxt = "") => {
    // setSearchTerm(searchTxt);
    // getTableInfo(searchTxt, 1, "", "", "");
    dispatch(actions.setSearchDealList(searchTxt));
    setPageNo(1);
    const updatedInfo = sortingColumnName.replace(currencySymbol, "");
    const sortBy = columnDataMap[updatedInfo];
    const updatedSearchTxt = searchTxt.replace(",", "");
    setSearchTerm(updatedSearchTxt);
    getTableInfo(updatedSearchTxt || "", 1, sortBy, sortOrder);
  };
  useEffect(() => {
    if (sortColumn) {
      //set current page no
      if (sortColumn["PRICE"]) {
        const priceKey = "PRICE " + currencySymbol;
        setSort({ ...sort, [`${priceKey}`]: true });
      }
    }
  }, [sortColumn]);

  useEffect(() => {
    if (dealsListCommonDetails) {
      //set current page no
      setPageNo(dealsListCommonDetails.dealsListPageNo);
    }
  }, [dealsListCommonDetails]);

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.deal_create));

    if (coupons && hotelDetails) {
      mapTableInfo();
    }
  }, [coupons, defaultSelectedLanguage, hotelDetails]);

  useEffect(() => {
    if (hotelDetails) {
      setCurrencySymbol(hotelDetails?.currency?.symbol);
    }
  }, [hotelDetails]);

  useEffect(() => {
    if (defaultSelectedLanguage) {
      getTableInfo(
        dealSearchTerm || "",
        dealsListCommonDetails.dealsListPageNo
          ? dealsListCommonDetails.dealsListPageNo
          : pageNo,
        dealsListCommonDetails.dealSortColumnName
          ? dealsListCommonDetails.dealSortColumnName
          : columnDataMap[sortingColumnName],
        dealsListCommonDetails.dealSortBy
          ? dealsListCommonDetails.dealSortBy
          : sortOrder
      );
    }
  }, [defaultSelectedLanguage]);

  i18next.on("languageChanged", (selectedLanguage) => {
    if (selectedLanguage) {
      const langFiltered = langId.filter(
        (option) => option.language.key === selectedLanguage
      );
      onLanguageSelect(langFiltered[0]?.language?.id);
    }
  });
  const onLanguageSelect = (selectedLanguage) => {
    if (selectedLanguage) {
      setDefaultLanguage(selectedLanguage);
    }
  };
  const mapTableInfo = () => {
    const mapper = coupons?.records?.map((couponInfo, index) => {
      const tableHeader = {
        id: couponInfo.id,
      };
      // tableHeader[`${t("Modules.Deals and offers (main page).Deal name AZ")}`] =
      //   Utils.langValueGenerator(
      //     couponInfo?.h_offers_and_services_ts,
      //     "name",
      //     defaultSelectedLanguage
      //   );
      tableHeader[`${t("Modules.Coupons.Coupon Code")}`] = couponInfo.code;

      tableHeader["TYPE"] = couponInfo.type;
      console.log(couponInfo.type);
      let amount = 0;
      if (couponInfo.type?.includes("Fixed")) {
        amount = Utils.NumberFormatText(
          hotelDetails?.currency?.symbol,
          couponInfo.amount
        );
      } else if (couponInfo.type?.includes("Perc")) {
        amount = parseFloat(couponInfo.amount).toFixed(2).concat(" %");
      }

      tableHeader[
        `${t("Modules.Coupons.Coupon Value", {
          symbol: hotelDetails?.currency?.symbol,
        })}`
      ] = amount;

      tableHeader[`${t("Modules.Deals and offers (main page).Start Date")}`] =
        Utils.getDDMMYY(couponInfo.start_date || "");
      tableHeader[`${t("Modules.Deals and offers (main page).End Date")}`] =
        Utils.getDDMMYY(couponInfo.end_date || "");
      tableHeader[`${t("Modules.Deals and offers (main page).Active")}`] = (
        <StatusButton
          pending={couponInfo.is_active == 1 ? "completed" : "pending"}
        >
          {" "}
        </StatusButton>
      );
      return tableHeader;
    });
    const tableHeaderDesc = {
      [`${t("Modules.Deals and offers (main page).Status")}`]: t(
        "Modules.Deals and offers (main page).Status - Question mark"
      ),
    };
    setHeaderDescription(tableHeaderDesc);
    setTableMapper(mapper);
  };
  const sortingClick = (event, headerInfo) => {
    const innerText = event.target.innerText.trim();
    setSortingColumnName(innerText);
    try {
      if (!sort && !Object.keys(sort)?.length) {
        setSort({ ...sort, [innerText]: true });
      } else {
        setSort({
          ...sort,
          [innerText]: !sort[innerText],
        });
      }
    } catch (error) {
      setSort({ ...sort, [innerText]: true });
    }
    const updatedInfo = headerInfo.replace(currencySymbol, "");
    setSortingColumnName(innerText);
    const sortOrder = sort[innerText] ? "ASC" : "DESC";
    setSortOrder(sortOrder);
    getTableInfo(
      searchTerm || "",
      pageNo,
      columnDataMap[updatedInfo],
      sortOrder
    );
  };
  const getTableInfo = (
    searchTerm = "",
    pageNumber = pageNo,
    sortBy = columnDataMap[sortingColumnName] || "",
    sortOrder = "",
    filterType = extractedDealStatusType
  ) => {
    const payload = {
      searchKey: encodeURIComponent(searchTerm),
      hotel_id: hotelId,
      limit: 10,
      language_id: defaultSelectedLanguage ? defaultSelectedLanguage : 1,
    };
    if (!searchTerm) {
      payload.sort = "code";
      payload.sortBy = "ASC";
    }

    if (sortOrder) {
      payload.sortBy = sortOrder;
    }

    if (sortBy) {
      payload.sort = sortBy;
    }

    if (pageNo) {
      payload.page = pageNumber;
    }

    if (filterType) {
      payload.type = filterType;
    }

    dispatch(actions.getCoupon({ ...payload }));
  };

  const onEditRow = (categoryId) => {
    history.push("coupon/edit/" + categoryId);
  };
  const tableExtraActions = [
    {
      icon: <PencilSVG className={"svg-s25"} />,
      onClick: onEditRow,
    },
  ];

  useEffect(() => {
    // dispatch(actions.getService(hotelId));
    //getTableInfo();
  }, []);

  useEffect(() => {
    if (hotelDetails) {
      coupons.records ? mapTableInfo(coupons.records) : mapTableInfo([]);
      setTotalCount(coupons?.totalCount);
    }
  }, [coupons, hotelDetails]);

  const updatePagination = (pageNo) => {
    setPageNo(pageNo);
    const updatedInfo = sortingColumnName.replace(currencySymbol, "");
    getTableInfo(
      searchTerm || "",
      pageNo,
      dealsListCommonDetails.dealSortColumnName
        ? dealsListCommonDetails.dealSortColumnName
        : columnDataMap[sortingColumnName],
      dealsListCommonDetails.dealSortBy
        ? dealsListCommonDetails.dealSortBy
        : sortOrder
    );
  };
  // listening on language Changed event to update table header
  // TODO: update listing on language change
  // i18n.on("languageChanged", (selectedLang) => {
  //   mapTableInfo();
  // });

  const dealStatusTypes = [
    t("Modules.Coupons.All Coupons"),
    t("Modules.Coupons.Active Coupons"),
    t("Modules.Coupons.Inactive Coupons"),
  ];
  const [selectedDealStatusType, setSelectedDealStatusType] = useState(
    dealStatusTypes[0]
  );
  const [extractedDealStatusType, setExtractedDealStatusType] = useState("");

  useEffect(() => {
    const headerInfo = {
      searchKey: "",
      sortBy: "ASC",
      sort: "username",
      page: 1,
    };

    let type = "";
    if (selectedDealStatusType === dealStatusTypes[1]) {
      type = "active";
    } else if (selectedDealStatusType === dealStatusTypes[2]) {
      type = "deactive";
    }

    setExtractedDealStatusType(type);

    getTableInfo(
      searchTerm,
      pageNo,
      columnDataMap[headerInfo],
      sortOrder,
      type
    );
  }, [selectedDealStatusType]);

  return (
    <React.Fragment>
      <div className="content mt-3">
        <Row>
          <Col>
            <Row>
              <Col>
                <h4>{t("Modules.Coupons.Coupons")}</h4>
              </Col>
            </Row>
            {/* <Row>
              <div
                className="alert alert-info informationBox sub-header mt-1 mb-1"
                role="alert"
              >
                <h6>{t("Modules.Coupons.Coupons Information box")}</h6>
              </div>
            </Row> */}
            <Row className="mb-3">
              <Col className="mb-md-0 mb-3" xs={12}>
                <div className="content-box ">
                  <SearchComponents
                    buttonLabel={t("Modules.Coupons.+ Add new coupon")}
                    addBtn={allowAccess}
                    path={PRIVATE_ROUTES.coupon_create}
                    search={onSearchEvent}
                    commonSearchTerm={dealSearchTerm}
                    dropDownData={dealStatusTypes}
                    selectedDropDownValue={selectedDealStatusType}
                    onDropDownSelect={(selectedType) => {
                      setPageNo(1);
                      setSelectedDealStatusType(selectedType);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <BootstrapTable
                  sortColumns={sortColumns}
                  currentlySorted={sort}
                  sortingColumnName={
                    sortingColumnName === "PRICE"
                      ? sortingColumnName + " " + currencySymbol
                      : sortingColumnName
                  }
                  onSortClick={sortingClick}
                  isLoading={isLoading}
                  tableExtraActions={allowAccess ? tableExtraActions : []}
                  component={"Coupons"}
                  headerDescription={headerDescription}
                  tableMapper={tableMapper}
                />
              </Col>
            </Row>
            {!isLoading ? (
              <>
                <Row>
                  <Col>
                    <PaginationComponent
                      pageNo={pageNo}
                      totalCount={Math.ceil(totalCount / 10)}
                      onChange={(number) => {
                        updatePagination(number);
                      }}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <Spinner />
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default CouponList;
